import React, {Component} from 'react';
import '../../css/Home.css'
import $ from "jquery";
import ReactHtmlParser from 'react-html-parser';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      homePage: '',
    }
  }

  componentDidMount() {
    fetch(process.env.REACT_APP_CBT + '/api/custom/configs?_format=json')
    .then(res => res.json()) // It resolves the promise with a JSON object
    .then(res => {
      this.setState({
        homePage : res.homepage_content,
      });
    })
  }

  render() {
    $(document).ready(function() {
      $('.submit-btn-loader').hide();
    });
    if (document.referrer === document.location.href) {
      setTimeout(function() {
        if(window.location.hash === '' ) {
          document.location.reload()
        }
      }, 1000);
      document.location.href = '#'
    }

    return (
      <div className="homepage frontpage">
        {ReactHtmlParser(this.state.homePage)}
      </div>
    );
  }
}

export default Home;
