import React, { Component } from 'react';

const siteName = 'IQS';
const yearNow = new Date().getFullYear();

class Footer extends Component {
  render() {
    return (
      <footer className="footer pt-navbar">
        <div className="copyright-wrapper">
          <div className="row">
            <div className="col-lg-12 col-xs-12 center-xs copyright">
              <span className="pt-text-footer-info">&copy; {yearNow} { siteName } All rights reserved.</span>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
