import React, {Component} from 'react';
import '../../css/Exam.css';
import ReactHtmlParser from 'react-html-parser'
import $ from "jquery";
import { TransverseLoading } from 'react-loadingg';
import { confirmAlert } from 'react-confirm-alert';
import ClipLoader from "react-spinners/ClipLoader";

class Agreement extends Component {
  constructor(props) {
    super(props);
    // Max Date global variable.
    global.MaxDateVar = new Date().toISOString().split('T')[0];

    this.state = {
      accept: "",
      not_accept: "",
      agreement: [],
      isLoading:true,
      language: "en",
      loading: true,
    }
    this.onBlur= this.onBlur.bind(this);
  }
  redirectToTarget = (e) => {
    let loader = <ClipLoader
          size={20}
          color={"red"}
          loading={this.state.loading}
        />;
    this.setState({"loader": loader});
    if ($('.accept').is(":checked")) {
      var response = e.target.id
      var exam_name = e.currentTarget.attributes['exam'].value;
      var exam_time = e.currentTarget.attributes['exam_time'].value;
      var question = e.currentTarget.attributes['data-question'].value;

      // Fetch logged in user info.
      const userID = localStorage.getItem('userData.uid');
      var is_backend = "";
      if(userID) {
        fetch(process.env.REACT_APP_CBT + '/user/' + userID + '?_format=json')
        .then(data => data.json()) // It resolves the promise with a JSON object
        .then(data => {
          if (data.field_backend_user) {
            if (typeof data.field_backend_user[0] != 'undefined'){

            is_backend = data.field_backend_user[0].value;
            // eslint-disable-next-line
            if (is_backend == true) {
              confirmAlert({
                customUI: ({ onClose }) => {
                  this.setState({"loader": ""});
                  return (
                    <div className='custom-ui'>
                      <h1>Confirm to Start the Exam</h1>
                      <p>Select the language you preferred from below list and start the Exam.</p>
                      <label className="pt-label">
                        Language :
                        <div className="pt-select">
                          <select className="lang-select" name = {"language"} required ={ true } onChange= {this.onBlur}>
                            <option value = "" > Select </option>
                            <option value = "en" > English </option>
                            <option value = "mr" > Marathi </option>
                            <option value = "hi" > Hindi </option>
                          </select>
                        </div>
                      </label>
                      <p>If not selected, English will be set as the default langauge.</p>
                      <div className="react-confirm-alert-button-group">
                        <button onClick={onClose}>Cancel</button>
                        <button
                          className = "start-exam"
                          onClick={() => {
                            this.props.history.push('/tutorial', {
                              response: response,
                              exam_name: exam_name,
                              exam_time: exam_time,
                              question: question,
                              lang_sel: this.state.language,
                            });
                            window.location.reload();
                          }}
                        >
                          Start Exam
                        </button>
                      </div>
                    </div>
                  );
                }
              });
            }
            else {
              this.props.history.push('/tutorial', {
                response: response,
                exam_name: exam_name,
                exam_time: exam_time,
                question: question,
                lang_sel: this.state.language,
              });
              window.location.reload();
            }
            }
          }
        })
      }
    }
    else if ($('.not_accept').is(":checked")) {
      this.props.history.push('/');
      window.location.reload();
    }
    else {
      this.setState({"loader": ""});
    }
    e.preventDefault();
  }

  componentDidMount() {
    fetch(process.env.REACT_APP_CBT + '/api/custom/configs?_format=json')
    .then(res => res.json()) // It resolves the promise with a JSON object
    .then(res => {
      this.setState({
        agreement : res.exam_agreement,
        isLoading : false,
      });
    })
  }

  onBlur(e) {
    const { name, value } = e.target;
    // eslint-disable-next-line
    if (e.target.value == 'off') {
      e.target.value = "on";
    }
    // eslint-disable-next-line
    else if (e.target.value == 'on') {
      e.target.value = "off";
    }
    // eslint-disable-next-line
    if (e.target.name == 'not_accept') {
      $('.checkbox-wrapper .accept').prop("checked", false);
    }
    else {
      $('.checkbox-wrapper .not_accept').prop("checked", false);
    }
    this.setState({ [name]: value });
    // eslint-disable-next-line
    if (name == "language" && value == "") {
      this.setState({ language: "en" });
    }
  }

  render() {
    $(document).ready(function() {
      $('.submit-btn-loader').hide();
    });
    var exam_name;
    var examid;
    var exam_time;
    var question;
    if (this.props.location.state) {
      exam_name = this.props.location.state.exam_name
      examid = this.props.location.state.response
      exam_time = this.props.location.state.exam_time
      question = this.props.location.state.question
    }
    else {
      exam_name = "IQS CBT Product";
      examid = "";
      exam_time = "";
      question = "";
    }
    var agreement;
    if (this.state.agreement) {
      agreement = this.state.agreement;
    }
    else {
      agreement = "<div><p>No Aggrement content found.</p><div>";
    }
    const userName= localStorage.getItem('userData.name');
    // eslint-disable-next-line
    if (this.state.isLoading == true) {
      return (
        <div className="result_loder"><TransverseLoading color="#F92424" /></div>
      );
    }
    return (
      <div className="exam-agreement-form">
        <div>
          <div className="exam-intro-section-header-wrapper">
            <div className="exam-header-section-wrapper">
              <div className="agree"><span><b>Agree to Terms</b></span></div>
            </div>
            <div className="exam-header-inner-wrapper">
              <div className="exam-title"><span>Exam : </span>{exam_name}</div>
              <div className="candidate-name"><span>Candidate : </span>{userName}</div>
            </div>
          </div>
        </div>
        <div className='exam-agreement-form-wrapper'>
          <div className="agreement-wrapper">
            {ReactHtmlParser(agreement)}
          </div>
          <div className="checkbox-wrapper">
            <label className="pt-label right">
              <div className="pt-input-group">
                <input
                  className={ "form-control pt-input accept" }
                  name={ "accept" }
                  type={ "checkbox" }
                  onChange={this.onBlur}
                />
              </div>
              Yes, I accept
            </label>
            <label className="pt-label right">
              <div className="pt-input-group">
                <input
                  className={ "form-control pt-input not_accept" }
                  name={ "not_accept" }
                  type={ "checkbox" }
                  onChange={this.onBlur}
                />
              </div>
              No, I do not accept
            </label>
          </div>
        </div>
        <div className="agreement-btn-wrapper">
          <button data-question={question} exam_time={exam_time} exam={exam_name} id={examid} className="fa fa-check" onClick={this.redirectToTarget}> Confirm</button><span className="loader">{this.state.loader}</span>
        </div>
      </div>
    );
  }
}

export default Agreement;
