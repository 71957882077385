import React, {Component} from 'react';
import '../../css/Questions.css';
import '../../css/Exam.css';
import '../../css/Tutorial.css';
import ReactHtmlParser from 'react-html-parser';
import $ from "jquery";
import {CreateResult} from '../../services/PostData';
import {UpdateResult} from '../../services/PostData';
import {UpdateFlagResult} from '../../services/PostData';
import {UpdateUserExam} from '../../services/PostData';
import {UpdatePauseTimeResult} from '../../services/PostData';
import {UpdateTotalMarksResult} from '../../services/PostData';
import {RemovePaymentData} from '../../services/PostData';
import {BlockBackendUser} from '../../services/PostData';
import {SendResultMail} from '../../services/PostData';
import {SendQuestionSetMail} from '../../services/PostData';
import {AddQuestionSet} from '../../services/PostData';
import Lightbox from 'react-image-lightbox';
import Iframe from 'react-iframe';
import 'react-image-lightbox/style.css';
import {
  Icon,
  TextArea
} from "@blueprintjs/core";
import 'jquery-ui-dist/jquery-ui';
import { CircularProgressbar } from 'react-circular-progressbar';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Timer from 'react-compound-timer';
import ReactTooltip from "react-tooltip";
import Popup from "reactjs-popup";
import ClipLoader from "react-spinners/ClipLoader";
import exam_logo from '../../logo_exam.png';
import Dialog from 'react-bootstrap-dialog';
import Editor from 'react-medium-editor';
import { LoopCircleLoading } from 'react-loadingg';
// import { Offline } from "react-detect-offline";
// load theme styles with webpack
require('medium-editor/dist/css/medium-editor.css');
require('medium-editor/dist/css/themes/default.css');

// Strikeout functionality.
var selector = ".options-wrapper li span";
var $curParent, Content;
$(document).delegate(selector,"dblclick", function(){
  var total_strikout = $( ".options-wrapper li" ).length;
  var no_strikeout = $( ".options-wrapper li strike" ).length + 1;

  if($(this).closest("strike").length) {
    $('.options-wrapper li strike span').removeClass('strike1');
    Content = $(this).parent("strike").html();
    $curParent = $(this).closest("strike");
    $(Content).insertAfter($curParent);
    $(this).closest("strike").remove();
    $('.nav-list a').removeAttr('strikeout');
  }
  else {
    // eslint-disable-next-line
    if (total_strikout != no_strikeout) {
      $(this).wrapAll("<strike />");
    }
  }
});

class Questions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions:[],
      showItems: 1,
      maxItemsPerPage: 1,
      currentPage: 1,
      photoIndex: 0,
      isOpen: false,
      userInfo: [],
      renderer: '',
      selected_answer: 0,
      option_selected: '',
      attempted: 0,
      resultData: [],
      pauseCount: 0,
      pauseAttemptCount: 0,
      pauseTime: 0,
      percentage: '',
      flag: 0,
      clear_feedback: '',
      cancel_feedback: '',
      question_feedback: '',
      chars_left: 300,
      max_char: 300,
      given_ans_data: [],
      att_ques_set: [],
      attachment_url: '',
      total_marks: 0,
      count: 1,
      loading: true,
      loader: '',
      lang: 'en',
      result_id: '',
      input: "",       // the input state
      output: "",      // the output state
      number: "",      // the number state for complex calculations
      degree: false,    // the state used to calculate trigonometric functions
      pauseTrigger: false,
      attempt_set: false,
    }

    this.onBlur= this.onBlur.bind(this);
    this.handleChange=this.handleChange.bind(this);
    this.clearChange= this.clearChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.exitModal = this.exitModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.saveModal= this.saveModal.bind(this);
    this.getDataForModal= this.getDataForModal.bind(this);
    this.openSectionModal = this.openSectionModal.bind(this);
    this.sectionClearModal = this.sectionClearModal.bind(this);
    this.filterModal = this.filterModal.bind(this);
    this.paragraphSwitch= this.paragraphSwitch.bind(this);
    // this.checkInternetConnection= this.checkInternetConnection.bind(this);
  }

  // Scrolling Functionality.
  nextbtn() {
    $('#prevbtn').show();
    $('.navigation-wrapper').animate({scrollTop: '+=195'}, 500);
  }

  prevbtn() {
    $('#prevbtn').show();
    $('.navigation-wrapper').animate({scrollTop: '-=195'}, 500);
  }

  // eslint-disable-next-line
  onBackButtonEvent = () => {
    window.history.forward();
    this.props.history.push('/questions', {
      response: this.props.location.state.response,
      exam_name: this.props.location.state.exam_name,
      exam_time: this.props.location.state.exam_time,
      question: this.props.location.state.question,
      lang_sel: this.props.location.state.lang_sel,
      result_id: this.state.nid
    });
  }

  componentDidMount() {
    window.addEventListener("popstate", this.onBackButtonEvent);
    // eslint-disable-next-line
    if (this.props.location.state == undefined) {
      this.props.history.push('/');
      window.location.reload();
    }
    const examid = this.props.location.state.response;
    const csrf_token = localStorage.getItem('userData.csrf_token');
    const auth = localStorage.getItem('userData.auth');
    const uid = localStorage.getItem('userData.uid');
    if (typeof(this.props.location.state.result_id) === "undefined") {
      let initial_data = {
        "type": "result",
        "examid": examid,
        "x_csrf_token": csrf_token,
        "auth": auth,
      }
      CreateResult('api/result', initial_data)
      .then(response => {
        this.setState({ "nid" : response.nid});
      })
      .catch(err => {
        return err
      })
    }
    else if (this.props.location.state.result_id){
      this.setState({ "nid" : this.props.location.state.result_id })

      fetch(process.env.REACT_APP_CBT + 'node/' + this.props.location.state.result_id + '?_format=json')
      .then(res => res.json()) // It resolves the promise with a JSON object
      .then(res => {
        var all_solved_questions = [];
        var total_solved_question = 1;
        if (res.field_solved_question_and_answer.length > 0 ) {
          all_solved_questions = res.field_solved_question_and_answer[0]['value'].split("\n");
          total_solved_question = all_solved_questions.length;
        }
        this.setState({
          currentPage: res.field_result_paused_question.length !== 0 ? res.field_result_paused_question[0].value : total_solved_question,
         })
      })
    }

    var userName = localStorage.getItem('userData.name');
    if (userName.includes('IQSOL')) {
      let user_data = {
        "examid": examid,
        "userid": uid,
        "x_csrf_token": csrf_token,
        "auth": auth
      }

      UpdateUserExam('api/user/update', user_data)
      .then(response => {
        console.log("User updated");
      })
      .catch(err => {
        return err
      })
    }

    var lang_select;
    if (this.props.location.state.lang_sel) {
      lang_select = this.props.location.state.lang_sel;
    }
    else {
      lang_select = "en";
    }

    this.setState({result_id: this.props.location.state.result_id});

    if(examid) {
      fetch(process.env.REACT_APP_CBT + 'api/question/' + lang_select + '/' + examid + '/' + uid + '?_format=json')
      .then(res => res.json()) // It resolves the promise with a JSON object
      .then(res => {
        const csrf_token = localStorage.getItem('userData.csrf_token');
        const auth = localStorage.getItem('userData.auth');

        if (!this.state.result_id) {
          // eslint-disable-next-line
          if (this.state.attempt_set == false) {
            if (this.state.nid) {
              var data_str = encodeURIComponent(JSON.stringify(res));
              let data = {
                "nid": this.state.nid,
                csrf_token,
                auth,
                "att_ques_set": data_str,
              };

              AddQuestionSet('node/' + this.state.nid , data)
              .then((response) => {
                console.log("Result Updated with Question Set!");
                this.setState({ attempt_set : true });
              });
            }
          }
        }

        this.setState({ questions : res });
      })

      fetch(process.env.REACT_APP_CBT + 'node/' + examid + '?_format=json')
      .then(res => res.json()) // It resolves the promise with a JSON object
      .then(res => {
        this.setState({ attachment_url: res.field_exam_attachment })
      })
    }

    // Fetch logged in user info.
    const userID = localStorage.getItem('userData.uid');
    if(userID) {
      fetch(process.env.REACT_APP_CBT + '/user/' + userID + '?_format=json')
      .then(data => data.json()) // It resolves the promise with a JSON object
      .then(data => {
        this.setState({ userInfo : data });
      })
    }

    // this.timerID = setInterval(
    //   () => this.checkInternetConnection(),
    //   1000
    // );
  }

  componentWillUnmount() {
    // clearInterval(this.timerID);
  }

  controlTime() {
    // eslint-disable-next-line
    if (this.state.questions.length > 0 && this.state.attempt_set == true) {
      let paused_time;
      let milisec;
      let clicks;
      let data;
      let paused_ques_data;

      const csrf_token = localStorage.getItem('userData.csrf_token');
      const auth = localStorage.getItem('userData.auth');

      // Set Exam timer.
      let exam_time = this.props.location.state.exam_time;

      let loader = <ClipLoader
            size={20}
            color={"red"}
            loading={this.state.loading}
          />;

      var paused_q = "";

      return (
        <Timer initialTime={exam_time} direction="backward" lastunit="s" timeToUpdate={10}
          checkpoints = {[
            {
              time: 0,
              callback: () => confirmAlert({
                title: 'Exam Time up!',
                message: 'Click to submit the Exam',
                buttons: [
                  {
                    label: 'Submit',
                    onClick: () => this.finishOnTimeUp(),
                  }
                ]
              }),
            },
            {
              time: 1200000,
              callback: () => $('.control-time').addClass('blue'),
            },
            {
              time: 600000,
              callback: () => [
                $('.control-time').removeClass('blue'),
                $('.control-time').addClass('red')
              ],
            },
          ]}
          onPause={async () => (
            // eslint-disable-next-line
            this.setState({ pauseTrigger: true}),
            this.setState({loader: loader}),
            await fetch(process.env.REACT_APP_CBT + 'node/' + this.state.nid + '?_format=json')
            .then(res => res.json()) // It resolves the promise with a JSON object
            .then(res => {
              this.setState({ pauseTime: res.field_remaining_time[0].value })
              this.setState({ pauseCount: res.field_result_pause_count[0].value })
            }),

            clicks = this.state.pauseCount + 1 ,
            // eslint-disable-next-line
            (clicks == 2) ? $("#control-pause-buttons").attr('disabled', true) : $("#control-pause-buttons").attr('disabled', false),
            // eslint-disable-next-line
            (clicks == 2) ? $("#control-resume-buttons").attr('disabled', false) : '',
            // eslint-disable-next-line
            (clicks == 2) ? $("#control-pause-buttons div").addClass('important-loader') : $("#control-pause-buttons").removeAttr(''),
            (clicks > 2) ? $("#control-pause-buttons").hide() : $("#control-pause-buttons").removeAttr(''),

            // freeze buttons on pause.
            (this.state.currentPage > 1) ? $("#control-back-buttons").attr('disabled', true) : '',
            $("#control-next-buttons").attr('disabled', true),
            $(".questions-page .navigation .nav-list a").attr('style','pointer-events: none;'),
            $(".questions-page .answer-container .answer li input.options").attr('disabled', true),
            $(".flag-question").attr('disabled', true),
            $(".unflag-question").attr('disabled', true),
            $('#section-review-button').attr('style','pointer-events: none;'),
            $('#feedback_button').attr('style','pointer-events: none;'),
            $('#control-next-buttons').attr('style','pointer-events: none;'),
            $('#control-back-buttons').attr('style','pointer-events: none;'),
            $('.flag-question').attr('style','pointer-events: none;'),
            $('.unflag-question').attr('style','pointer-events: none;'),
            $('.questions-page .answer-container .answer li').attr('style','pointer-events: none;opacity: 0.6;'),

            this.setState({ pauseCount: clicks }),
            paused_time = $('.control-time')[0].innerText,
            paused_time = paused_time.split(':'),
            milisec = ((+paused_time[0]) * 60 * 60 + (+paused_time[1]) * 60 + (+paused_time[2])) * 1000,

            $('.nav-list a').each((index, element) => {
              // eslint-disable-next-line
              if ($('.nav-list a[not_sel]').length > 0 && this.state.currentPage != 1) {
                paused_q = $('.nav-list a[not_sel]').parent()[0].id
                paused_q = paused_q.split("t")
                paused_q = parseInt(paused_q[1])+1
              }
              else {
                paused_q = this.state.currentPage;
              }
            }),

            paused_ques_data = $("#control-pause-buttons").attr('data-question'),

            data = {
              "nid": this.state.nid,
              "pauseCount": clicks,
              "pauseTime": milisec,
              csrf_token,
              auth,
              paused_q,
              paused_ques_data
            },

            UpdatePauseTimeResult('node/' + this.state.nid , data)
            .then((response) => {
              console.log("Result Updated with pause time!");
            }),

            document.getElementById("finish-button").disabled = true,
            (clicks < 2) ? $("#control-pause-buttons").attr('class', 'not-visible') : $("#control-pause-buttons").attr('id', 'pause-disable'),
            $("#control-resume-buttons").attr('class', 'visible')

          )}

          onResume={() => (
            // eslint-disable-next-line
            this.setState({ pauseTrigger: false}),
            this.setState({loader: ""}),
            clicks = this.state.pauseCount + 1 ,
            // unfreeze buttons on resume.
            $("#control-pause-buttons").attr('class', 'visible'),
            $("#control-resume-buttons").attr('class', 'not-visible'),
            document.getElementById("finish-button").disabled = false,
            (this.state.currentPage > 1) ? $("#control-back-buttons").attr('disabled', false) : '',
            $("#control-next-buttons").attr('disabled', false),
            $(".questions-page .navigation .nav-list a").attr('style','pointer-events: visible;'),
            $(".questions-page .answer-container .answer li input.options").attr('disabled', false),
            $(".flag-question").attr('disabled', false),
            $(".unflag-question").attr('disabled', false),
            $('#section-review-button').attr('style','pointer-events: visible;'),
            $('#feedback_button').attr('style','pointer-events: visible;'),
            $('#control-next-buttons').attr('style','pointer-events: visible;'),
            $('#control-back-buttons').attr('style','pointer-events: visible;'),
            $('.flag-question').attr('style','pointer-events: visible;'),
            $('.unflag-question').attr('style','pointer-events: visible;'),
            $('.questions-page .answer-container .answer li').attr('style','pointer-events: visible;opacity: 1;'),

            (clicks >= 3) ? $(".control-timer-buttons p.last-review").html("<button data-toggle='tooltip' title='You have already paused exam for 2 times.' class='resume-last'>Pause</button>") : $("#control-pause-buttons").removeAttr('test')
          )}
        >
        {({resume, pause, start, stop}) => (
          <div>
            <p className="control-time">
              <Timer.Hours formatValue={(text) => (text.toString().length > 1 ? text : "0" + text)} /> : <Timer.Minutes formatValue={(text) => (text.toString().length > 1 ? text : "0" + text)} /> : <Timer.Seconds formatValue={(text) => (text.toString().length > 1 ? text : "0" + text)} />
              <button className="stop-timer" onClick={stop}>Stop</button>
              <button className="start-timer" onClick={start}>Start</button>
            </p>
            <div className="control-timer-buttons">
              <p className="hide-loader" data-tip="Exam will be automatically ends after 36 hours from start time if not started again."><button id="control-pause-buttons" data-question={this.props.location.state.question} onClick={() => {pause();}}>Pause{this.state.loader}</button></p>
              <ReactTooltip place="top" type="warning" effect="float" textColor="#000"/>
              <p className="last-review"><button id="control-resume-buttons" className="not-visible" onClick={() => {resume();}}>Resume</button></p>
            </div>
          </div>
        )}
        </Timer>
      );
    }
    else {
      return (
        <div>
          <p className="control-time">0 : 0 : 0</p>
        </div>
      );
    }

  }

  getAnswerData() {
    fetch(process.env.REACT_APP_CBT + 'node/' + this.props.location.state.result_id + '?_format=json')
    .then(res => res.json()) // It resolves the promise with a JSON object
    .then(res => {
      if (res.field_attempted_question_set[0]) {
        var my_object_att = JSON.parse(decodeURIComponent(res.field_attempted_question_set[0].value))
        this.setState({ att_ques_set: my_object_att })
        this.setState({ questions : my_object_att });
        this.setState({ attempt_set : true });
      }
      this.setState({ given_ans_data: res.field_solved_question_and_answer })
      if (res.field_paused_question_data[0]) {
        var my_object = JSON.parse(decodeURIComponent(res.field_paused_question_data[0].value))
        this.setState({ questions: my_object })
        $("#control-pause-buttons").attr('data-question', res.field_paused_question_data[0].value)

        $('.navigation-wrapper li').removeClass('submitted-quest');
        $('.navigation-wrapper li a').attr('class', 'nav-button not-answered');
        $('.navigation-wrapper li a').attr('not_sel');
        $('.navigation-wrapper li a').removeAttr('ans_sel');
        $('.navigation-wrapper li a.nav-button i').attr('class', 'no-fa-class');
      }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    var cp1 = this.state.currentPage - 1;
    var page = this.state.currentPage;
    var te = $('.navigation-wrapper .nav-list a#nav-button' + cp1).attr('ans_sel');

    $('.questions-list #options-wrapper' + this.state.currentPage + ' input[type=radio][value=' + te + ']').attr('checked', true);

    var feed = $('.navigation-wrapper .nav-list a#nav-button' + cp1).attr('feedback_given');
    if (feed) {
      $('.answer-container #feedback_no' + this.state.currentPage + ' textarea').val(feed);
      $('.answer-container #feedback_no' + this.state.currentPage + ' textarea').html(feed);
    }

    if(prevState.nid !== this.state.nid) {
      if(this.state.nid) {
        fetch(process.env.REACT_APP_CBT + 'api/attempted/' + this.state.nid + '?_format=json')
        .then(res => res.json()) // It resolves the promise with a JSON object
        .then(res => {
          this.setState({ attempted: res })
        })
      }
    }

    $("div.ril__outer").draggable();
    $(".questions-page .popup-content .calculator").draggable();
    $(".update-popup-html").draggable();

    if ($('#nav-button' + cp1).attr('ans_sel')) {
      var selected_answer = $('#nav-button' + cp1).attr('ans_sel');
      selected_answer = parseInt(selected_answer) + 1;
      $('#options-wrapper' + page + ' li[value="' + selected_answer + '"]').addClass('option-selected');
      $('.answer-container #page' + this.state.currentPage + ' .submit-question').removeAttr('disabled');
    }
    else {
      $('.answer-container #page' + this.state.currentPage + ' .submit-question').attr('disabled', true);
    }

    // eslint-disable-next-line
    if ($('.navigation-wrapper .nav-list a#nav-button' + cp1).attr('flag_sel') == 1) {
      $("#options-wrapper" + this.state.currentPage).attr('flag', "1");
      $('#flag-' + this.state.currentPage).attr('style', 'display: none !important;');
      $('#unflag-' + this.state.currentPage).attr('style', 'display: inline-block !important;');
    }
    else {
      $("#options-wrapper" + this.state.currentPage).attr('flag', "0");
      $('.navigation-wrapper .nav-list a#nav-button' + cp1).removeClass('fa-flag');
      $('.navigation-wrapper .nav-list a#nav-button' + cp1).removeClass('fa');
      $('.navigation-wrapper .nav-list a#nav-button' + cp1).empty();
      $('.navigation-wrapper .nav-list a#nav-button' + cp1).html(page + '<i class="no-fa-class"></i>');
      $('#flag-' + this.state.currentPage).attr('style', 'display: inline-block !important;');
      $('#unflag-' + this.state.currentPage).attr('style', 'display: none !important;');
    }

    // disable pause button if exam paused count is 2.
    const examPauseCount = this.props.location.state.pauseAttemptCount;
    // eslint-disable-next-line
    if (examPauseCount == 2) {
      $("#control-pause-buttons").attr('disabled', true);
      $("#control-pause-buttons").attr('id', 'pause-disable');
      $("#control-resume-buttons").attr('disabled', true);
      $("#control-resume-buttons").attr('id', 'disable');
      $(".control-timer-buttons p.last-review").html("<button data-toggle='tooltip' title='You have already paused exam for 2 times.' class='resume-last'>Pause</button>");
    }

    // Get answered data on resume exam.
    let value = '';
    let sub_value = '';
    if (this.state.result_id) {
      if(prevState.result_id !== this.state.result_id) {
        this.getAnswerData();
      }

      if (this.state.given_ans_data.length > 0) {
        return this.state.given_ans_data.map((qitem) => {
          value = qitem.value.split("\n");
          var cp = this.state.currentPage;
          // eslint-disable-next-line
          return value.map((val, index) => {
            if (val) {
              sub_value = val.split("|");
              if (sub_value) {
                let questionNum = $('.questions-list .options-wrapper').attr('quesid');
                // eslint-disable-next-line
                if (questionNum && sub_value[0] == questionNum) {
                  $('.questions-list .options-wrapper input[type=radio][cid=' + sub_value[1] + '][id=' + sub_value[0] + '][selected_value=' + sub_value[2] +']').attr('checked', true);
                  $('.pagination-button-wrapper-parent button.feedback_button').attr('ques_id', sub_value[0]);
                  $('.pagination-button-wrapper-parent .popup-content .feedback-section textarea').attr('id', sub_value[0]);
                  $('.pagination-button-wrapper-parent .popup-content .remove-comment-sec button').attr('remove_ques_id', sub_value[0]);
                }
                if (sub_value[0]) {
                  $('.navigation-wrapper li[nav_quesid="' + sub_value[0] + '"] a').removeAttr('not_sel');
                  $('.navigation-wrapper li[nav_quesid="' + sub_value[0] + '"] a').attr('ans_sel', sub_value[2] - 1 );
                  $('.navigation-wrapper li[nav_quesid="' + sub_value[0] + '"]').addClass('submitted-quest');
                }
                // eslint-disable-next-line
                if (sub_value[2] == 0) {
                  $('.navigation-wrapper li[nav_quesid="' + sub_value[0] + '"] a').removeClass('answered');
                  $('.navigation-wrapper li[nav_quesid="' + sub_value[0] + '"] a').addClass('not-answered')
                  $('.navigation-wrapper li[nav_quesid="' + sub_value[0] + '"] a').attr('not_sel');
                  $('.navigation-wrapper li[nav_quesid="' + sub_value[0] + '"] a').removeAttr('ans_sel');
                  $('.navigation-wrapper li[nav_quesid=' + sub_value[0] + ']').removeClass('submitted-quest');
                }
                else {
                  $('.navigation-wrapper li[nav_quesid="' + sub_value[0] + '"] a').attr('class', 'nav-button answered');
                }

                // eslint-disable-next-line
                if (sub_value[6] == 1) {
                  $('.navigation-wrapper li[nav_quesid=' + sub_value[0] + '] a.nav-button i').attr('class', 'fa fa-flag');
                }
                else {
                  $('.navigation-wrapper li[nav_quesid=' + sub_value[0] + '] a.nav-button i').attr('class', 'no-fa-class');
                }

                // eslint-disable-next-line
                if (questionNum && sub_value[0] == questionNum && sub_value[7] != '') {
                  $('.navigation-wrapper li[nav_quesid=' + sub_value[0] + '] a.nav-button').attr('feedback_given', sub_value[7]);
                  $('.pagination-button-wrapper-parent #page' + cp + ' #feedback_no' + cp + ' textarea').attr('defaultValue', sub_value[7]);
                }
                // eslint-disable-next-line
                if (questionNum && sub_value[0] == questionNum && sub_value[7] == '') {
                  $('.pagination-button-wrapper-parent #page' + cp + ' #feedback_no' + cp + ' textarea').val('');
                }

                setTimeout(() => this.setState({ given_ans_data: [] }), 5000);
              }
            }
          });
        });
      }
    }

    if(prevState.attempted !== this.state.attempted) {
      if (this.state.attempted > 0 ) {
        var questionLength = JSON.parse(decodeURIComponent(this.props.location.state.question)).length
        var percent = (this.state.attempted)/(questionLength)*100;
        this.setState({percentage: percent});
      }
    }
  }

  onBlur(e) {
    this.setState({ "question_id": e.target.id });
    this.setState({ selected_answer: e.currentTarget.attributes['selected_value'].value });
    this.setState({ option_selected: e.target.value });

    var cp = this.state.currentPage-1;
    // eslint-disable-next-line
    if ($('.navigation-wrapper .nav-list a#nav-button' + cp).attr('ans_sel') == undefined) {
      this.setState({'no_of_attempt': 1});
      $('.navigation-wrapper .nav-list a#nav-button' + cp).attr('ans_sel', e.target.value);
      var percent = (this.state.attempted + 1)/(this.state.questions.length)*100;
      this.setState({percentage: percent})
      $('.navigation-wrapper .nav-list a#nav-button' + cp).removeAttr('not_sel');
    }
    // eslint-disable-next-line
    else if ($('.navigation-wrapper .nav-list a#nav-button' + cp).attr('ans_sel') != undefined) {
      this.setState({'no_of_attempt': this.state.no_of_attempt + 1});
      $('.navigation-wrapper .nav-list a#nav-button' + cp).attr('ans_sel', e.target.value);
      $('.navigation-wrapper .nav-list a#nav-button' + cp).removeAttr('not_sel');
    }
    else if ($('.navigation-wrapper .nav-list a#nav-button' + cp).attr('ans_sel') > 1) {
      this.setState({'no_of_attempt': this.state.no_of_attempt + 1});
    }
    // eslint-disable-next-line
    var ans_sel = $('.navigation-wrapper .nav-list a#nav-button' + cp).attr('ans_sel');
  }

  handleChange(e) {
    this.setState({ "question_id": e.target.id });
    this.setState({ question_feedback: e.target.value });
    const charCount = e.target.value.length;
    const maxChar = this.state.max_char;
    const charLength = maxChar - charCount;
    this.setState({ chars_left: charLength });
    var cp = this.state.currentPage-1;
    $('.navigation-wrapper .nav-list a#nav-button' + cp).attr('feedback_given', e.target.value);
  }

  clearChange(e) {
    var cp = this.state.currentPage-1;
    $('.navigation-wrapper .nav-list a#nav-button' + cp).attr('feedback_given', '');
    var remove_ques_id = $('.remove-comment-sec button').attr('remove_ques_id');
    this.setState({ "question_id": remove_ques_id });
    this.setState({ clear_feedback: '' });
    if (this.state.clear_feedback === '') {
      this.setState({ question_feedback: ''});
    }
    this.setState({ chars_left: 300 });
    $('.answer-container .feedback-section textarea[id="'+ remove_ques_id + '"]').val('');
    $('.answer-container .feedback-section textarea[id="'+ remove_ques_id + '"]').html('');
  }

  openModal() {
    this.setState({ open: true });
  }

  closeModal(e) {
    this.setState({ chars_left: 300 });
    this.setState({ question_feedback: '' });
    var cp = this.state.currentPage-1;
    $('.navigation-wrapper .nav-list a#nav-button' + cp).attr('feedback_given', this.state.cancel_feedback);
  }

  exitModal() {
    this.setState({ open: false });
    this.setState({ sectionOpen: false });
  }

  saveModal() {
    var correct_answer = $("#correct1").attr('value');
    var marks = $(".options-wrapper .options").attr('marks');
    var chapter_id = $(".options-wrapper .options").attr('cid');
    // eslint-disable-next-line
    var chapter_id = 0;
    if ($(".options-wrapper .options").attr('cid')) {
      chapter_id = $(".options-wrapper .options").attr('cid');
    }
    var user_marks;
    var iscorrect;
    // eslint-disable-next-line
    if (correct_answer == this.state.selected_answer) {
      iscorrect = 1;
    }
    else {
      iscorrect = 0;
    }
    const csrf_token = localStorage.getItem('userData.csrf_token');
    const auth = localStorage.getItem('userData.auth');
    const flag = $("#options-wrapper" + this.state.currentPage).attr('flag');

    // eslint-disable-next-line
    if (this.state.selected_answer == 0) {
      this.setState({selected_answer: 0});
    }

    // Get current exam current time.
    var paused_time = $('.control-time')[0].innerText;
    paused_time = paused_time.split(':');
    var milisec = ((+paused_time[0]) * 60 * 60 + (+paused_time[1]) * 60 + (+paused_time[2])) * 1000;

    let data = {
      "question_id" : this.state.question_id,
      "chapter_id" : chapter_id,
      "correct_answer" : correct_answer,
      "selected_answer" : this.state.selected_answer,
      "marks": marks,
      "user_marks": user_marks,
      "iscorrect": iscorrect,
      "nid": this.state.nid,
      "flag": flag,
      "question_feedback": this.state.question_feedback,
      "remaining_exam_time": milisec,
      csrf_token,
      auth
    };
    // eslint-disable-next-line
    if(this.state.nid) {
      UpdateResult('api/result/update', data)
      .then((response) => {
        this.setState({ question_feedback: '' })
        console.log("Result Updated!");
      })
    }
  }

  getDataForModal(e) {
    let ques_id = $('.pagination-button-wrapper button').attr('ques_id');
    fetch(process.env.REACT_APP_CBT + 'node/' + this.state.nid + '?_format=json')
    .then(res => res.json()) // It resolves the promise with a JSON object
    .then(res => {
      let value = '';
      let sub_value = '';
      let param = '';
      if (res.field_solved_question_and_answer.length > 0) {
        return res.field_solved_question_and_answer.map((qitem) => {
          value = qitem.value.split("\n");
          // eslint-disable-next-line
          return value.map((val, index) => {
            if (val) {
              sub_value = val.split("|");
              // eslint-disable-next-line
              if (sub_value[0] == ques_id ) {
                // eslint-disable-next-line
                if (sub_value[7] !== '') {
                  param = sub_value[7];
                }
                this.setState({
                  question_feedback : param,
                  cancel_feedback: param
                });
              }
            }
          });
        });
      }
    })
  }

  //Section modal
  openSectionModal() {
    this.setState({ sectionOpen: true });
    // Get answered data on resume exam.
    let value = '';
    let sub_value = '';

    if (this.state.result_id) {

      fetch(process.env.REACT_APP_CBT + 'node/' + this.props.location.state.result_id + '?_format=json')
      .then(res => res.json()) // It resolves the promise with a JSON object
      .then(res => {
        this.setState({ given_ans_data: res.field_solved_question_and_answer })
        if (res.field_paused_question_data[0]) {
          var my_object = JSON.parse(decodeURIComponent(res.field_attempted_question_set[0].value))
          this.setState({ att_ques_set: my_object })
          $("#control-pause-buttons").attr('data-question', res.field_attempted_question_set[0].value)
        }
      })

      if (this.state.given_ans_data.length > 0) {
        return this.state.given_ans_data.map((qitem) => {
          value = qitem.value.split("\n");

          // eslint-disable-next-line
          return value.map((val, index) => {
            if (val) {
              sub_value = val.split("|");
              if (sub_value) {
                let questionNum = $('.questions-list .options-wrapper').attr('quesid');
                // eslint-disable-next-line
                if (questionNum && sub_value[0] == questionNum) {
                  $('.questions-list .options-wrapper input[type=radio][cid=' + sub_value[1] + '][id=' + sub_value[0] + '][selected_value=' + sub_value[2] +']').attr('checked', true);
                }
                if (sub_value[0]) {
                  $('.navigation-wrapper li[nav_quesid="' + sub_value[0] + '"] a').removeAttr('not_sel');
                  $('.navigation-wrapper li[nav_quesid="' + sub_value[0] + '"] a').attr('ans_sel', sub_value[2] - 1 );
                  $('.navigation-wrapper li[nav_quesid="' + sub_value[0] + '"]').addClass('submitted-quest');
                }

                // eslint-disable-next-line
                if (sub_value[6] == 1) {
                  $('.navigation-wrapper li[nav_quesid=' + sub_value[0] + '] a.nav-button i').attr('class', 'fa fa-flag');
                }
                else {
                  $('.navigation-wrapper li[nav_quesid=' + sub_value[0] + '] a.nav-button i').removeClass('fa fa-flag');
                }

                $('.navigation-wrapper li[nav_quesid="' + sub_value[0] + '"] a').attr('class', 'nav-button answered');
              }
            }
          });
        });
      }
    }
  }

  sectionClearModal() {
    $(".filter-criteria-section #unattempted").prop("checked", false);
    $(".filter-criteria-section #attempted").prop("checked", false);
    $(".filter-criteria-section #flagged").prop("checked", false);
    $('.section-review-content .navigation .nav-list').attr('style', 'display: block !important;');
  }

  //show questions on filter criteria.
  filterModal() {
    var unattem_check = document.getElementById('unattempted');
    var attem_check = document.getElementById('attempted');
    var flagged_check = document.getElementById('flagged');

    if (unattem_check != null || attem_check != null || flagged_check != null) {
      // eslint-disable-next-line
      if (unattem_check.checked == true) {
        $('.section-review-content .navigation .nav-list .nav-button.not-answered').parent('li').attr('style', 'display: block !important;');
        $('.section-review-content .navigation .nav-list .selected').parent('li').attr('style', 'display: block !important;');
        $('.section-review-content .navigation .nav-list .nav-button.answered').parent('li').attr('style', 'display: none !important;');
        $('.section-review-content .navigation .nav-list .nav-button.not-answered .fa-flag').parent('a').parent('li').attr('style', 'display: none !important;');
      }
      // eslint-disable-next-line
      else if (attem_check.checked == true) {
        $('.section-review-content .navigation .nav-list .nav-button.not-answered').parent('li').attr('style', 'display: none !important;');
        $('.section-review-content .navigation .nav-list .selected').parent('li').attr('style', 'display: none !important;');
        $('.section-review-content .navigation .nav-list .nav-button.answered').parent('li').attr('style', 'display: block !important;');
        $('.section-review-content .navigation .nav-list .nav-button.not-answered .fa-flag').parent('a').parent('li').attr('style', 'display: none !important;');
      }
      // eslint-disable-next-line
      else if (flagged_check.checked == true) {
        $('.section-review-content .navigation .nav-list .nav-button.not-answered').parent('li').attr('style', 'display: none !important;');
        $('.section-review-content .navigation .nav-list .selected').parent('li').attr('style', 'display: none !important;');
        $('.section-review-content .navigation .nav-list .nav-button.answered').parent('li').attr('style', 'display: none !important;');
        $('.section-review-content .navigation .nav-list .nav-button.not-answered .fa-flag').parent('a').parent('li').attr('style', 'display: block !important;');
        $('.section-review-content .navigation .nav-list .nav-button.answered .fa-flag').parent('a').parent('li').attr('style', 'display: block !important;');
      }
      else {
        $('.section-review-content .navigation .nav-list').attr('style', 'display: block !important;');
      }
      // eslint-disable-next-line
      if (attem_check.checked == true && unattem_check.checked == true) {
        $('.section-review-content .navigation .nav-list').attr('style', 'display: block !important;');
        $('.section-review-content .navigation .nav-list .nav-button.not-answered .fa-flag').parent('a').parent('li').attr('style', 'display: none !important;');
      }
      // eslint-disable-next-line
      if (attem_check.checked == true && flagged_check.checked == true) {
        $('.section-review-content .navigation .nav-list .nav-button.answered .no-fa-class').parent('a').parent('li').attr('style', 'display: block !important;');
        $('.section-review-content .navigation .nav-list .nav-button.not-answered .fa-flag').parent('a').parent('li').attr('style', 'display: block !important;');
      }
      // eslint-disable-next-line
      if (flagged_check.checked == true && unattem_check.checked == true) {
        $('.section-review-content .navigation .nav-list .nav-button.not-answered .no-fa-class').parent('a').parent('li').attr('style', 'display: block !important;');
        $('.section-review-content .navigation .nav-list .nav-button.not-answered .fa-flag').parent('a').parent('li').attr('style', 'display: block !important;');
      }
      // eslint-disable-next-line
      if (attem_check.checked == true && unattem_check.checked == true && flagged_check.checked == true) {
        $('.section-review-content .navigation .nav-list').attr('style', 'display: block !important;');
      }
    }
  }

  navigation_questions() {
    var questionSet = [];
    if (this.state.att_ques_set.length > 0 ) {
      questionSet = this.state.att_ques_set;
    }
    else if (this.state.questions.length > 0) {
      questionSet = this.state.questions;
    }
    if(questionSet.length > 0) {
      let index = 1;
      // eslint-disable-next-line
      return questionSet.map((questions, item) => {
        if (questions.node_data != null) {
          let newIndex = item + index;
          let selected_nav = this.state.currentPage;
          let cp1 = this.state.currentPage - 1;
          let nav_class;
          let btn_class;
          let flag_class;
          $('.next-btn-submit').on('click', function(){
            $('#nav-list' + cp1).addClass('submitted-quest');
          });

          if (selected_nav === newIndex && ($('#nav-button' + item).attr('ans_sel'))) {
            btn_class = 'nav-button answered selected';
            nav_class = '<span class="selected-nav"></span>';
          }
          else if (selected_nav !== newIndex && ($('#nav-button' + item).attr('ans_sel'))) {
            nav_class = '';
            btn_class = 'nav-button answered';
          }
          else if (selected_nav === newIndex) {
            btn_class = 'selected';
            nav_class = '<span class="selected-nav"></span>';
          }
          else {
            nav_class = '';
            btn_class = 'nav-button not-answered';
          }

          // Show/Hide flag on navigation buttons.
          if ($('#nav-button' + item).attr('flag_sel')) {
            flag_class = 'fa fa-flag';
          }
          else {
            flag_class = 'no-fa-class';
          }

          return (
            <li key={item} className="nav-list" nav_quesid={questions.node_data.nid[0].value} id={"nav-list" + item}>
              {/* eslint-disable-next-line */}
              <a id={"nav-button" + item} className={btn_class} not_sel="" feedback_given="" onClick={() => this.changePageOnNav(item)}>{newIndex}
                <i className={flag_class}></i>
              </a>
              {ReactHtmlParser(nav_class)}
            </li>
          );
        }
      });
    }
  }

  changePageOnNav(index) {
    // eslint-disable-next-line
    if (this.state.questions.length > index) {
      this.setState({
        currentPage: index + 1,
      });
    }

    if ($('.navigation-wrapper .nav-list a#nav-button' + index).attr('flag_sel')) {
      $('.navigation-wrapper .nav-list a#nav-button' + index + ' i').attr('class', 'fa fa-flag');
    }

    // eslint-disable-next-line
    if (index != this.state.currentPage) {
      $('.control-timer-buttons').attr('style', 'display: inline-flex;');
      $('.paragraphSpilt-left .spilt-paragraph-wrapper .spilt_paragraph').attr('style', 'display: block !important;');
    }

    $('.answer-container .paragraphSpilt-left .spilt-paragraph-wrapper').attr('class', 'spilt-paragraph-wrapper col-sm-5 new-wrapper');
    $('.answer-container .paragraphSpilt-left .question-paragraph-link .attachment_link').attr('style','pointer-events: none;');
  }

  questions(){
    // eslint-disable-next-line
    let return_data;
    const { photoIndex, isOpen } = this.state;
    // eslint-disable-next-line
    if (this.state.attempt_set == false || this.state.questions.length == 0) {
      return (
        <LoopCircleLoading color="#F92424" />
      );
    }
    // eslint-disable-next-line
    else if (this.state.attempt_set == true || this.state.questions.length > 0) {
      if (this.state.att_ques_set.length > 0) {
        return this.state.att_ques_set.slice((this.state.currentPage * this.state.maxItemsPerPage) - this.state.maxItemsPerPage, this.state.currentPage * this.state.maxItemsPerPage).map(({node_data, question}, item) => {
          let calci = '';
          let loader_btn = <ClipLoader
                size={20}
                color={"red"}
                loading={true}
              />;
          // eslint-disable-next-line
          if (node_data.field_question_allow_calci[0].value == 1) {
            calci = <Popup trigger={<div className="calci-btn"><button><Icon icon="calculator" /><span>Calculator</span></button><span className="calc-btn-loader">{loader_btn}</span></div>} position="left top">
              <div className="calculator" Style="width: 480px;border: 2px solid black;">
                <Iframe url="https://www.desmos.com/scientific"
                  width="480px"
                  height="450px"
                  id="calculator"
                  className="calculator-under"
                  position="relative"/>
              </div>
            </Popup>
          }
          let reference = '';
          let attachment_link = '';
          if (this.state.attachment_url.length > 0) {
            attachment_link = <li><a href={false} className="attachment_link" onClick={() => this.paragraphSwitch()}>Paragraph</a></li>
          }
          else {
            attachment_link = "";
          }
          if (node_data.field_question_attach_doc.length > 0) {
            var reference_no =  this.state.currentPage;
            var attached_docs = node_data.field_question_attach_doc;
            var link = "";
            $.each( attached_docs, function( i, val ) {
              var link_title = val.url.split("/");
              link_title = link_title.slice(-1)[0];
              link_title = link_title.replace(/([,%\d+])+/g, '');
              link += '<li><Icon icon="pdf" /><a class="fa fa-file-pdf-o" href=' + val.url + ' target="_blank"> ' + link_title + '</a></li>';
            });
            reference = <Popup className={"reference-popup-" + reference_no } trigger={<div><button type="button" className={"button reference-btn-" + reference_no}><Icon icon="multi-select" /><span>Reference</span></button></div>} position="bottom right">
               <div className="update-popup-html">
                {attachment_link}
                {ReactHtmlParser(link)}
                </div>
              </Popup>
            }
            let exhibit_image = '';
            if (node_data.field_question_exhibit_image.length !== 0) {
              const images = [
                node_data.field_question_exhibit_image[0].url
              ];
              exhibit_image = <div>
                <button type="button" onClick={() => this.setState({ isOpen: true })}>
                  <Icon icon="multi-select" /><span>Exhibit</span>
                </button>
                {isOpen && (
                  <Lightbox
                    mainSrc={images[photoIndex]}
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() =>
                      this.setState({
                        photoIndex: (photoIndex + images.length - 1) % images.length,
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                        photoIndex: (photoIndex + 1) % images.length,
                      })
                    }
                  />
                )}
              </div>
            }
            var questiondata;
            var cp1 = this.state.currentPage - 1;
            var new_question_title = $('.nav-list a#nav-button' + cp1).attr('hightlight1');
            // eslint-disable-next-line
            if (new_question_title == undefined) {
              questiondata = node_data.body[0].value;
            }
            else {
              questiondata = new_question_title;
            }
            return (
              <div key={node_data.nid[0].value} className="questions-list">
                <div className="question-icons">
                  {reference}
                  {exhibit_image}
                  {calci}
                </div>
                <div className="question-title" id={"question" + this.state.currentPage}>
                  <Editor
                    tag="div"
                    text={questiondata}
                    options={{toolbar: {buttons: ['underline']}}}
                  />
                </div>
                <div className="options-wrapper" id={"options-wrapper" + this.state.currentPage} flag="0" quesid={node_data.nid[0].value} >
                {question.map((items, index) => {
                  if (items['question_type'] === "MCQ Image" ) {
                    var question_val = "<img src=" + items.options + " height='100' width='100  ' className='img-responsive'/>";
                  }
                  else {
                    question_val = items.options;
                  }
                  var page = this.state.currentPage;
                  var cp = this.state.currentPage-1;
                  if ($('#options-wrapper' + page + ' li strike').length) {
                    var mvar = "";
                    $("strike span").each(function(index, value) {
                      // eslint-disable-next-line
                      var isLastElement = index == $(this).length -1;
                      if (isLastElement) {
                        mvar += $(this).attr('value');
                      }
                      else {
                        mvar += ',' + $(this).attr('value');
                      }
                      $('.navigation-wrapper .nav-list a#nav-button' + cp).attr('strikeout', mvar);
                    });
                    // eslint-disable-next-line
                    if (mvar.length == 0) {
                      $('.navigation-wrapper .nav-list a#nav-button' + cp).removeAttr('strikeout');
                    }
                  }

                  let findNum = index;
                  let num = String.fromCharCode(97 + findNum)
                  let cid = '';
                  if (node_data.field_question_chapter.length > 0) {
                    cid = node_data.field_question_chapter[0].target_id;
                  }
                  else {
                    cid = '';
                  }
                  return (
                    <div key={index}>
                      <span className="index">{num.toUpperCase()}</span>
                      <li question_no={this.state.currentPage} value={index+1} sel_ans="" key={ items.id } selected={index} id={ "correct" + items.correct } className={ items.id }>
                        <label id={index}>
                          <input className="options" cid={cid} name="answer" value={index} marks={node_data.field_question_marks[0].value} id={node_data.nid[0].value} type="radio" selected_value={ index + 1 } onChange={this.onBlur}/>
                          <input name="correct" type="hidden" value={ items.correct } onChange={this.onBlur}/>
                          <span value={index+1}>{ ReactHtmlParser(question_val) }</span>
                        </label>
                      </li>
                    </div>
                  );
                })}
              </div>
              </div>
            );
        });
      }
      else {
        return this.state.questions.slice((this.state.currentPage * this.state.maxItemsPerPage) - this.state.maxItemsPerPage, this.state.currentPage * this.state.maxItemsPerPage).map(({node_data, question}, item) => {
          let calci = '';
          let loader_btn = <ClipLoader
                size={20}
                color={"red"}
                loading={true}
              />;
          // eslint-disable-next-line
          if (node_data.field_question_allow_calci[0].value == 1) {
            calci = <Popup trigger={<div className="calci-btn"><button><Icon icon="calculator" /><span>Calculator</span></button><span className="calc-btn-loader">{loader_btn}</span></div>} position="left top">
              <div className="calculator" Style="width: 480px;border: 2px solid black;">
                <Iframe url="https://www.desmos.com/scientific"
                  width="480px"
                  height="450px"
                  id="calculator"
                  className="calculator-under"
                  position="relative"/>
              </div>
            </Popup>
          }
          let reference = '';
          let attachment_link = '';
          if (this.state.attachment_url.length > 0) {
            attachment_link = <li><a href={false} className="attachment_link" onClick={() => this.paragraphSwitch()}>Paragraph</a></li>
          }
          else {
            attachment_link = "";
          }
          if (node_data.field_question_attach_doc.length > 0) {
            var reference_no =  this.state.currentPage;
            var attached_docs = node_data.field_question_attach_doc;
            var link = "";
            $.each( attached_docs, function( i, val ) {
              var link_title = val.url.split("/");
              link_title = link_title.slice(-1)[0];
              link_title = link_title.replace(/([,%\d+])+/g, '');
              link += '<li><Icon icon="pdf" /><a class="fa fa-file-pdf-o" href=' + val.url + ' target="_blank"> ' + link_title + '</a></li>';
            });
            reference = <Popup className={"reference-popup-" + reference_no } trigger={<div><button type="button" className={"button reference-btn-" + reference_no}><Icon icon="multi-select" /><span>Reference</span></button></div>} position="bottom right">
               <div className="update-popup-html">
                {attachment_link}
                {ReactHtmlParser(link)}
                </div>
              </Popup>
            }
            let exhibit_image = '';
            if (node_data.field_question_exhibit_image.length !== 0) {
              const images = [
                node_data.field_question_exhibit_image[0].url
              ];
              exhibit_image = <div>
                <button type="button" onClick={() => this.setState({ isOpen: true })}>
                  <Icon icon="multi-select" /><span>Exhibit</span>
                </button>
                {isOpen && (
                  <Lightbox
                    mainSrc={images[photoIndex]}
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() =>
                      this.setState({
                        photoIndex: (photoIndex + images.length - 1) % images.length,
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                        photoIndex: (photoIndex + 1) % images.length,
                      })
                    }
                  />
                )}
              </div>
            }
            var questiondata;
            var cp1 = this.state.currentPage - 1;
            var new_question_title = $('.nav-list a#nav-button' + cp1).attr('hightlight1');
            // eslint-disable-next-line
            if (new_question_title == undefined) {
              questiondata = node_data.body[0].value;
            }
            else {
              questiondata = new_question_title;
            }
            return (
              <div key={node_data.nid[0].value} className="questions-list">
                <div className="question-icons">
                  {reference}
                  {exhibit_image}
                  {calci}
                </div>
                <div className="question-title" id={"question" + this.state.currentPage}>
                  <Editor
                    tag="div"
                    text={questiondata}
                    options={{toolbar: {buttons: ['underline']}}}
                  />
                </div>
                <div className="options-wrapper" id={"options-wrapper" + this.state.currentPage} flag="0" quesid={node_data.nid[0].value} >
                {question.map((items, index) => {
                  if (items['question_type'] === "MCQ Image" ) {
                    var question_val = "<img src=" + items.options + " height='100' width='100  ' className='img-responsive'/>";
                  }
                  else {
                    question_val = items.options;
                  }
                  var page = this.state.currentPage;
                  var cp = this.state.currentPage-1;
                  if ($('#options-wrapper' + page + ' li strike').length) {
                    var mvar = "";
                    $("strike span").each(function(index, value) {
                      // eslint-disable-next-line
                      var isLastElement = index == $(this).length -1;
                      if (isLastElement) {
                        mvar += $(this).attr('value');
                      }
                      else {
                        mvar += ',' + $(this).attr('value');
                      }
                      $('.navigation-wrapper .nav-list a#nav-button' + cp).attr('strikeout', mvar);
                    });
                    // eslint-disable-next-line
                    if (mvar.length == 0) {
                      $('.navigation-wrapper .nav-list a#nav-button' + cp).removeAttr('strikeout');
                    }
                  }

                  let findNum = index;
                  let num = String.fromCharCode(97 + findNum)
                  let cid = '';
                  if (node_data.field_question_chapter.length > 0) {
                    cid = node_data.field_question_chapter[0].target_id;
                  }
                  else {
                    cid = '';
                  }
                  return (
                    <div key={index}>
                      <span className="index">{num.toUpperCase()}</span>
                      <li question_no={this.state.currentPage} value={index+1} sel_ans="" key={ items.id } selected={index} id={ "correct" + items.correct } className={ items.id }>
                        <label id={index}>
                          <input className="options" cid={cid} name="answer" value={index} marks={node_data.field_question_marks[0].value} id={node_data.nid[0].value} type="radio" selected_value={ index + 1 } onChange={this.onBlur}/>
                          <input name="correct" type="hidden" value={ items.correct } onChange={this.onBlur}/>
                          <span value={index+1}>{ ReactHtmlParser(question_val) }</span>
                        </label>
                      </li>
                    </div>
                  );
                })}
              </div>
              </div>
            );
        });
      }
    }
    else {
      return (
        <div className="no-result">No Questions present</div>
      )
    }
  }

  simplePaging() {
    let feedback_popup = '';
    let sectionReview_popup = '';
    let loader_btn = <ClipLoader
          size={20}
          color={"red"}
          loading={true}
        />;

    if(this.state.questions.length > 0) {
      // eslint-disable-next-line
      return this.state.questions.slice((this.state.currentPage * this.state.maxItemsPerPage) - this.state.maxItemsPerPage, this.state.currentPage * this.state.maxItemsPerPage).map(({node_data, question}, item) => {
        if (node_data != null) {
          feedback_popup = <Popup open={this.state.open} closeOnDocumentClick onClose={this.exitModal}>
              <div className="modal">
                {/* eslint-disable-next-line */}
                <a className="close" onClick={() => {this.closeModal();this.exitModal()}}>
                  &times;
                </a>
                <div className="header"> Leave a Comment: </div>
                <div className="content" id ={"feedback_no" + this.state.currentPage}>
                  <div className="pt-input-group feedback-section">
                    <TextArea rows={10} cols={40} defaultValue={this.state.question_feedback} maxLength="300"  name="question_feedback" id={node_data.nid[0].value} onChange={this.handleChange}/>
                    <p className="remaining_chars">{this.state.chars_left}/{this.state.max_char}</p>
                  </div>
                  <div>
                    <div className="remove-comment-sec">
                      <button remove_ques_id={node_data.nid[0].value} onClick={() => {this.clearChange()}}>Remove Comment</button>
                    </div>
                    <div className="contrl-comment-sec">
                      <button onClick={() => {this.closeModal();this.exitModal()}}>Cancel</button>
                      <button onClick={() => {this.saveModal();this.exitModal()}}>Save</button>
                    </div>
                  </div>
                </div>
              </div>
          </Popup>

          // Section Review Popup.
          sectionReview_popup = <Popup open={this.state.sectionOpen} closeOnDocumentClick onClose={this.exitModal}>
              <div className="modal">
                {/* eslint-disable-next-line */}
                <a className="close" onClick={() => {this.exitModal()}}>
                  &times;
                </a>
                <div className="header"> Section Review </div>
                <div className="section-review-content" id ={"review_no" + this.state.currentPage}>
                  <h2> Filter by: </h2>
                  <div className="filter-criteria-section">
                    <div><input type="checkbox" id="unattempted" onClick={() => {this.filterModal()}}/>Unattempted</div>
                    <div><input type="checkbox" id="attempted" onClick={() => {this.filterModal()}}/>Attempted</div>
                    <div><input type="checkbox" id="flagged" onClick={() => {this.filterModal()}}/>Flagged</div>
                  </div>
                  <button className="clear-section-filter-criteria" onClick={() => {this.sectionClearModal()}}>Clear</button>
                  <div className="navigation">
                    <div className="navigation-wrapper section-review-nav-wrapper">
                      <ul>
                        {this.navigation_questions()}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
          </Popup>

          return (
            <div className="pagination-button-wrapper-parent" key={this.state.currentPage}>
              <div className="pagination-button-wrapper" key={this.state.currentPage} id={"page" + this.state.currentPage} >
                <button className="section-review-button" id="section-review-button" ques_id={node_data.nid[0].value} onClick={() => {this.openSectionModal()}}><i  className="fa fa-th"></i></button>
                { sectionReview_popup }
                <button className="feedback_button" id="feedback_button" ques_id={node_data.nid[0].value} onClick={() => {this.openModal();this.getDataForModal()}}><i className="fa fa-comments"></i></button>
                { feedback_popup }
                <button className="flag-question" id={"flag-question flag-" + this.state.currentPage} onClick={() => this.changePage('flag')}><i  className="fa fa-flag"></i></button>
                <button className="unflag-question" id={"unflag-question unflag-" + this.state.currentPage} onClick={() => this.changePage('unflag')}><i className="fa fa-flag"></i></button>
               {this.state.currentPage > 1 ?
                <button className="back-button" id="control-back-buttons" onClick={() => this.changePage('back')}><div className="back-arrow"></div>Back</button>
               : null}
                <button className="next-btn-submit submit-question next-button" id="control-next-buttons" onClick={() => {this.changePage('submit');this.changePageOnNav(this.state.currentPage)}}>Next<div className="next-arrow"><span className="next-btn-loader">{loader_btn}</span></div></button>
              </div>
            </div>
          );
        }
      });
    }
  }

  changePage(direction: string) {
    var correct_answer = $("#correct1").attr('value');
    var marks = $(".options-wrapper .options").attr('marks');
    var chapter_id = 0;
    if ($(".options-wrapper .options").attr('cid')) {
      chapter_id = $(".options-wrapper .options").attr('cid');
    }
    var user_marks;
    var iscorrect;
    // eslint-disable-next-line
    if (correct_answer === this.state.selected_answer) {
      iscorrect = 1;
    }
    else {
      iscorrect = 0;
    }
    const csrf_token = localStorage.getItem('userData.csrf_token');
    const auth = localStorage.getItem('userData.auth');
    // eslint-disable-next-line
    const flag = $("#options-wrapper" + this.state.currentPage).attr('flag');

    // Get current exam current time.
    var paused_time = $('.control-time')[0].innerText;
    paused_time = paused_time.split(':');
    var milisec = ((+paused_time[0]) * 60 * 60 + (+paused_time[1]) * 60 + (+paused_time[2])) * 1000;

    // eslint-disable-next-line
    if (this.state.selected_answer == 0) {
      this.setState({selected_answer: 0});
    }

    let data = {
      "question_id" : this.state.question_id,
      "chapter_id" : chapter_id,
      "correct_answer" : correct_answer,
      "selected_answer" : this.state.selected_answer,
      "marks": marks,
      "user_marks": user_marks,
      "iscorrect": iscorrect,
      "nid": this.state.nid,
      "flag": flag,
      "question_feedback": this.state.question_feedback,
      "remaining_exam_time": milisec,
      csrf_token,
      auth
    };

    // Call function on flagging the question.
    if (direction === 'flag') {
      var current_flag_page = this.state.currentPage;
      var cpf = this.state.currentPage-1;

      $("#options-wrapper" + current_flag_page).attr('flag', "1");
      $('.navigation-wrapper .nav-list a#nav-button' + cpf).attr('flag_sel', 1);
      $('.navigation-wrapper .nav-list a#nav-button' + cpf).empty();
      var flagInnerString = current_flag_page + '<i class="fa fa-flag"></i>';
      $('.navigation-wrapper .nav-list a#nav-button' + cpf).html(flagInnerString);

      var qid = $("#options-wrapper" + current_flag_page + ' input').attr('id');

      let newdata = {
        "question_id" : qid,
        "chapter_id" : chapter_id,
        "correct_answer" : correct_answer,
        "selected_answer" : 0,
        "marks": marks,
        "user_marks": user_marks,
        "iscorrect": iscorrect,
        "nid": this.state.nid,
        "flag": "1",
        "question_feedback": this.state.question_feedback,
        "remaining_exam_time": milisec,
        csrf_token,
        auth
      };

      if(this.state.nid) {
        UpdateFlagResult('api/result/update', newdata)
        .then((response) => {
          $('#flag-' + current_flag_page).attr('style', 'display: none !important;');
          $('#unflag-' + current_flag_page).attr('style', 'display: inline-block !important;');
        })
      }

      // eslint-disable-next-line
      if (this.state.questions.length != this.state.currentPage) {
        this.setState({
          currentPage: this.state.currentPage + 1,
        });
      }
    }

    // Call function on unflagging the question.
    if (direction === 'unflag') {
      // eslint-disable-next-line
      var current_flag_page = this.state.currentPage;
      // eslint-disable-next-line
      var cpf = this.state.currentPage-1;

      $('.navigation-wrapper .nav-list a#nav-button' + cpf).removeAttr('flag_sel');
      $("#options-wrapper" + current_flag_page).attr('flag', "0");
      $('.navigation-wrapper .nav-list a#nav-button' + cpf).empty();
      var unflagInnerString = current_flag_page + '<i class="no-fa-class"></i>';
      $('.navigation-wrapper .nav-list a#nav-button' + cpf).html(unflagInnerString);

      // eslint-disable-next-line
      var qid = $("#options-wrapper" + current_flag_page + ' input').attr('id');

      let newdata = {
        "question_id" : qid,
        "chapter_id" : chapter_id,
        "correct_answer" : correct_answer,
        "selected_answer" : this.state.selected_answer,
        "marks": marks,
        "user_marks": user_marks,
        "iscorrect": iscorrect,
        "nid": this.state.nid,
        "flag": "0",
        "question_feedback": this.state.question_feedback,
        "remaining_exam_time": milisec,
        csrf_token,
        auth
      };

      if(this.state.nid) {
        UpdateFlagResult('api/result/update', newdata)
        .then((response) => {
          $('#flag-' + current_flag_page).attr('style', 'display: inline-block !important;');
          $('#unflag-' + current_flag_page).attr('style', 'display: none !important;');
        })
      }

      // eslint-disable-next-line
      if (this.state.questions.length != this.state.currentPage) {
        this.setState({
          currentPage: this.state.currentPage + 1,
        });
      }
    }
    if (direction === 'back') {
       this.setState({
        currentPage: this.state.currentPage - 1
       });

       var cpf1_scroll = this.state.currentPage-1;
       var container = $('.questions-page .navigation-wrapper'),
           scrollTo = $('.questions-page .answer-container .navigation #nav-list' + cpf1_scroll);

       container.animate({
           scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop()
       });
    }
    if (direction === 'submit') {
      $('.exam-footer-wrapper').addClass('time-to-disable');
      var current_flag_page1 = this.state.currentPage;
      var cpf1 = this.state.currentPage-1;
      var page_length = this.state.questions.length;
      if ($('.navigation-wrapper .nav-list a#nav-button' + cpf1).attr('flag_sel')) {
        $("#options-wrapper" + current_flag_page1).attr('flag', "1");
        $('#flag-' + current_flag_page1).attr('style', 'display: none !important;');
        $('#unflag-' + current_flag_page1).attr('style', 'display: inline-block !important;');
      }
      else {
        $('.navigation-wrapper .nav-list a#nav-button' + cpf1).removeAttr('flag_sel')
        $("#options-wrapper" + current_flag_page1).attr('flag', "0");
        $('.navigation-wrapper .nav-list a#nav-button' + cpf1).html(current_flag_page1);
      }
      // eslint-disable-next-line
      if(this.state.nid && this.state.selected_answer != 0) {
        UpdateResult('api/result/update', data)
        .then((res) => {
          this.setState({ attempted: res.attempted_count });
          console.log("Result Updated!");
          $('.exam-footer-wrapper').removeClass("time-to-disable");
        })
      }

      if (page_length === current_flag_page1) {
        // if (this.state.currentPage === 10) {
        this.openSectionModal();
        // }
        $('.control-timer-buttons').attr('style', 'display: none !important;');
        $('.paragraphSpilt-left .spilt-paragraph-wrapper .spilt_paragraph').attr('style', 'display: none !important;');
      }
      if (page_length !== current_flag_page1) {
        this.setState({
          currentPage: this.state.currentPage + 1,
        });

        var feed = $('.navigation-wrapper .nav-list a#nav-button' + this.state.currentPage).attr('feedback_given');
        // eslint-disable-next-line
        if(feed == '') {
          $('.answer-container #feedback_no' + this.state.currentPage + ' textarea').value = '';
        }
      }

      this.setState({ question_feedback: '' })
      // eslint-disable-next-line
      var container = $('.questions-page .navigation-wrapper'),
      // eslint-disable-next-line
      scrollTo = $('.questions-page .answer-container .navigation #nav-list' + cpf1);
      // eslint-disable-next-line
      container.animate({
        // eslint-disable-next-line
        scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop()
      });
    }
    if (direction === 'end') {
      var result_response = this.state.nid;
      const csrf_token = localStorage.getItem('userData.csrf_token');
      const auth = localStorage.getItem('userData.auth');
      const uid = localStorage.getItem('userData.uid');

      var paused_q = "";

      let data = {
        "nid": this.state.nid,
        "pauseCount": 0,
        "pauseTime": 0,
        csrf_token,
        auth,
        paused_q
      };
      if(this.state.nid) {
        UpdatePauseTimeResult('node/' + this.state.nid , data)
        .then((response) => {
          console.log("Result Updated with required data on finish exam!");
        })
      }

      let userdata = {
        "uid": uid,
        "emptydata": "",
        "examid" : this.props.location.state.response,
        csrf_token,
        auth
      }

      confirmAlert({
        title: 'Confirm to finish the Exam',
        message: 'Are you sure to finish the Exam?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => this.dialog.show({
              title: 'Confirm to finish the Exam',
              body: 'Are you definitely sure to finish the Exam?',
              actions: [
                Dialog.Action(
                  'Yes',
                  () => {
                    this.updateTotalMarks();
                    this.sendMail();
                    this.props.history.push('/review', {
                      result_id: result_response,
                      examname: this.props.location.state.exam_name,
                      exam_id: this.props.location.state.response,
                      question: this.props.location.state.question,
                      ques_count: this.state.questions.length
                    });
                    RemovePaymentData('user/' + uid , userdata)
                    .then((response) => {
                      console.log("Remove Payment metdata on finish exam!");
                    });
                    var userName = localStorage.getItem('userData.name');
                    if (userName) {
                      if (!userName.includes('IQSOL')) {
                        BlockBackendUser('api/backend/user/update', userdata)
                        .then((response) => {
                          console.log("Backend user is Updated!");
                        })
                      }
                    }
                  },
                  'btn-yes'
                ),
                Dialog.Action(
                  'No',
                  () => console.log('No!'),
                  'btn-no'
                )
              ],
              bsSize: 'small',
              onHide: (dialog) => {
                Dialog.hide()
              }
            })
          },
          {
            label: 'No',
            onClick: () => ""
          }
        ]
      });
    }
  }

  paragraphSwitch() {
    $('.answer-container .answer ul').attr('class', 'paragraphSpilt-left');
    if (this.state.attachment_url.length > 0) {
      $('.answer-container .answer ul').append('<div class="spilt-paragraph-wrapper col-sm-5"><div class="close-para-wrapper"><a class="close-para">&times;</a></div><div class="spilt_paragraph"><iframe src="' + this.state.attachment_url[0].url + '" height="400" width="500"></iframe></div></div>');
    }

    $(".spilt-paragraph-wrapper .close-para").click(function(){
      $('.answer-container .answer ul').removeClass('paragraphSpilt-left');
      $('.answer-container .answer ul .spilt_paragraph').remove();
      $(".spilt-paragraph-wrapper .close-para").remove();
    });
  }

  // Update total marks in result after exam finished.
  updateTotalMarks() {
    fetch(process.env.REACT_APP_CBT + 'api/total_marks/' + this.state.nid + '?_format=json')
    .then(res => res.json()) // It resolves the promise with a JSON object
    .then(res => {
      const totalMarks = res.total_marks;
      const csrf_token = localStorage.getItem('userData.csrf_token');
      const auth = localStorage.getItem('userData.auth');

      let data = {
        "nid": this.state.nid,
        "totalMarks": totalMarks,
        csrf_token,
        auth
      };
      UpdateTotalMarksResult('node/' + this.state.nid , data)
      .then((response) => {
        console.log("Result Updated with total marks on finish exam!");
      })
    })
  }

  sendMail() {
    const userID = localStorage.getItem('userData.uid');
    const examid = this.props.location.state.response;
    if (!this.state.userInfo.mail) return null;
    const userMail  = this.state.userInfo.mail[0].value;
    const csrf_token = localStorage.getItem('userData.csrf_token');
    const auth = localStorage.getItem('userData.auth');

    fetch(process.env.REACT_APP_CBT + 'api/result/' + examid + '/' + this.state.nid + '/' + userID + '?_format=json')
    .then(res => res.json()) // It resolves the promise with a JSON object
    .then(res => {
      let result = {
        "result_id": this.state.nid,
        "uid": userID,
        "result_data": res[0],
        "email": userMail,
        "exam_id": examid,
        "questionCount": this.state.questions.length,
        csrf_token,
        auth
      };
      SendResultMail('api/result/email', result)
      .then((response) => {
        console.log("Email has been sent!");
      })
    })

    fetch(process.env.REACT_APP_CBT + 'node/' + this.state.nid + '?_format=json')
    .then(res => res.json()) // It resolves the promise with a JSON object
    .then(res => {
      let QuestionRes = {
        "questions_data": this.state.questions,
        "result_id": this.state.nid,
        "uid": userID,
        "email": userMail,
        "exam_name" : this.props.location.state.exam_name,
        "solved_questions_data" : (res.field_solved_question_and_answer.length > 0 ) ? res.field_solved_question_and_answer[0].value : '',
        csrf_token,
        auth
      };
      SendQuestionSetMail('api/questions/email', QuestionRes)
      .then((response) => {
        console.log("Questions Email has been sent!");
      })
    })
  }

  // Finish exam on time up.
  finishOnTimeUp() {
    var result_response = this.state.nid;
    const csrf_token = localStorage.getItem('userData.csrf_token');
    const auth = localStorage.getItem('userData.auth');
    const uid = localStorage.getItem('userData.uid');

    var paused_q = "";

    let data = {
      "nid": this.state.nid,
      "pauseCount": 0,
      "pauseTime": 0,
      csrf_token,
      auth,
      paused_q
    };
    if(this.state.nid) {
      UpdatePauseTimeResult('node/' + this.state.nid , data)
      .then((response) => {
        console.log("Result Updated with required data on finish exam!");
      })
    }

    let userdata = {
      "uid": uid,
      "emptydata": "",
      csrf_token,
      auth
    }
    this.updateTotalMarks();
    this.sendMail();
    this.props.history.push('/review', {
      result_id: result_response,
      examname: this.props.location.state.exam_name,
      exam_id: this.props.location.state.response,
      question: this.props.location.state.question,
      ques_count: this.state.questions.length
    });
    RemovePaymentData('user/' + uid , userdata)
    .then((response) => {
      console.log("Remove Payment metdata on finish exam!");
    });

  }

  // Check the internet connection and freeze the exam.
  // checkInternetConnection(){
  //   var status = navigator.onLine;
  //   if (status) {
  //     if (this.state.pauseTrigger !== 'undefined'  && this.state.pauseTrigger === false ) {
  //       $('.start-timer').trigger('click');
  //       $(".questions-page .exam-header-timer-wrapper .exam-header-timer-inner-wrapper .exam-end button").removeAttr("disabled");
  //       $(".pagination-button-wrapper-parent button").removeAttr("disabled");
  //       $(".control-timer-buttons #control-pause-buttons").removeAttr("disabled");
  //       $(".questions-page .exam-header-timer-wrapper .exam-header-timer-inner-wrapper .exam-end button").removeClass("disabled-btn");
  //       $(".pagination-button-wrapper-parent button").removeClass("disabled-btn");
  //       $(".control-timer-buttons #control-pause-buttons").removeClass("disabled-btn");
  //       $(".control-timer-buttons #control-resume-buttons").removeClass("disabled-btn");

  //       $(".questions-page .navigation-wrapper #listlink a").removeClass("disable-click");
  //       $(".questions-page .answer-container .answer ul li").removeClass("disable-click");
  //       $(".questions-page #nextbtn").removeClass("disable-click");
  //     }
  //   }
  //   else {
  //     $('.stop-timer').trigger('click');
  //     $(".questions-page .exam-header-timer-wrapper .exam-header-timer-inner-wrapper .exam-end button").attr("disabled", true);
  //     $(".pagination-button-wrapper-parent button").attr("disabled", true);
  //     $(".control-timer-buttons #control-pause-buttons").attr("disabled", true);
  //     $(".questions-page .exam-header-timer-wrapper .exam-header-timer-inner-wrapper .exam-end button").addClass("disabled-btn");
  //     $(".pagination-button-wrapper-parent button").addClass("disabled-btn");
  //     $(".control-timer-buttons #control-pause-buttons").addClass("disabled-btn");
  //     $(".control-timer-buttons #control-resume-buttons").addClass("disabled-btn");

  //     $(".questions-page .navigation-wrapper #listlink a").addClass("disable-click");
  //     $(".questions-page .answer-container .answer ul li").addClass("disable-click");
  //     $(".questions-page #nextbtn").addClass("disable-click");
  //   }
  // }

  render() {
    // $('.submit-btn-loader').show();
    window.onbeforeunload = function () {return false;}

    let username = '';
    let prefix = '';
    if (this.state.userInfo.length > 0 && this.state.userInfo.field_user_prefix.length > 0) {
      prefix  = this.state.userInfo.field_user_prefix[0].value;
      prefix = prefix.charAt(0).toUpperCase() + prefix.slice(1);
    }
    if (this.state.userInfo.length > 0 && this.state.userInfo.field_user_self_name.length > 0) {
      username  = prefix + '. ' + this.state.userInfo.field_user_self_name[0].value;
    }
    else {
      if (!this.state.userInfo.field_first_name) return null;
      const fname  = this.state.userInfo.field_first_name[0].value;
      if (!this.state.userInfo.field_last_name) return null;
      const lname  = this.state.userInfo.field_last_name[0].value;
      if (prefix) {
        username = prefix + '. ' + fname + ' ' + lname;
      }
      else {
        username = fname + ' ' + lname;
      }
    }

    const examname = this.props.location.state.exam_name;
    const progress = Math.trunc(this.state.percentage);
    // eslint-disable-next-line
    var wrapper = $('.question-title').text();
    var page = this.state.currentPage;
    var cp = this.state.currentPage-1;

    $(document).ready(function() {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);

      // We listen to the resize event
      window.addEventListener('resize', () => {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      });

      var scroll_height = $('.navigation-wrapper').outerHeight();
      var nav_height = $('#listlink').outerHeight();
      if (nav_height > scroll_height) {
        $('#nextbtn').show();
      }
      else {
        $('#prevbtn').hide();
        $('#nextbtn').hide();
      }
      $(".navigation-wrapper").scroll(function() {
        var div = $(this);
        // eslint-disable-next-line
        if (div[0].scrollHeight - div.scrollTop() == div.height()) {
          $('#nextbtn').hide();
          $('#nextbtn').addClass('importantRule');
          $('#prevbtn').show();
        }
        // eslint-disable-next-line
        else if(div.scrollTop() == 0)
        {
          $('#prevbtn').hide();
          $('#nextbtn').show();
          $('#nextbtn').removeClass('importantRule');
        }
      });

      $('[data-toggle="tooltip"]').tooltip().addClass('tooltip');

      $('#options-wrapper' + page + ' li').on('click', function(){
        $('#options-wrapper' + page + ' li').removeClass('option-selected');
        $(this).addClass('option-selected');
      });

      $(".spilt-paragraph-wrapper .close-para").click(function(){
        $('.answer-container .answer ul').removeClass('paragraphSpilt-left');
        $('.answer-container .answer ul .spilt_paragraph').remove();
        $(".spilt-paragraph-wrapper .close-para").remove();
      });

      // Highlight functionality.
      $(".question-title#question" + page + ' u').addClass('highlight1');
      if ($(".question-title#question" + page + ' span').hasClass('highlight1')) {
        $(".question-title#question" + page + ' span u').contents().unwrap();
      }
      $(".medium-editor-action u").html('<i class="fa fa-pencil" aria-hidden="true"></i>');
      if ($(".question-title#question" + page + ' u').hasClass('highlight1')) {
        var question_html = $(".question-title#question" + page + ' .medium-editor-element').html();
        $('.navigation-wrapper .nav-list a#nav-button' + cp).attr('hightlight1', question_html);
      }
      $('.medium-editor-element').on('mouseup', function(){
        var length = document.getSelection().toString().length;
        if (length > 1) {
          $('.medium-editor-element').attr('contenteditable', "true");
        }
        else {
          $('.medium-editor-element').attr('contenteditable', "inherit");
        }
      });

      // eslint-disable-next-line
      if ($('.nav-list a#nav-button' + cp).attr('strikeout')) {
        var question_strikeout = $('.nav-list a#nav-button' + cp).attr('strikeout');
        // eslint-disable-next-line
        if (question_strikeout.length == 1) {
          $('#options-wrapper' + page + ' span[value=' + question_strikeout + ']').addClass('strike1');
        }
        else {
          var sout = question_strikeout;
          sout = sout.split(',');
          $(sout).each(function(index, value) {
            $('#options-wrapper' + page + ' span[value=' + this + ']').addClass('strike1');
          });
        }
      }

      if ($('.question-title#question' + page + ' img')) {
        var img_src = $('.question-title#question' + page + ' img').attr('src');
        // eslint-disable-next-line
        if (typeof(img_src) !== 'undefined' ) {
          var urlCheck = img_src.includes('http');
          // eslint-disable-next-line
          if (urlCheck == false) {
            var img_url = process.env.REACT_APP_CBT + img_src;
            $('.question-title#question' + page + ' img').attr('src', img_url);
          }
        }
      }

      $('.questions-page .navigation .nav-list').each(function () {
        if ($('i', this).hasClass('fa-flag')) {
          $('a', this).attr('flag_sel', 1);
        }
        if ($('i', this).hasClass('no-fa-class')) {
          $('a', this).removeAttr('flag_sel');
        }
        // eslint-disable-next-line
        if ($('a', this).attr('flag_sel') != 1) {
          $('a i', this).removeAttr('class');
        }

        if ($(this).hasClass('submitted-quest')) {
          var sub_ques_id = $(this).attr('nav_quesid');
          $('.section-review-nav-wrapper li[nav_quesid="' + sub_ques_id + '"]').addClass('submitted-quest');
        }
      });

      $('.next-btn-submit').on('click', function(){
        $('#nav-list' + cp).addClass('submitted-quest');
      });

      $('.calci-btn').on('click', function() {
        $('.calc-btn-loader').show();
        setTimeout(function(){ $('.calc-btn-loader').hide(); }, 2000);
      });
      $('.submit-btn-loader').hide();
    });

    return (
      <div className="questions-page">
        <div>
            <div className="exam-header-timer-wrapper">
              <div className="exam-header-timer-inner-wrapper">
                <div className="site-logo">
                  <a href="/"><img src={exam_logo} className="logo" alt="logo" /></a>
                </div>
                <div className="exam-header-inner-wrapper">
                  <div className="exam-start">
                    <p>Question : {this.state.currentPage} </p>
                  </div>
                  <div className="exam-middle">
                    <Icon icon="time" />
                    <div className="exam-time-remaining">
                      <p>Exam Time Remaining</p>
                      {this.controlTime()}
                    </div>
                  </div>
                  <div className="progressbar">
                    <CircularProgressbar value={this.state.percentage} text={`${progress}%`} />
                    <p className="progress-title">Progress</p>
                  </div>
                  <div className="attempted-wrapper">
                    <p><span className="attempted">{this.state.attempted}</span> / <span className="total-questions">{this.state.questions.length}</span></p>
                    <p className="attempted-title">Items Attempted</p>
                  </div>
                </div>
                <div className="exam-end">
                  <button id="finish-button" submit_id={this.state.nid} ques_length={this.state.questions.length} exam_nm={examname} onClick={() => this.changePage('end')}>Finish Exam</button>
                </div>
              </div>
            </div>
            <div className="exam-header-wrapper">
              <div className="exam-header-inner-wrapper">
                <div className="exam-title"><span>Exam : </span>{examname}</div>
                <div className="candidate-name"><span>Candidate : </span>{username}</div>
              </div>
            </div>
            <div className="answer-container">
              <div className="row">
                <div className="navigation col-sm-3">
                  <button id="prevbtn" onClick={() => this.prevbtn()}><i className="fa fa-chevron-up" aria-hidden="true"></i></button>
                  <div className="navigation-wrapper">
                    <ul id="listlink">
                      {this.navigation_questions()}
                    </ul>
                  </div>
                  <button id="nextbtn" onClick={() => this.nextbtn()}><i className="fa fa-chevron-down" aria-hidden="true"></i></button>
                </div>
                <div className="answer col-sm-7">
                  <ul>
                    {this.questions()}
                    <Dialog ref={(el) => { this.dialog = el }} />
                  </ul>
                </div>
              </div>
            </div>
            <div className="exam-footer-wrapper">
              <div className="exam-footer-inner-wrapper">
                {this.simplePaging()}
              </div>
            </div>
        </div>
      </div>
    );
  }
}

export default Questions;
