import React, {Component} from 'react';
import '../../css/Result.css';
import ReactProgressLineBar from 'react-progress-line-bar';
import ReactHtmlParser from 'react-html-parser'
import $ from "jquery";

class Result extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result:[],
    }
  }

  componentDidMount() {
    const examid = this.props.location.state.examid;
    const resultid = this.props.location.state.resultid;
    const userID = localStorage.getItem('userData.uid');

    if (examid && resultid && userID) {
      fetch(process.env.REACT_APP_CBT + 'api/result/' + examid + '/' + resultid + '/' + userID + '?_format=json')
      .then(res => res.json()) // It resolves the promise with a JSON object
      .then(res => {
        this.setState({ result : res });
      })
    }
  }

  chapterPercentage() {
    if (this.state.result.length > 0) {
      // eslint-disable-next-line
      return this.state.result.map((item, index) => {
        if (item.chapter_result) {
          return item.chapter_result.map((value, key) => {
            const sr = key + 1;
            return (
              <tr key={ sr }>
                <td className="index">{(sr < 10 ? '0' : '') + sr}.</td>
                <td className="label"><label>{value.title}</label></td>
                <td className="percentage">
                  <ReactProgressLineBar value={value.percentage}/>
                  <span className="zero">0%</span>
                  <span className="hundred">100%</span>
                </td>
              </tr>
            );
          });
        }
      });
    }
  }

  render() {
    $(document).ready(function() {
      $('.submit-btn-loader').hide();
    });
    if (!this.state.result[0]) return null;
    const html = this.state.result[0].result_config_data;

    return (
      <div className="result user-result">
        <div className="result-candidate-details">
          {ReactHtmlParser(html)}
        </div>
        <div className="chapter_percentage">
          <table>
            <tbody>
              {this.chapterPercentage()}
            </tbody>
          </table>
        </div>
        <div className="result_note">Note: Above result is computer generated hence no need of signature.</div>
      </div>
    );
  }
}

export default Result;
