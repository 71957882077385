import React, {Component} from 'react';
import '../../css/Result.css';
import ReactHtmlParser from 'react-html-parser';
import $ from "jquery";
import exam_logo from '../../logo_exam.png';
import congrats_logo from '../../images/congrats_flowers.png';

class Result_summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result:[],
      isLoading:true,
      userInfo: '',
      data_questions: [],
      maxItemsPerPage: 1,
      currentPage: 1,
      result_id: '',
      given_ans_data: [],
      showExamResult: false,
      exam_total_marks: '',
    }
  }

  componentDidMount() {
    const examid = this.props.location.state.exam_id;
    this.setState({result_id : this.props.location.state.result_id});
    const resultid = this.props.location.state.result_id;
    const userID = localStorage.getItem('userData.uid');

    if (examid && resultid && userID) {
      fetch(process.env.REACT_APP_CBT + 'api/result/' + examid + '/' + resultid + '/' + userID + '?_format=json')
      .then(res => res.json()) // It resolves the promise with a JSON object
      .then(res => {
        this.setState({
          result : res,
          isLoading : false,
        });
      })
    }

    // Fetch logged in user info.
    if(userID && examid) {
      fetch(process.env.REACT_APP_CBT + '/user/' + userID + '?_format=json')
      .then(data => data.json()) // It resolves the promise with a JSON object
      .then(data => {
        this.setState({ userInfo : data });

        // eslint-disable-next-line
        if (data.field_backend_user.length > 0 && data.field_backend_user[0]['value'] == true) {
          // eslint-disable-next-line
          return data.field_exam_details.map((item) => {
            if (item.target_id) {
              fetch(process.env.REACT_APP_CBT + '/entity/paragraph/' + item.target_id + '?_format=json')
              .then(para => para.json()) // It resolves the promise with a JSON object
              .then(para => {
                // eslint-disable-next-line
                // if (para.field_cbt_exam && para.field_cbt_exam[0]['target_id'] == examid) {
                  let show_result = para.field_exam_show_result[0]['value'];
                  this.setState({ showExamResult : show_result });
                // }
              })
            }
          })
        }
      })
    }

    if (resultid && userID) {
      fetch(process.env.REACT_APP_CBT + '/api/solved-questions/' + resultid + '/' + userID + '/0?_format=json')
      .then(data => data.json()) // It resolves the promise with a JSON object
      .then(data => {
        this.setState({ data_questions : data });
      })
    }
    // For all questions.
    // var my_object = JSON.parse(decodeURIComponent(this.props.location.state.question));
    // this.setState({data_questions: my_object});
  }

  componentDidUpdate(prevProps, prevState) {
    let value = '';
    let sub_value = '';
    if (this.state.result_id) {
      if(prevState.result_id !== this.state.result_id) {
        fetch(process.env.REACT_APP_CBT + 'node/' + this.state.result_id + '?_format=json')
        .then(res => res.json()) // It resolves the promise with a JSON object
        .then(res => {
          this.setState({ given_ans_data: res.field_solved_question_and_answer })
        })

        fetch(process.env.REACT_APP_CBT + 'node/' + this.props.location.state.exam_id + '?_format=json')
        .then(exres => exres.json()) // It resolves the promise with a JSON object
        .then(exres => {
          this.setState({ exam_total_marks: exres.field_exam_marks[0].value })
        })
      }

      if (this.state.given_ans_data.length > 0) {
        return this.state.given_ans_data.map((qitem) => {
          value = qitem.value.split("\n");
          // eslint-disable-next-line
          return value.map((val, index) => {
            if (val) {
              sub_value = val.split("|");
              if (sub_value) {
                let questionNum = $('.questions-list .options-wrapper').attr('quesid');
                // eslint-disable-next-line
                if (questionNum && sub_value[0] == questionNum) {
                  $('.questions-list .options-wrapper input[type=radio][cid=' + sub_value[1] + '][id=' + sub_value[0] + '][selected_value=' + sub_value[2] +']').attr('checked', true);

                  let dataCheck = $('.result-summary-page .options-wrapper input[checked]').parent('label').parent('li').attr('id');
                  // eslint-disable-next-line
                  if (dataCheck == 'correct0') {
                    $('.result-summary-page .options-wrapper input[checked]').parent('label').parent('li').attr('style', 'color: #ff0000 !important;');
                  }
                  // eslint-disable-next-line
                  else if (dataCheck == 'correct1') {
                    $('.result-summary-page .options-wrapper input[checked]').parent('label').parent('li').attr('style', 'color: #008000 !important;');
                  }
                }
              }
            }
            $('.result-summary-page .options-wrapper #correct1').attr('style', 'color: #008000 !important;');
            let correctData = $('.result-summary-page .options-wrapper li[id="correct1"]').attr('value');
            let findNum = parseInt(correctData) - 1;
            let num = String.fromCharCode(97 + findNum);
            $('.correct-answer-wrapper span.correct-ans').html(num.toUpperCase());
            $('.result-summary-page .options-wrapper input.options').attr('disabled', true);
          });
        });
      }
      $('.result-summary-page .options-wrapper #correct1').attr('style', 'color: #008000 !important;');
      let correctData = $('.result-summary-page .options-wrapper li[id="correct1"]').attr('value');
      let findNum = parseInt(correctData) - 1;
      let num = String.fromCharCode(97 + findNum);
      $('.correct-answer-wrapper span.correct-ans').html(num.toUpperCase());
    }
  }

  backUserSummary() {
    let fullname = '';
    let prefix = '';
    if (this.state.userInfo.field_user_prefix.length > 0) {
      prefix  = this.state.userInfo.field_user_prefix[0].value;
      prefix = prefix.charAt(0).toUpperCase() + prefix.slice(1);
    }
    if (this.state.userInfo.field_user_self_name.length > 0) {
      fullname  = prefix + '. ' + this.state.userInfo.field_user_self_name[0].value;
    }
    else {
      if (!this.state.userInfo.field_first_name) return null;
      const fname  = this.state.userInfo.field_first_name[0].value;
      if (!this.state.userInfo.field_last_name) return null;
      const lname  = this.state.userInfo.field_last_name[0].value;
      fullname = prefix + '. ' + fname + ' ' + lname;
    }

    const examname = this.props.location.state.examname;
    let userIDNum = localStorage.getItem('userData.name');

    if(this.state.result.length > 0) {
      return this.state.result.map((item, index) => {
        // var grade = '';
        let pass_msg = '';
        let averagePer = parseFloat(item.average);
        averagePer = averagePer.toFixed(2);
        if (parseFloat(averagePer) >= parseFloat(item.exam_passing_criteria)) {
          // grade = "Pass";
          pass_msg = "<div class='exam-status'><img src='" + congrats_logo + "' className='congrats-logo' alt='logo' /><h2 class='summary-subtitle' style='color: #005800;font-family: Stardos Stencil;'>CONGRATULATIONS!!</h2></div>";
        }
        else {
          // grade ="Fail";
          pass_msg = "<div class='exam-status'><h2 class='summary-subtitle' style='color: #ff0000;font-family: Stardos Stencil;'>BETTER LUCK NEXT TIME!!</h2></div>";
        }
        return (
          <div className="result-display-wrapper backend-candidate-summary" key= {index}>
            <div className="site-logo">
              <a href="/"><img src={exam_logo} className="logo" alt="logo" /></a>
            </div>
            <h1 className="summary-title">Result of Examination</h1>
            <div className="result-upper-wrapper">
              <div className="result-left-wrapper">
                <div className="candidate-name"><strong>Candidate : </strong>{fullname}</div>
                <div className="exam-title"><strong>Subject : </strong>{examname}</div>
              </div>
              <div className="result-right-wrapper">
                <div className="exam-given-date"><strong>Test Date : </strong>{item.exam_given_date}</div>
                <div className="candidate-idnumber"><strong>ID Number : </strong>{userIDNum}</div>
              </div>
            </div>
            {ReactHtmlParser(pass_msg)}
            <div className="pagination-online-wrapper" key={this.state.currentPage} id={"page" + this.state.currentPage} >
              <button id="control-buttons" className="go-home exam-btn btn btn-primary button"onClick={() => this.changePage('home')}>Go Home</button>
            </div>
          </div>
        );
      });
    }
  }

  frontUserSummary() {
    let fullname = '';
    let prefix = '';
    if (this.state.userInfo.field_user_prefix.length > 0) {
      prefix  = this.state.userInfo.field_user_prefix[0].value;
      prefix = prefix.charAt(0).toUpperCase() + prefix.slice(1);
    }
    if (this.state.userInfo.field_user_self_name.length > 0) {
      fullname  = prefix + '. ' + this.state.userInfo.field_user_self_name[0].value;
    }
    else {
      if (!this.state.userInfo.field_first_name) return null;
      const fname  = this.state.userInfo.field_first_name[0].value;
      if (!this.state.userInfo.field_last_name) return null;
      const lname  = this.state.userInfo.field_last_name[0].value;
      fullname = prefix + '. ' + fname + ' ' + lname;
    }

    const examname = this.props.location.state.examname;
    let detailed = '';
    if(this.state.result.length > 0) {
      // eslint-disable-next-line
      return this.state.result.map((item, index) => {
        // eslint-disable-next-line
        // var grade = '';
        let pass_msg = '';
        // eslint-disable-next-line
        var averagePer = 0;
        var totalMarks = item.total_marks;
        var correctCount = item.correctCount;

        averagePer = parseFloat(item.average);
        averagePer = averagePer.toFixed(2);
        if (parseFloat(averagePer) >= parseFloat(item.exam_passing_criteria)) {
          // grade = "Pass";
          pass_msg = "<div class='exam-status'><img src='" + congrats_logo + "' className='congrats-logo' alt='logo' /><h2 class='summary-subtitle' style='color: #005800;font-family: Stardos Stencil;'>CONGRATULATIONS!!</h2></div>";
        }
        else {
          // grade ="Fail";
          pass_msg = "<div class='exam-status'><h2 class='summary-subtitle' style='color: #ff0000;font-family: Stardos Stencil;'>BETTER LUCK NEXT TIME!!</h2></div>";
        }
        if(this.state.data_questions.length > 0) {
          return this.state.data_questions.slice((this.state.currentPage * this.state.maxItemsPerPage) - this.state.maxItemsPerPage, this.state.currentPage * this.state.maxItemsPerPage).map(({node_data, question}, item) => {
            if (node_data.field_failed_answer_description.length > 0) {
              detailed = <div key={index}><span className="detailed-result-bold">Justification for correct answer:</span><br/>
                {ReactHtmlParser(node_data.field_failed_answer_description[0].value)}
                </div>
            }

            return (
              <div key={index} className="result-wrapper">
                <div className="frontend-result-display-wrapper">
                  <div className="site-logo">
                    <a href="/"><img src={exam_logo} className="logo" alt="logo" /></a>
                  </div>
                  <div className="result-upper-wrapper">
                    <table className="result-wrapper">
                      <tbody>
                        <tr>
                          <td><div className="candidate-name"><strong>Candidate : </strong>{fullname}</div></td>
                          <td><div className="exam-given-date"><strong>Total Questions : </strong>{correctCount} / {this.props.location.state.ques_count}</div></td>
                        </tr>
                        <tr>
                          <td><div className="exam-title"><strong>Subject : </strong>{examname}</div></td>
                          <td><div className="candidate-avrge-total"><strong>Total Marks : </strong>{totalMarks}</div></td>
                        </tr>
                        <tr>
                          <td></td>
                          <td><div className="candidate-percentage"><strong>Total Percentage : </strong>{parseFloat(averagePer)} %</div></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <hr/>
                <div className="summary-header">
                  <p className="summary-title">Thank you, <strong>{fullname}</strong> Your exam has ended successfully.</p>
                  {ReactHtmlParser(pass_msg)}
                </div>
                <h3> Detailed Explanation Summary :</h3>
                <div key={node_data.nid[0].value} className="questions-list">
                  <div className="question-title" id={"question" + this.state.currentPage}>
                    <strong>{ReactHtmlParser(node_data.body[0].value)}</strong>
                  </div>
                  <div className="options-wrapper" id={"options-wrapper" + this.state.currentPage} flag="0" quesid={node_data.nid} >
                    {question.map((items, index) => {
                      if (items['question_type'] === "MCQ Image" ) {
                        var question_val = "<img src=" + items.options + " height='200' width='200  ' class='img-responsive'/>";
                      }
                      else {
                        question_val = items.options;
                      }

                      let findNum = index;
                      let num = String.fromCharCode(97 + findNum)
                      let cid;
                      if (node_data.field_question_chapter.length > 0) {
                        cid = node_data.field_question_chapter[0].target_id;
                      }
                      else {
                        cid = 0;
                      }
                      return (
                        <div key={index}>
                          <li question_no={this.state.currentPage} value={index+1} sel_ans="" key={ items.id } selected={index} id={ "correct" + items.correct } className={ items.id }>
                            <span className="index">{num.toUpperCase()}</span>
                            <label id={index}>
                              <input className="options" cid={cid} name="answer" value={index} marks={node_data.field_question_marks[0].value} id={node_data.nid} type="radio" selected_value={ index + 1 } onChange={this.onBlur}/>
                              <input name="correct" type="hidden" value={ items.correct } onChange={this.onBlur}/>
                              <span value={index+1}>{ ReactHtmlParser(question_val) }</span>
                            </label>
                          </li>
                        </div>
                      );
                    })}
                  </div>
                  <div className="correct-answer-wrapper" id={"question" + this.state.currentPage}>
                    <strong>Answer "<span className="correct-ans"></span>" is correct.</strong><br/><br/>
                    {detailed}
                  </div>
                </div>
              </div>
            );
          });
        }
        else {
          return (
            <div key={index} className="result-wrapper">
              <div className="frontend-result-display-wrapper">
                <div className="site-logo">
                  <a href="/"><img src={exam_logo} className="logo" alt="logo" /></a>
                </div>
                <div className="result-upper-wrapper">
                  <table className="result-wrapper">
                    <tr>
                      <td><div className="candidate-name"><strong>Candidate : </strong>{fullname}</div></td>
                      <td><div className="exam-given-date"><strong>Total Questions : </strong>{correctCount} / {this.props.location.state.ques_count}</div></td>
                    </tr>
                    <tr>
                      <td><div className="exam-title"><strong>Subject : </strong>{examname}</div></td>
                      <div className="candidate-avrge-total"><strong>Total Marks : </strong>{totalMarks}</div>
                    </tr>
                    <tr>
                      <td></td>
                      <td><div className="candidate-percentage"><strong>Total Percentage : </strong>{parseFloat(averagePer)} %</div></td>
                    </tr>
                  </table>
                </div>
              </div>
              <hr/>
              <div className="summary-header">
                <p className="summary-title">Thank you, <strong>{fullname}</strong> Your exam has ended successfully.</p>
                {ReactHtmlParser(pass_msg)}
              </div>
            </div>
          );
        }
      });
    }
  }

  simplePaging() {
    if(this.state.data_questions.length > 0) {
      // eslint-disable-next-line
      return this.state.data_questions.slice((this.state.currentPage * this.state.maxItemsPerPage) - this.state.maxItemsPerPage, this.state.currentPage * this.state.maxItemsPerPage).map(({node_data, question}, item) => {
        if (node_data != null) {
          return (
            <div className="pagination-button-wrapper" key={this.state.currentPage} id={"page" + this.state.currentPage} >
              {this.state.currentPage > 1 ?
              <button id="control-back-buttons" className="prev-question exam-btn btn btn-primary button"onClick={() => this.changePage('back')}>Previous</button>
              : null}
              {this.state.currentPage < this.state.data_questions.length ?
              <button id="control-next-buttons" className="next-question exam-btn btn btn-primary button" onClick={() => {this.changePage('next')}}>Next</button>
              : null}
              {/* eslint-disable-next-line */}
              {this.state.currentPage == this.state.data_questions.length ?
              <button id="control-buttons" className="go-home exam-btn btn btn-primary button"onClick={() => this.changePage('home')}>Go Home</button>
             : null}
            </div>
          );
        }
      });
    }
  }

  changePage(direction: string) {
    if (direction === 'back') {
      this.setState({
        currentPage: this.state.currentPage - 1
      });
    }
    if (direction === 'next') {
      this.setState({
        currentPage: this.state.currentPage + 1
      });
    }
    if (direction === 'home') {
      window.location = '/';
    }
  }

  render() {
    $('.submit-btn-loader').show();
    let frontUserSummary = '';
    let backUserSummary = '';
    let paging = '';
    let page = this.state.currentPage;
    if (!this.state.userInfo.field_backend_user) return null;
    const userCheck  = this.state.userInfo.field_backend_user[0].value;
    // eslint-disable-next-line
    if (userCheck == true && this.state.showExamResult == false) {
      backUserSummary = this.backUserSummary();
    }
    // eslint-disable-next-line
    else {
      frontUserSummary = this.frontUserSummary();
      paging = this.simplePaging();
    }

    $(document).ready(function() {
      if ($('.question-title#question' + page + ' img')) {
        var img_src = $('.question-title#question' + page + ' img').attr('src');
        // eslint-disable-next-line
        if (typeof(img_src) !== 'undefined' ) {
          var urlCheck = img_src.includes('http');
          // eslint-disable-next-line
          if (urlCheck == false) {
            var img_url = process.env.REACT_APP_CBT + img_src;
            $('.question-title#question' + page + ' img').attr('src', img_url);
          }
        }
      }
      $('.submit-btn-loader').hide();
    });

    return (
      <div className="result-summary-wrapper">
        <div className="result-summary-page">
          {backUserSummary}
          <div className="solved-exams col-sm-7">
            <ul>
              {frontUserSummary}
            </ul>
            {paging}
          </div>
        </div>
      </div>
    );
  }
}

export default Result_summary;
