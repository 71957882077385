import React, {Component} from 'react';
import { TransverseLoading } from 'react-loadingg';
import ReactTooltip from "react-tooltip";
import {
  Icon
} from "@blueprintjs/core";
import Pagination from "react-js-pagination";
import '../../css/Result.css';
import $ from "jquery";

class Result_list extends Component {
  constructor(props) {
    super(props);
    this.state = {
      available_result:[],
      availableResultsPerPage: [],
      isLoading:true,
      activePage: 1,
      totalItemsCount: 0,
      itemsCountPerPage: 10,
      pageRangeDisplayed: 5,
      skipCountPerPage: 0,
    }
  }

  componentDidMount() {
    const userID = localStorage.getItem('userData.uid');
    if(userID) {
      fetch(process.env.REACT_APP_CBT + 'api/result/' + userID + '?_format=json')
      .then(res => res.json()) // It resolves the promise with a JSON object
      .then(res => {
        if (res.length > 0) {
          var availableResultsPerPage = res.slice(this.state.skipCountPerPage, this.state.itemsCountPerPage);
        }

        this.setState({
          available_result : res,
          isLoading : false,
          totalItemsCount : res.length,
          availableResultsPerPage : availableResultsPerPage
         });
      })
    }
  }

  redirectToTarget = (e) =>  {
    window.location = '/result';
    this.props.history.push('/result', {
      examid: e.currentTarget.attributes['examid'].value,
      resultid: e.currentTarget.attributes['resultid'].value,
    });
  }

  // Handle page changes.
  handlePageChange(pageNumber) {
    var skipPagesExamTypes = (pageNumber - 1) * this.state.itemsCountPerPage;
    var skipCountPerPage = this.state.skipCountPerPage + skipPagesExamTypes;
    var showNextCount = this.state.itemsCountPerPage * pageNumber;
    var availableResults = this.state.available_result;
    this.setState({
      activePage: pageNumber,
      availableResultsPerPage : availableResults.slice(skipCountPerPage, showNextCount)
    });
  }

  resultList() {
    let userName = localStorage.getItem('userData.name');
    if (userName === null) {
      return (
        <tr>
          <td colSpan="4"><div className="no_result_message">Please <a href="/login">Login</a> for checking all results.</div></td>
        </tr>
      );
    }
    // eslint-disable-next-line
    else if (this.state.isLoading == true) {
      return (
        <tr>
          <td colSpan="4"><div className="result_loder"><TransverseLoading color="#F92424" /></div></td>
        </tr>
      );
    }
    else if (this.state.available_result.length > 0) {
      return this.state.availableResultsPerPage.map((item) => {
        if (item.total_marks != null) {
          return (
            <tr key={ item.id } className="available_result_list" id={item.id}>
              <td>{ item.index }</td>
              <td>{ item.exam }</td>
              <td>{ item.exam_date }</td>
              <td className="action">
                <label>
                  <button examid={item.examid} resultid={item.id} className="view_result" onClick={this.redirectToTarget}>View Result</button>
                </label>
              </td>
            </tr>
          );
        }
        else {
          return (
            <tr key={ item.id } className="available_result_list" id={item.id}>
              <td>{ item.index }</td>
              <td>{ item.exam }</td>
              <td>{ item.exam_date }</td>
              <td className="action">
                <p data-tip="Result is not generated. Please contact to administrator."><Icon color="red" icon="ban-circle" /></p>
                <ReactTooltip place="top" type="warning" effect="float" textColor="#000"/>
              </td>
            </tr>
          );
        }
      });
    }
    else {
      return (
        <tr>
          <td colspan="4"><div className="no_result_message"> Sorry, No results found. Please give any exam for checking results.</div></td>
        </tr>
      );
    }
  }

  resultPagination() {
    // Add pagination.
    if (this.state.available_result.length > this.state.itemsCountPerPage) {
      return (
        <div className="pagination-wrapper">
          <Pagination
            itemClass="page-item"
            linkClass="page-link"
            activePage={this.state.activePage}
            itemsCountPerPage={this.state.itemsCountPerPage}
            totalItemsCount={this.state.totalItemsCount}
            pageRangeDisplayed={this.state.pageRangeDisplayed}
            onChange={this.handlePageChange.bind(this)}
          />
        </div>
      );
    }
  }

  render() {
    $(document).ready(function() {
      $('.submit-btn-loader').hide();
    });
    return (
      <div className="result-list">
      <h2>All Result</h2>
      <div className="result-list-inner">
        <table width="100%">
          <thead>
            <tr className="result-header">
              <th>Index</th>
              <th>Exam Name</th>
              <th>Test Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.resultList()}
          </tbody>
        </table>
        {this.resultPagination()}
      </div>
      </div>
    );
  }

}

export default Result_list;
