import React from 'react';
import {
  Route,
  BrowserRouter
} from "react-router-dom";
import './App.css';

import Header from "./components/Layout/Header/Header";
import Footer from "./components/Layout/Footer/Footer";

import Home from "./pages/Home/Home";
import About_us from "./pages/about/About_us";
import Login from "./pages/login/Login";
import Register_Candidate from "./pages/register/Register_candidate";
import Exam_types from "./pages/Exams/Exam_types";
import Available_exams from "./pages/Exams/Available_exams";
import Exam_type_id from "./pages/Exams/Filter_exam_id"
import Questions from "./pages/Exams/Questions";
import Tutorial from "./pages/Exams/Tutorial";
import EmailVerify from "./pages/register/EmailVerify";
import Result_list from "./pages/result/Result_list";
import Result from "./pages/result/Result_certificate";
import Result_summary from "./pages/result/Result_summary";
import Exam_review from "./pages/Exams/Exam_review";
import Dashboard from "./pages/dashboard/Dashboard";
import Pay from "./pages/Exams/Pay";
import success from "./pages/Exams/success";
import fail from "./pages/Exams/fail";
import Agreement from "./pages/Exams/Agreement";
import Assigned_exams from "./pages/Exams/Assigned_exams";
import $ from "jquery";
import { LoopCircleLoading } from 'react-loadingg';

function App() {
  let pathMatchFound = window.location.pathname.match('/(tutorial|questions|review|agreement|result_summary)') == null;
  const renderHeader = () => {
    if (pathMatchFound) {
      return <Header />
    }
    else {
      return '';
    }
  }
  const renderFooter = () => {
    if (pathMatchFound) {
      return <Footer />
    }
    else {
      return '';
    }
  }
  var assignedExams = '';
  let userName = localStorage.getItem('userData.name');
  if (userName) {
    if (!userName.includes('IQSOL')) {
      assignedExams = <Route path="/assigned-exams" component={Assigned_exams}/>;
    }
  }
  // eslint-disable-next-line
  if($('.content').html() == "") {
    $('.submit-btn-loader').hide();
  }
  return (
    <BrowserRouter>
      <div className="page">
        <div className="wrapper">
          {renderHeader()}
          <div className="submit-btn-loader"><LoopCircleLoading color="#F92424" /><p>Please Wait...</p></div>
          <div className="content">
            <Route exact path="/" component={Home}/>
            <Route path="/about-us" component={About_us}/>
            <Route path="/login" component={Login}/>
            <Route path="/register" component={Register_Candidate}/>
            <Route path="/exam_types" component={Exam_types}/>
            <Route path="/exams" component={Available_exams}/>
            <Route path="/exam/:id" component={Exam_type_id}/>
            <Route path="/questions" component={Questions}/>
            <Route path="/tutorial" component={Tutorial}/>
            <Route path="/verify/:id/:token" component={EmailVerify}/>
            <Route path="/allresult" component={Result_list}/>
            <Route path="/result" component={Result}/>
            <Route path="/result_summary" component={Result_summary}/>
            <Route path="/dashboard" component={Dashboard}/>
            <Route path="/review" component={Exam_review}/>
            <Route path="/pay/:id" component={Pay}/>
            <Route path="/payu/success" component={success}/>
            <Route path="/payu/fail" component={fail}/>
            <Route path="/agreement" component={Agreement}/>
            {assignedExams}
          </div>
        </div>
        {renderFooter()}
      </div>
    </BrowserRouter>
  );
}

export default App;
