import React, {Component} from 'react';
import Popup from "reactjs-popup";
import ReactHtmlParser from 'react-html-parser'
import {
  Icon
} from "@blueprintjs/core";
import '../../css/Exam.css';
import $ from "jquery";
import { TransverseLoading } from 'react-loadingg';
import Spinner from 'react-ions/lib/components/Spinner';
import {RemovePaymentData} from '../../services/PostData';
import Pagination from "react-js-pagination";

class Filter_exam_id extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter_exams: [],
      filterExamsPerPage: [],
      attemptPauseExam: [],
      isLoading: true,
      activePage: 1,
      totalItemsCount: 0,
      itemsCountPerPage: 10,
      pageRangeDisplayed: 5,
      skipCountPerPage: 0,
    }
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      const examid = this.props.match.params.id;
      fetch(process.env.REACT_APP_CBT + 'api/exam/' + examid + '?_format=json')
      .then(res => res.json()) // It resolves the promise with a JSON object
      .then(res => {
        if (res.length > 0) {
          var filterExamsPerPage = res.slice(this.state.skipCountPerPage, this.state.itemsCountPerPage);
        }
        this.setState({
          filter_exams : res,
          isLoading : false,
          totalItemsCount : res.length,
          filterExamsPerPage : filterExamsPerPage
        });
      })

      // get all the attempted exams.
      const userId = localStorage.getItem('userData.uid');
      if (userId != null) {
        fetch(process.env.REACT_APP_CBT + 'api/exams/candidate/' + userId + '?_format=json')
        .then(res => res.json()) // It resolves the promise with a JSON object
        .then(res => {
          this.setState({ attemptPauseExam : res });
        })
      }
    }
  }

  redirectToTarget = (e) => {
    let userName = localStorage.getItem('userData.name');

    var exam_time_diff = e.currentTarget.attributes['exam_time_diff'] ? e.currentTarget.attributes['exam_time_diff'].value : null;
    // eslint-disable-next-line
    if (userName && e.currentTarget.attributes['pause'].value <= 2 && (exam_time_diff && exam_time_diff <= 36)) {
      this.props.history.push('/questions', {
        response: e.target.id,
        exam_name: e.currentTarget.attributes['exam'].value,
        exam_time: e.currentTarget.attributes['exam_time'].value,
        result_id: e.currentTarget.attributes['result_id'].value,
        pauseAttemptCount: e.currentTarget.attributes['pause'].value,
        question: e.currentTarget.attributes['data-question'].value,
      });
      window.location.reload();
      e.preventDefault();
    }
    else if (userName) {
      this.props.history.push('/agreement', {
        response: e.target.id,
        exam_name: e.currentTarget.attributes['exam'].value,
        exam_time: e.currentTarget.attributes['exam_time'].value,
        exam_loc: window.location.pathname,
        question: e.currentTarget.attributes['data-question'].value,
      });
      window.location.reload();
      e.preventDefault();
    }
    // eslint-disable-next-line
    else if (userName === null) {
      this.props.history.push('/login');
    }
  }

  redirectToPayment = (e) => {
    let userName = localStorage.getItem('userData.name');
    if (userName) {
      window.location = '/pay/' + e.currentTarget.attributes['examid'].value;
      this.props.history.push('/pay/' + e.currentTarget.attributes['examid'].value, {
        examid: e.currentTarget.attributes['examid'].value,
        exam_fee: e.currentTarget.attributes['fee'].value,
        exam_name: e.currentTarget.attributes['exam'].value,
        exam_time: e.currentTarget.attributes['exam_time'].value,
        question: e.currentTarget.attributes['data-question'].value,
      });
    }
    else {
      window.location = '/login';
    }
  }

  componentDidUpdate() {
    const uid = localStorage.getItem('userData.uid');
    var user_id;
    if (uid == null) {
      user_id = '1';
    }
    else {
      user_id = uid;
    }

    if (this.state.filter_exams.length > 0) {
      $( ".available_exams_list" ).each(function(e) {
        fetch(process.env.REACT_APP_CBT + 'api/question/en/' + $(this).attr('id') + '/' + user_id + '?_format=json')
        .then(res => res.json()) // It resolves the promise with a JSON object
        .then(res => {
          if (res.message) {
            $('td.question-count', this).html('0');
            $('td.exam_action .exam_pay', this).attr('disabled', true);
            $('td.exam_action .exam_pay', this).attr('data-toggle','tooltip');
            $('td.exam_action .exam_pay', this).attr('title','Currently this exam is not available.');
          }
          else {
            if (res.length > 0) {
              $('td.question-count', this).html(res.length);
              var data_str = encodeURIComponent(JSON.stringify(res));
              $('td.exam_action .start_exam', this).attr('data-question', data_str);
              $('td.exam_action .exam_pay', this).attr('data-question', data_str);
            }
          }
        })
      });
    }

    if (this.state.attemptPauseExam.length > 0) {
      let examTime = '';
      let pauseTimeDiffHr = '';
      // eslint-disable-next-line
      return this.state.attemptPauseExam.map((pitem) => {
        pauseTimeDiffHr = Math.floor(Math.abs(Date.now() - (Date.parse(pitem.exam_given_date))) / 36e5);
        // eslint-disable-next-line
        if (pitem.exam_pause_count <= 2 && pauseTimeDiffHr <= 36 && pitem.result_total_marks === null) {
          // eslint-disable-next-line
          if ($('td[value="' + pitem.exam_id + '"] button').attr("value") == pitem.exam_id) {
            $('.exam-fee[value=' + pitem.exam_id + ']').text('PAID');
            $('td.exam_action button.start_exam[value=' + pitem.exam_id + ']').show();
            $('td.exam_action button.start_exam[value=' + pitem.exam_id + ']').html("Continue");
            $('td.exam_action button.start_exam[value=' + pitem.exam_id + ']').attr("exam_time", pitem.exam_pause_time);
            $('td.exam_action button.start_exam[value=' + pitem.exam_id + ']').attr("pause", pitem.exam_pause_count);
            $('td.exam_action button.start_exam[value=' + pitem.exam_id + ']').attr("result_id", pitem.result_id);
            $('td.exam_action button.start_exam[value=' + pitem.exam_id + ']').attr("exam_time_diff", pauseTimeDiffHr);
            $('td.exam_action button.start_exam[value=' + pitem.exam_id + ']').attr("exam_total_marks", pitem.result_total_marks);

            examTime = Math.floor(pitem.exam_pause_time/60000);
            $('.available_exams_list td[exam_value="' + pitem.exam_id + '"]').innerHTML = examTime;
            $('td.exam_action button.exam_pay[value=' + pitem.exam_id + ']').hide();
          }
        }
        else {
          $('.available_exams_list button.start_exam').hide();
        }
        // eslint-disable-next-line
        if ((pitem.exam_pause_count == 1 || pitem.exam_pause_count == 2) && pauseTimeDiffHr >= 36 && pitem.result_total_marks !== null) {
          const csrf_token = localStorage.getItem('userData.csrf_token');
          const auth = localStorage.getItem('userData.auth');
          const uid = localStorage.getItem('userData.uid');

          let userdata = {
            "uid": uid,
            "emptydata": "",
            csrf_token,
            auth
          }

          RemovePaymentData('user/' + uid , userdata)
          .then((response) => {
            console.log("Remove Payment metdata on pause time difference is greter than 36 Hours!");
          })
          $('td.exam_action button.start_exam[value=' + pitem.exam_id + ']').hide();
          $('td.exam_action button.exam_pay[value=' + pitem.exam_id + ']').show();
        }
      });
    }
    $('.available_exams_list button.start_exam').hide();
  }

  // Handle page changes.
  handlePageChange(pageNumber) {
    var skipPagesExamTypes = (pageNumber - 1) * this.state.itemsCountPerPage;
    var skipCountPerPage = this.state.skipCountPerPage + skipPagesExamTypes;
    var showNextCount = this.state.itemsCountPerPage * pageNumber;
    var filteredAllExams = this.state.filter_exams;
    this.setState({
      activePage: pageNumber,
      filterExamsPerPage : filteredAllExams.slice(skipCountPerPage, showNextCount)
    });
  }

  filteredExamsList(){
    $('[data-toggle="tooltip"]').tooltip();
    // eslint-disable-next-line
    if (this.state.isLoading == true) {
      return (
        <tr>
          <td colSpan="7"><div className="result_loder"><TransverseLoading color="#F92424" /></div></td>
        </tr>
      );
    }
    else if(this.state.filter_exams.length > 0) {
      return this.state.filterExamsPerPage.map((item) => {
        let description = '';

        if (item.description != null){
          description = <Popup trigger={<label className="exam-description"><Icon icon="info-sign" /></label>} position="left center">
            <div>{ReactHtmlParser(item.description)}</div>
          </Popup>
        }
        const uid = localStorage.getItem('userData.uid');

        if (uid) {
          fetch(process.env.REACT_APP_CBT + 'user/' + uid + '?_format=json')
          .then(res => res.json()) // It resolves the promise with a JSON object
          .then(res => {
            if (res.field_user_payment_metadata) {
              if (res.field_user_payment_metadata.length > 0) {
                var response = res.field_user_payment_metadata[0].value;
                var myObject = JSON.parse(response);
                var ex_name = myObject.result.productinfo;
                var ex_status = myObject.result.txnStatus;
                if (res.field_user_payment_metadata[0].value.length > 0) {
                  // eslint-disable-next-line
                  if (item.title == ex_name && ex_status == 'SUCCESS') {
                    $('.exam-fee[value=' + item.id + ']').text('PAID');
                    $('.exam_action button.start_exam[value=' + item.id + ']').show();
                    $('.exam_action button.exam_pay[value=' + item.id + ']').hide();
                  }
                }
              }
            }
          })
        }
        // if($('.exam_action button.exam_pay[value=' + item.id + ']').css("display") != "none") {
        //   $('.exam-fee[value=' + item.id + ']').text('PAID');
        // }
        return (
          <tr key={ item.id } className="available_exams_list" id={item.id}>
            <td>{ item.index }</td>
            <td>{ item.title }</td>
            <td className="question-count">
              <Spinner loading type='spinner-circular' position='inline' color="#F92424"/>
            </td>
            <td>{ item.marks }</td>
            <td className="exam-time" exam_value= {item.id}>{ item.time }</td>
            <td className="exam-fee" value={item.id}>{ item.fee }</td>
            <td className="exam_action" value= {item.id}>
              <label><button exam_time={item.time * 60000} exam={item.title.trim()} pause="0" className="start_exam" id={item.id} value= {item.id} onClick={this.redirectToTarget}>Start Exam</button></label>
              <label><button className ="exam_pay" exam_time={item.time * 60000} exam={item.title.trim()} fee={ item.fee } value= {item.id} examid={ item.id } onClick={this.redirectToPayment}>Pay</button></label>
              {description}
            </td>
          </tr>
        );
      });
    }
    else {
      return (
        <tr>
          <td colSpan="7"><div className="no_result_message"> Sorry, No exams found. Please contact to site administrator.</div></td>
        </tr>
      );
    }
  }

  filteredExamsPagination() {
    // Add pagination.
    if (this.state.filter_exams.length > this.state.itemsCountPerPage) {
      return (
        <div className="pagination-wrapper">
          <Pagination
            itemClass="page-item"
            linkClass="page-link"
            activePage={this.state.activePage}
            itemsCountPerPage={this.state.itemsCountPerPage}
            totalItemsCount={this.state.totalItemsCount}
            pageRangeDisplayed={this.state.pageRangeDisplayed}
            onChange={this.handlePageChange.bind(this)}
          />
        </div>
      );
    }
  }

  render() {
    $(document).ready(function() {
      $('.submit-btn-loader').hide();
    });
    return (
      <div className="exam-list">
        <h2>Exam List</h2>
        <div className="exam-list-inner">
          <table width="100%">
            <thead>
              <tr className="exam-header">
                <th>Index</th>
                <th>Title</th>
                <th>Questions</th>
                <th>Marks</th>
                <th>Time (Min)</th>
                <th>Exam Fee (₹)</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {this.filteredExamsList()}
            </tbody>
          </table>
          {this.filteredExamsPagination()}
        </div>
      </div>
    );
  }
}

export default Filter_exam_id;
