import React, {Component} from 'react';
import {
  ControlGroup,
  Button
} from "@blueprintjs/core";

import { Form } from "react-bootstrap";

import {Redirect} from 'react-router-dom';
import {PostData} from '../../services/PostData';
import '../../css/Login.css'
import ReactHtmlParser from 'react-html-parser'
import $ from "jquery";

class Login extends Component {

  constructor(props){
    super(props);

    this.state = {
     username: '',
     password: '',
     redirectToReferrer: false,
     login_errors: '',
     errors: []
    };

    this.login = this.login.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  login() {
    let errors = {};

    if(!this.state.username) {
      errors["username"] = "Username should not be empty.";
    }
    if(!this.state.password) {
      errors["password"] = "Password should not be empty.";
    }
    this.setState({errors: errors});

    if(!errors.username && !errors.password && this.state.username && this.state.password) {
      PostData('user/login',this.state)
      .then((result) => {

        this.setState({login_errors: result.message});
        this.setState({errors: errors});

        let responseJson = result;
        let basicAuthCredential = responseJson.current_user.name + ":" + this.state.password;
        let basic64 =  btoa(basicAuthCredential);
        let encodedString = 'basic ' + basic64;

        if(responseJson.current_user && responseJson.current_user.uid > 0){
          sessionStorage.setItem('userData',JSON.stringify(responseJson));
          localStorage.setItem('userData.name', responseJson.current_user.name);
          localStorage.setItem("userData.csrf_token", responseJson.csrf_token);
          localStorage.setItem("userData.logout_token", responseJson.logout_token);
          localStorage.setItem("userData.uid", responseJson.current_user.uid);
          localStorage.setItem("userData.auth", encodedString);

          window.location.reload();

          this.setState({redirectToReferrer: true});
        }
        else {
          // Failed Login
          sessionStorage.setItem('userData.failedLogin', responseJson.message);
          this.setState({'url': '/login'});
          this.setState({login_errors: "Incorrect username or password"});
        }
      })
      .catch(err => {
        return err;
      })
    }
  }

  handleSubmit = event => {
    event.preventDefault();
  }

  onChange(e){
    this.setState({[e.target.name]:e.target.value});
  }

  render() {
    $(document).ready(function() {
      $('.submit-btn-loader').hide();
    });
    var userName = localStorage.getItem('userData.name');
    if (userName != null && !userName.includes('IQSOL')) {
      return (<Redirect to={'/assigned-exams'}/>)
    }
    else if(this.state.redirectToReferrer || localStorage.getItem('userData') || sessionStorage.getItem('userData')) {
      return (<Redirect to={'/exams'}/>)
    }

    const failedMessage = sessionStorage.getItem('userData.failedLogin')
    if(failedMessage) {
      sessionStorage.removeItem('userData.failedLogin')
    }

    var response_msg;
    var response_msg_inactive;
    var message;
    if (this.props.location.state == null) {
      response_msg = ""
      response_msg_inactive = ""
    }
    else {
      response_msg = this.props.location.state.verified;
      response_msg_inactive = this.props.location.state.not_verified;
      if (response_msg === 'Your Account has been Activated, please log in.') {
        message = '<span class="verified">' + response_msg + '</span>'
      }
      else {
        message = response_msg
      }
    }

    return (
      <div className="user-login">
        <div className="login-form app-small-form">
          <h1 className="login-form-title">Login to your account</h1>
          <h3 className="verified-title">{ReactHtmlParser(message)}</h3>
          <h3 className="not-verified-title">{response_msg_inactive}</h3>
          <Form onSubmit={this.handleSubmit} className="login-form-wrapper">
            <ControlGroup fill={true} vertical={true}>
              <div><span className="error">{this.state.login_errors}</span></div>
              <label className="pt-label right">
                Email or Candidate ID *
                <div className="pt-input-group">
                  <input
                    name={ "username" }
                    type={ "text" }
                    required
                    className={ "form-control pt-input" }
                    onChange= {this.onChange}
                  />
                  <span className="error">{this.state.errors["username"]}</span>
                </div>
              </label>
              <label className="pt-label left">
                Password *
                <div className="pt-input-group">
                  <input
                    name={ "password" }
                    type={ "password" }
                    required
                    className={ "form-control pt-input" }
                    onChange= {this.onChange}
                  />
                  <span className="error">{this.state.errors["password"]}</span>
                </div>
              </label>
              <div className="login-button">
                <Button className="login-submit" type="submit" intent="primary" large="true" text="Login" onClick={this.login}/>
              </div>
              <div className="links-group">
                <div className="form-group group-register">
                  <a href="/register" alt="Create a new account.">Create New Account</a>
                </div>
              </div>
            </ControlGroup>
          </Form>
        </div>
      </div>
    );
  }
}

export default Login;
