import React, {Component} from 'react';
import $ from "jquery";

class fail extends Component {
  render() {
    $(document).ready(function() {
      $('.submit-btn-loader').hide();
    });
    return (
      <div className="payment-fail-form">
        <h2>Payment Failed!</h2>
      </div>
    );
  }
}

export default fail;
