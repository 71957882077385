import React, { Component } from "react";
import {
  Button,
  ControlGroup,
  TextArea
} from "@blueprintjs/core";
import { Form } from "react-bootstrap";
import $ from "jquery";
import '../../css/Register.css';
import {PostUserRegisterData} from '../../services/PostData';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ReactHtmlParser from 'react-html-parser'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ClipLoader from "react-spinners/ClipLoader";

class Register_Candidate extends Component {
  constructor(props) {
    super(props);

    // Max Date global variable.
    global.MaxDateVar = new Date().toISOString().split('T')[0];

    this.state = {
      user_picture: "",
      field_first_name: "",
      field_middle_name: "",
      field_last_name: "",
      mail: "",
      name: "",
      pass: "",
      field_is_your_course_self_sp: "0",
      field_course_sponsored_or_not: "0",
      field_user_company_name: "",
      field_user_company_email: "",
      field_user_person_to_address: "",
      field_user_company_gst_number: "",
      field_user_company_address: "",
      field_user_personal_address: "",
      field_user_prefix: "",
      field_user_self_name: "",
      field_birth_date: "",
      field_user_company: "",
      field_user_office_tel: "",
      field_user_fax_no: "",
      field_user_resi_tel_: "",
      field_user_contact_number: "",
      field_qualification: "",
      field_user_experience: "",
      redirect: false,
      options: this.options,
      value: null,
      country: "",
      region: "",
      qualification: [],
      errors: [],
      loading: true,
      recaptcha: false,
    }
    this.signup = this.signup.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onBlur= this.onBlur.bind(this);
    this.verifyBack = this.verifyBack.bind(this);
  }

  componentDidMount() {
    fetch(process.env.REACT_APP_CBT + 'api/qualification?_format=json')
    .then(res => res.json()) // It resolves the promise with a JSON object
    .then(res => {
      this.setState({ qualification : res });
    })
  }

  componentDidUpdate() {
    $('input:radio[name="field_is_your_course_self_sp"][value="0"]').attr('checked', true);
    $('input:radio[name="field_course_sponsored_or_not"][value="0"]').attr('checked', true);
    // eslint-disable-next-line
    if ($('input:radio[name="field_course_sponsored_or_not"][value="0"]').attr('checked') == 'checked') {
      $('.company-sponsored-wrapper').hide();
    }
    // eslint-disable-next-line
    if($('input:radio[name="field_course_sponsored_or_not"][value="0"]').attr('checked') == 'checked' && $('input:radio[name="field_course_sponsored_or_not"][value="1"]').attr('checked') == 'checked') {
      $('.company-sponsored-wrapper').show();
    }

    $('input:radio[name="field_course_sponsored_or_not"]').change(
    function(){
      // eslint-disable-next-line
      if ($(this).val() == '1') {
        $('.company-sponsored-wrapper').show();
        $('input:radio[name="field_course_sponsored_or_not"][value="0"]').attr('checked', false);
        $('input:radio[name="field_course_sponsored_or_not"][value="1"]').attr('checked', true);
      }
      else {
        $('.company-sponsored-wrapper').hide();
        $('input:radio[name="field_course_sponsored_or_not"][value="0"]').attr('checked', true);
        $('input:radio[name="field_course_sponsored_or_not"][value="1"]').attr('checked', false);
      }
    });
  }

  qualification() {
    if(this.state.qualification.length > 0) {
      return this.state.qualification.map((item) => {
        return (
          <option key={ item.tid[0].value } value = { item.tid[0].value } >{ item.name[0].value }</option>
        );
      });
    }
  }

  signup() {
    let loader = <ClipLoader
          size={20}
          color={"red"}
          loading={this.state.loading}
        />;
    // eslint-disable-next-line
    if ($('input:radio[name="field_course_sponsored_or_not"]').val() == '1') {
      $('.company-sponsored-wrapper').show();
    }
    var randomstring = require("randomstring");

    const classname = $(".flag-dropdown .selected-flag .flag").attr('class');
    var country_code = classname.split(" ");

    let data = {
      "user_picture": this.state.user_pic,
      "field_first_name": this.state.field_first_name,
      "field_middle_name": this.state.field_middle_name,
      "field_last_name": this.state.field_last_name,
      "mail": this.state.mail,
      "name": randomstring.generate(8),
      "pass": this.state.pass,
      "field_is_your_course_self_sp": this.state.field_is_your_course_self_sp,
      "field_course_sponsored_or_not": this.state.field_course_sponsored_or_not,
      "field_user_company_name": this.state.field_user_company_name,
      "field_user_company_email": this.state.field_user_company_email,
      "field_user_person_to_address": this.state.field_user_person_to_address,
      "field_user_company_gst_number": this.state.field_user_company_gst_number,
      "field_user_company_address": this.state.field_user_company_address,
      "field_user_personal_address": this.state.field_user_personal_address,
      "field_user_prefix": this.state.field_user_prefix,
      "field_user_self_name": this.state.field_user_self_name,
      "field_birth_date": this.state.field_birth_date,
      "field_user_company": this.state.field_user_company,
      "field_user_office_tel": this.state.field_user_office_tel,
      "field_user_fax_no": this.state.field_user_fax_no,
      "field_user_resi_tel_": this.state.field_user_resi_tel_,
      "field_user_contact_number": this.state.field_user_contact_number,
      "field_qualification": this.state.field_qualification,
      "field_user_experience": this.state.field_user_experience,
      "file_name" : this.state.file_name,
      "file_mime" : this.state.file_mime,
      "file_base64": this.state.image64,
      "file_size": this.state.file_size,
      "country_code": country_code[1]
    };

    let errors = {};
    // eslint-disable-next-line
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // Validation
    if(!data.field_first_name) {
      errors["field_first_name"] = "Cannot be empty.";
    }
    if(!data.field_last_name) {
      errors["field_last_name"] = "Cannot be empty.";
    }
    if(!data.mail) {
      errors["mail"] = "Cannot be empty.";
    }
    else if(!re.test(data.mail)) {
      errors["mail"] = "Invalid Email Address";
    }
    if(!data.field_birth_date) {
      errors["field_birth_date"] = "Please select a Date.";
    }
    if(!data.pass) {
      errors["pass"] = "Cannot be empty.";
    }
    else if(data.pass.length < 6) {
      errors["pass"] = "Password Should be atleast of 6 character.";
    }
    if(!data.field_user_contact_number) {
      errors["field_user_contact_number"] = "Cannot be empty.";
    }
    else if(data.field_user_contact_number.length < 12) {
      errors["field_user_contact_number"] = "Invalid Contact Number.";
    }
    // eslint-disable-next-line
    if ($('input:radio[name="field_course_sponsored_or_not"]').val() == '1' && !data.field_user_company_name) {
      errors["field_user_company_name"] = "Cannot be empty.";
    }
    // eslint-disable-next-line
    if ($('input:radio[name="field_course_sponsored_or_not"]').val() == '1' && !data.field_user_company_email) {
      errors["field_user_company_email"] = "Cannot be empty.";
    }
    // eslint-disable-next-line
    else if($('input:radio[name="field_course_sponsored_or_not"]').val() == '1' && !re.test(data.field_user_company_email)) {
      errors["field_user_company_email"] = "Invalid Email Address";
    }
    // eslint-disable-next-line
    if ($('input:radio[name="field_course_sponsored_or_not"]').val() == '1' && !data.field_user_person_to_address) {
      errors["field_user_person_to_address"] = "Cannot be empty.";
    }
    // eslint-disable-next-line
    if ($('input:radio[name="field_course_sponsored_or_not"]').val() == '1' && !data.field_user_company_gst_number) {
      errors["field_user_company_gst_number"] = "Cannot be empty.";
    }
    // eslint-disable-next-line
    if ($('input:radio[name="field_course_sponsored_or_not"]').val() == '1' && !data.field_user_company_address) {
      errors["field_user_company_address"] = "Cannot be empty.";
    }
    if(!data.field_user_personal_address) {
      errors["field_user_personal_address"] = "Cannot be empty.";
    }
    if(!data.field_user_prefix) {
      errors["field_user_prefix"] = "Please select a Prefix.";
    }
    if(!data.field_user_self_name) {
      errors["field_user_self_name"] = "Cannot be empty.";
    }
    // eslint-disable-next-line
    if (this.state.recaptcha == false) {
      errors["recaptcha"] = "Verify Recaptcha.";
    }

    this.setState({errors: errors});

    if(!errors.user_picture && !errors.field_first_name && !errors.field_last_name && !errors.mail && !errors.field_birth_date && !errors.pass && !errors.field_qualification && !errors.field_user_contact_number) {
      this.setState({"loader": loader});
      PostUserRegisterData('api/register', data)
      .then(response => {
        if (response.message) {
          // eslint-disable-next-line
          if (response.message == 'Email already exists') {
            errors["mail"] = "Email already exists.";
          }
          errors["all"] = "<p style='color:red;margin-bottom:0px;text-decoration: underline;font-weight:bold;'>Known Errors:</p>" + response.message;
          this.setState({errors: errors});
        }
        else {
          confirmAlert({
            title: 'User Registered successfully',
            buttons: [
              {
                label: 'Ok',
                onClick: () => this.setState({redirect: true})
              }
            ]
          });
        }
      })
      .catch(err => {
        console.log(err)
        return err
      })
    }
  }

  selectCountry (val) {
    this.setState({ country: val });
  }

  selectRegion (val) {
    this.setState({ administrative_area: val });
  }

  submit = () => {
    // alert(JSON.stringify(data, null, 4));
  }

  async onBlur(e) {
    const { name, value } = e.target;
    if (e.target.files && e.target.files[0]) {
      var file_name = e.target.files[0].name;
      var file_mime = e.target.files[0].type;
      var file_size = e.target.files[0].size;
      this.setState({'file_name': file_name });
      this.setState({'file_mime': file_mime });
      this.setState({'file_size': file_size });
      this.setState({user_pic: file_name });
      var file_value = URL.createObjectURL(e.target.files[0]);
      let blob = await fetch(file_value).then(response => response.blob());
      const reader = new FileReader();
      reader.onload = () => {
        this.setState({image64: reader.result});
      }
      reader.readAsDataURL(blob);
      this.setState({'file_value': file_value });
    }

    this.setState({ [name]: value });
  }

  handleSubmit = event => {
    event.preventDefault();
  }

  onChange(e) {
    const { name, files } = e.target;
    this.setState({ [name]: files[0].name });
  }

  verifyBack = (response) => {
    if (response) {
      this.setState({
          recaptcha: true,
      });
    }
  }

  render() {
    $(document).ready(function() {
      $('.submit-btn-loader').hide();
    });
    if (this.state.redirect){
      this.props.history.push('/login', {
        not_verified: "Please check the Email to verify your account."
      });
    }
    var Recaptcha = require('react-recaptcha');
    // specifying your onload callback function
    var callback = function () {
      console.log('Done!!!!');
    };

    $('.register-form-wrapper .register-submit').attr("disabled", true);
    // eslint-disable-next-line
    if (this.state.recaptcha == true) {
      $('.register-form-wrapper .register-submit').attr("disabled", false);
    }

    return (
      <div className="register-form-wrapper">
        <h1 className="register-form-title">Candidate Registration</h1>
        <Form onSubmit={this.handleSubmit} className="register-form-wrapper" noValidate>
          <ControlGroup fill={true} vertical={true}>
            <fieldset>
              <div className="full-name-wrapper">
                <label className="pt-label">
                  First Name <span className="star">*</span>
                  <div className="pt-input-group">
                    <input
                      name={ "field_first_name" }
                      type={ "text" }
                      required
                      className={ "form-control pt-input" }
                      onChange= {this.onBlur}
                    />
                    <span className="error">{this.state.errors["field_first_name"]}</span>
                  </div>
                </label>
                <label className="pt-label">
                  Middle Name
                  <div className="pt-input-group">
                    <input
                      name={ "field_middle_name" }
                      type={ "text" }
                      className={ "form-control pt-input" }
                      onChange= {this.onBlur}
                    />
                    <span className="error">{this.state.errors["field_middle_name"]}</span>
                  </div>
                </label>
                <label className="pt-label">
                  Last Name <span className="star">*</span>
                  <div className="pt-input-group">
                    <input
                      name={ "field_last_name" }
                      type={ "text" }
                      required
                      className={ "form-control pt-input" }
                      onChange= {this.onBlur}
                    />
                    <span className="error">{this.state.errors["field_last_name"]}</span>
                  </div>
                </label>
              </div>
              <label className="pt-label">
                Is your course self sponsored? <span className="star">*</span>
                <div className="pt-input-group">
                  <input type="radio" name="field_is_your_course_self_sp" value="1" onChange= {this.onBlur}/>Yes &nbsp;&nbsp;&nbsp;
                  <input type="radio" name="field_is_your_course_self_sp" value="0" onChange= {this.onBlur}/>No
                  <span className="error">{this.state.errors["field_is_your_course_self_sp"]}</span>
                </div>
              </label>
              <label className="pt-label">
                Is your course company sponsored? <span className="star">*</span>
                <div className="pt-input-group">
                  <input type="radio" name="field_course_sponsored_or_not" value="1" onChange= {this.onBlur}/>Yes &nbsp;&nbsp;&nbsp;
                  <input type="radio" name="field_course_sponsored_or_not" value="0" onChange= {this.onBlur}/>No
                  <span className="error">{this.state.errors["field_course_sponsored_or_not"]}</span>
                </div>
              </label>
              <div className="company-sponsored-wrapper">
                <p><strong>If Company Sponsored, please fill up below information:</strong></p>
                <hr />
                <label className="pt-label left">
                  Company Name <span className="star">*</span>
                  <div className="pt-input-group">
                    <input
                      name={ "field_user_company_name" }
                      type={ "text" }
                      className={ "form-control pt-input" }
                      onChange= {this.onBlur}
                    />
                    <span className="error">{this.state.errors["field_user_company_name"]}</span>
                  </div>
                </label>
                <label className="pt-label right">
                  Company Email <span className="star">*</span>
                  <div className="pt-input-group">
                    <input
                      id={ "companyemail" }
                      className={ "form-control pt-input" }
                      name={ "field_user_company_email" }
                      type={ "email" }
                      onChange={this.onBlur}
                    />
                    <span className="error">{this.state.errors["field_user_company_email"]}</span>
                  </div>
                </label>
                <label className="pt-label left">
                  Person to whom invoice should be addressed <span className="star">*</span>
                  <div className="pt-input-group">
                    <input
                      name={ "field_user_person_to_address" }
                      type={ "text" }
                      className={ "form-control pt-input" }
                      onChange= {this.onBlur}
                    />
                    <span className="error">{this.state.errors["field_user_person_to_address"]}</span>
                  </div>
                </label>
                <label className="pt-label right">
                  Company GST Number <span className="star">*</span>
                  <div className="pt-input-group">
                    <input
                      name={ "field_user_company_gst_number" }
                      type={ "text" }
                      className={ "form-control pt-input" }
                      onChange= {this.onBlur}
                    />
                    <span className="error">{this.state.errors["field_user_company_gst_number"]}</span>
                  </div>
                </label>
                <label className="pt-label">
                  Company Address	 <span className="star">*</span>
                  <div className="pt-input-group">
                    <TextArea  rows={3} cols={40} name="field_user_company_address" onChange={this.onBlur}/>
                    <span className="error">{this.state.errors["field_user_company_address"]}</span>
                  </div>
                </label>
              </div>
              <div className="personal-details-wrapper">
                <p><strong>Personal Details</strong></p>
                <hr />
                <label className="pt-label left">
                  Personal Email<span className="star">*</span>
                  <div className="pt-input-group">
                    <input
                      id={ "registeremail" }
                      className={ "form-control pt-input" }
                      required
                      name={ "mail" }
                      type={ "email" }
                      onChange={this.onBlur}
                    />
                    <span className="error">{this.state.errors["mail"]}</span>
                  </div>
                </label>
                <label className="pt-label right">
                  Personal Password <span className="star">*</span>
                  <div className="pt-input-group">
                    <input
                      id={ "registerpass" }
                      className={ "form-control pt-input" }
                      required
                      name={ "pass" }
                      type={ "password" }
                      onChange={this.onBlur}
                    />
                    <span className="error">{this.state.errors["pass"]}</span>
                  </div>
                </label>
                <label className="pt-label">
                  Personal Address	 <span className="star">*</span>
                  <div className="pt-input-group">
                    <TextArea  rows={3} cols={40} name="field_user_personal_address" required onChange={this.onBlur}/>
                    <span className="error">{this.state.errors["field_user_personal_address"]}</span>
                  </div>
                </label>
              </div>
              <div className="certificate-details-wrapper">
                <p><strong>Required Name as on Certificate</strong></p>
                <hr />
                <div className="prefix-wrapper">
                  <label className="pt-label">
                    Prefix <span className="star">*</span>
                    <div className="pt-select">
                      <select name = {"field_user_prefix"} required ={ true } onChange= {this.onBlur}>
                        <option value = "" > Select </option>
                        <option value = "mr" > Mr </option>
                        <option value = "miss" > Miss </option>
                        <option value = "mrs" > Mrs </option>
                        <option value = "smt" > Smt </option>
                        <option value = "dr" > Dr </option>
                      </select>
                      <span className="error">{this.state.errors["field_user_prefix"]}</span>
                    </div>
                  </label>
                  <label className="pt-label">
                    Self Name <span className="star">*</span>
                    <div className="pt-input-group">
                      <input
                        className={ "form-control pt-input" }
                        required
                        name={ "field_user_self_name" }
                        type={ "text" }
                        onChange={this.onBlur}
                      />
                      <span className="error">{this.state.errors["field_user_self_name"]}</span>
                    </div>
                  </label>
                  <label className="pt-label">
                    Date of Birth <span className="star">*</span>
                    <div className="pt-input-group">
                      <input
                        name={ "field_birth_date" }
                        type={ "date" }
                        required
                        className={ "form-control pt-input" }
                        max={ global.MaxDateVar }
                        onChange= {this.onBlur}
                      />
                      <span className="error">{this.state.errors["field_birth_date"]}</span>
                    </div>
                  </label>
                </div>
                <div className="company-wrapper">
                  <label className="pt-label left">
                    Company
                    <div className="pt-input-group">
                      <input
                        className={ "form-control pt-input" }
                        required
                        name={ "field_user_company" }
                        type={ "text" }
                        onChange={this.onBlur}
                      />
                      <span className="error">{this.state.errors["field_user_company"]}</span>
                    </div>
                  </label>
                  <label className="pt-label right">
                    (Office) Tel
                    <div className="pt-input-group">
                      <input
                        className={ "form-control pt-input" }
                        required
                        name={ "field_user_office_tel" }
                        type={ "text" }
                        onChange={this.onBlur}
                      />
                      <span className="error">{this.state.errors["field_user_office_tel"]}</span>
                    </div>
                  </label>
                  <label className="pt-label left">
                    Fax No
                    <div className="pt-input-group">
                      <input
                        className={ "form-control pt-input" }
                        required
                        name={ "field_user_fax_no" }
                        type={ "text" }
                        onChange={this.onBlur}
                      />
                      <span className="error">{this.state.errors["field_user_fax_no"]}</span>
                    </div>
                  </label>
                  <label className="pt-label right">
                    (Resi) Tel
                    <div className="pt-input-group">
                      <input
                        className={ "form-control pt-input" }
                        required
                        name={ "field_user_resi_tel_" }
                        type={ "text" }
                        onChange={this.onBlur}
                      />
                      <span className="error">{this.state.errors["field_user_resi_tel_"]}</span>
                    </div>
                  </label>
                </div>
                <label className="pt-label left">
                  Mobile No <span className="star">*</span>
                  <div className="pt-input-group">
                    <PhoneInput
                      country={'in'}
                      name={"field_user_contact_number"}
                      value={this.state.field_user_contact_number}
                      onChange={field_user_contact_number => this.setState({ field_user_contact_number })}
                    />
                    <span className="error">{this.state.errors["field_user_contact_number"]}</span>
                  </div>
                </label>
                <label className="pt-label right">
                  Qualification
                  <div className="pt-select">
                    <select name = {"field_qualification"} required ={ true } onChange= {this.onBlur}>
                      <option value = "" > Select </option>
                      {this.qualification()}
                    </select>
                    <span className="error">{this.state.errors["field_qualification"]}</span>
                  </div>
                </label>
                <label className="pt-label">
                  Experience
                  <div className="pt-input-group">
                    <TextArea  rows={3} cols={40} name="field_user_experience" onChange={this.onBlur}/>
                    <span className="error">{this.state.errors["field_user_experience"]}</span>
                  </div>
                </label>
              </div>
              <label className="pt-label ">
                Upload Picture
                <div className="pt-input-group">
                  <input
                    accept="image/*"
                    name={ "user_picture" }
                    type={ "file" }
                    required
                    className={ "form-control pt-input-file" }
                    onChange= {this.onBlur}
                  />
                  <div><span className="error">{this.state.errors["user_picture"]}</span></div>
                </div>
              </label>
              <Recaptcha
                name={ "recaptcha" }
                sitekey="6LdyOZUUAAAAAMbMwJha3JKhCH_dFRwPGzDzxVMe"
                verifyCallback={this.verifyBack}
                onloadCallback={callback}
              />
              <span className="error">{this.state.errors["recaptcha"]}</span>
              <div className="all-errors">
                <span className="error">{ReactHtmlParser(this.state.errors["all"])}</span>
              </div>
            </fieldset>
            <Button className="register-submit" type="submit" intent="primary" large="true" text="Register" onClick={this.signup}/><span className="loader">{this.state.loader}</span>
          </ControlGroup>
        </Form>
      </div>
    );
  }
}
export default Register_Candidate;
