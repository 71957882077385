import React, { Component } from "react";
import {
  Button,
  ControlGroup
} from "@blueprintjs/core";
import { Form } from "react-bootstrap";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import '../../css/Register.css';
import $ from "jquery";
import * as sha512 from 'js-sha512';
import {AddPaymentData} from '../../services/PostData';

class Pay extends Component {
  constructor(props) {
    super(props);

    // Max Date global variable.
    global.MaxDateVar = new Date().toISOString().split('T')[0];

    this.state = {
      key: "",
      amount: "",
      txnid: "",
      firstname: "",
      lastname: "",
      productinfo: "",
      hash: "",
      email: "",
      phone: "",
      surl: "",
      user: [],
      errors: [],
      status_msg: "",
      loading: true    }
    this.pay = this.pay.bind(this);
    this.onBlur= this.onBlur.bind(this);
  }
  componentDidMount() {
    const uid = localStorage.getItem('userData.uid');
    var exam_fee;
    if (this.props.location.state != null) {
      exam_fee = this.props.location.state.exam_fee
    }
    else {
      exam_fee = "";
    }

    fetch(process.env.REACT_APP_CBT + 'user/' + uid + '?_format=json')
    .then(res => res.json()) // It resolves the promise with a JSON object
    .then(res => {
      $('.payment-form-wrapper .payment-wrapper .form-control.fee').val(exam_fee);
      $('.payment-form-wrapper .payment-wrapper .form-control.firstname').val(res.field_first_name[0] ? res.field_first_name[0].value : '');
      $('.payment-form-wrapper .payment-wrapper .form-control.lastname').val(res.field_last_name[0] ? res.field_last_name[0].value : '');
      $('.payment-form-wrapper .payment-wrapper .form-control.email').val(res.mail[0] ? res.mail[0].value : '');
      $('.payment-form-wrapper .payment-wrapper .react-tel-input input').val(res.field_user_contact_number[0] ? res.field_user_contact_number[0].value : '');

      this.setState({ amount: exam_fee });
      this.setState({ firstname: res.field_first_name[0] ? res.field_first_name[0].value : '' });
      this.setState({ lastname: res.field_last_name[0] ? res.field_last_name[0].value : '' });
      this.setState({ email: res.mail[0] ? res.mail[0].value : '' });
      this.setState({ phone: res.field_user_contact_number[0] ? res.field_user_contact_number[0].value : '' });
    })

    $('.payment-form-wrapper .payment-wrapper .fee').attr('disabled', true);
  }

  onBlur(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit = event => {
    event.preventDefault();
  }

  pay() {
    var exam_name;
    var question;
    if (this.props.location.state != null) {
      exam_name = this.props.location.state.exam_name
      question = this.props.location.state.question;
    }
    else {
      exam_name = "IQS CBT Product";
      question = "";
    }
    let BaseURL = process.env.REACT_APP_CBT;
    let amount = this.state.amount;
    let txid = new Date().getTime()+"";
    let productId = exam_name;
    let name = this.state.firstname;
    let email = this.state.email;
    let phone = this.state.phone;
    let surl = BaseURL + "payu/success"; //"http://localhost:3000/payu/success"
    let furl = BaseURL + "payu/fail"; //"http://localhost:3000/payu/fail"
    let key = process.env.REACT_APP_PAYMENT_MERCHANT_KEY; //Your Merchant ID here
    let salt = process.env.REACT_APP_PAYMENT_MERCHANT_SALT; //Your Key Here
    let hash_params = key + '|' + txid + '|' + amount + '|' + productId + '|' + name + '|' + email + '|||||||||||' + salt;
    let hash = sha512.sha512(hash_params);

    //Create a Data object that is to be passed to LAUNCH method of Bolt
    var data = {
       key: key,
       txnid: txid,
       amount: amount,
       firstname: name,
       email: email,
       phone: phone,
       productinfo: productId,
       surl: surl,
       furl: furl,
       hash: hash,
    }

    var history = this.props;

    var handler = {
      responseHandler: function(BOLT){
        let status = BOLT.response.txnStatus;
        // eslint-disable-next-line
        if (status == "CANCEL" || status == "FAILED") {
          $('.status-msg').html("Oops! Something went wrong. Please try again.");
        }
        else {
          history.history.push('/payu/success', {
            examid: history.location.state.examid,
            exam_name: history.location.state.exam_name,
            exam_time: history.location.state.exam_time,
            amount: amount,
            productinfo: productId,
            firstname: name,
            txnid: txid,
            question: question,
          });
        }
        // eslint-disable-next-line
        if (status == "SUCCESS" || status == "FAILED") {
          const uid = localStorage.getItem('userData.uid');
          const csrf_token = localStorage.getItem('userData.csrf_token');
          const auth = localStorage.getItem('userData.auth');
          let user_data = {
            "payment": BOLT.response,
            "userid": uid,
            "x_csrf_token": csrf_token,
            "auth": auth
          }

          AddPaymentData('user/' + uid, user_data)
          .then(response => {
            console.log("User updated");
          })
          .catch(err => {
            return err
          })
        }
      },
      catchException: function(BOLT){
        console.log(BOLT.message);
      }
    }
    window.bolt.launch( data , handler );
  }

  render() {
    $(document).ready(function() {
      $('.submit-btn-loader').hide();
    });
    return (
      <div className="payment-form-wrapper">
        <h1 className="payment-form-title">Payment Details</h1>
        <Form onSubmit={this.handleSubmit} className="payment-form-wrapper" noValidate>
          <ControlGroup fill={true} vertical={true}>
            <fieldset>
              <div className='status-msg'></div>
              <div className="payment-wrapper">
                <label className="pt-label left">
                  Fee <span className="star">*</span> ₹
                  <div className="pt-input-group">
                    <input
                      name={ "amount" }
                      type={ "text" }
                      required
                      className={ "form-control pt-input fee" }
                      onChange= {this.onBlur}
                    />
                    <span className="error">{this.state.errors["field_first_name"]}</span>
                  </div>
                </label>
                <label className="pt-label left firstname">
                  First Name <span className="star">*</span>
                  <div className="pt-input-group">
                    <input
                      name={ "firstname" }
                      type={ "text" }
                      className={ "form-control pt-input firstname" }
                      onChange= {this.onBlur}
                    />
                    <span className="error">{this.state.errors["firstname"]}</span>
                  </div>
                </label>
                <label className="pt-label right">
                  Last Name <span className="star">*</span>
                  <div className="pt-input-group">
                    <input
                      name={ "lastname" }
                      type={ "text" }
                      required
                      className={ "form-control pt-input lastname" }
                      onChange= {this.onBlur}
                    />
                    <span className="error">{this.state.errors["lastname"]}</span>
                  </div>
                </label>
                <label className="pt-label left">
                  Email<span className="star">*</span>
                  <div className="pt-input-group">
                    <input
                      id={ "registeremail" }
                      className={ "form-control pt-input email" }
                      required
                      name={ "email" }
                      type={ "email" }
                      onChange={this.onBlur}
                    />
                    <span className="error">{this.state.errors["email"]}</span>
                  </div>
                </label>
                <label className="pt-label right">
                  Phone <span className="star">*</span>
                  <div className="pt-input-group">
                    <PhoneInput
                      className={'phone'}
                      country={'in'}
                      name={"phone"}
                      value={this.state.phone}
                      onChange={phone => this.setState({ phone })}
                    />
                    <span className="error">{this.state.errors["phone"]}</span>
                  </div>
                </label>
              </div>
            </fieldset>
            <Button className="payment-submit" type="submit" intent="primary" large="true" text="Pay Now" onClick={this.pay}/><span className="loader">{this.state.loader}</span>
          </ControlGroup>
        </Form>
      </div>
    );
  }
}

export default Pay;
