import React, { Component } from "react";
import '../../css/Register.css';
import {VerifyToken} from '../../services/PostData';
import $ from "jquery";

class EmailVerify extends Component {
  constructor(props) {
    super(props);

    // Max Date global variable.
    global.MaxDateVar = new Date().toISOString().split('T')[0];
  }

  componentDidMount() {
    let temp_data = this.props.location.pathname.split("/");
    let data = {
      "uid": temp_data[2],
      "token": temp_data[3]
    };

    VerifyToken('api/verify-account', data)
    .then(response => {
      if (response.message) {
        this.props.history.push('/login', {
          verified: response.message
        });
      }
    })
    .catch(err => {
      return err
    })
  }

  render() {
    $(document).ready(function() {
      $('.submit-btn-loader').hide();
    });
    return (
      <div className="verification-wrapper">
      </div>
    );
  }
}
export default EmailVerify;
