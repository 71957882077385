// User Login
export function PostData(type, userData) {
  let BaseURL = process.env.REACT_APP_CBT + type + '?_format=json';
  return new Promise((resolve, reject) => {
    fetch(BaseURL, {
        method: 'POST',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          "name":  userData.username,
          "pass": userData.password
        }
        )
      })
      .then((response) => response.json())
      .then((res) => {
          resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
// User Register
export function PostUserRegisterData(type, data) {
  let apiUrl = process.env.REACT_APP_CBT + type + '?_format=json';
  return new Promise((resolve, reject) => {
    fetch(apiUrl,{
      method: 'POST',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        "uuid": "1416af38-76e1-437e-8847-2f6961cbc055",
        "username": data.name,
        "email": data.mail,
        "password": data.pass,
        "firstName": data.field_first_name,
        "middleName": data.field_middle_name,
        "lastName": data.field_last_name,
        'isselfsponsered': data.field_is_your_course_self_sp,
        'iscompanysponsered': data.field_course_sponsored_or_not,
        'companyName': data.field_user_company_name,
        'companyEmail': data.field_user_company_email,
        'invoicetoAddress': data.field_user_person_to_address,
        'companyGSTNo': data.field_user_company_gst_number,
        'companyAddress': data.field_user_company_address,
        'personalAddress': data.field_user_personal_address,
        'prefix': data.field_user_prefix,
        'selfName': data.field_user_self_name,
        'birthDate': data.field_birth_date,
        'company': data.field_user_company,
        'officeTel': data.field_user_office_tel,
        'faxNo': data.field_user_fax_no,
        'resTel': data.field_user_resi_tel_,
        'contactNumber': {
          "number" : data.field_user_contact_number,
          "country" : data.country_code.toUpperCase(),
          "localNumber" : data.field_user_contact_number
        },
        'qualification': data.field_qualification,
        'experience': data.field_user_experience,
        "picture": {
          "filename": data.file_name,
          "data": data.file_base64
        }
      })
    })
    .then((response) => response.json())
    .then((res) => {
        resolve(res);
    })
    .catch((error) => {
      reject(error);
    });
  });
}

// Update profile picture.
export function UpdateUserImage(type, data) {
  let apiUrl = process.env.REACT_APP_CBT + type + '?_format=json';
  return new Promise((resolve, reject) => {
    fetch(apiUrl,{
      method: 'POST',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
        // FIXME: Below lines causes 403: Forbidden.
        // 'X-CSRF-Token': data.x_csrf_token,
        // 'Authorization': data.auth
      },
      body: JSON.stringify({
        "id": data.uid,
        "picture": {
          "filename": data.file_name,
          "data": data.file_base64
        }
      })
    })
    .then((response) => response.json())
    .then((res) => {
      resolve(res);
    })
    .catch((error) => {
      reject(error);
    });
  });
}

// Create Request.
export function CreateResult(type, initial_data) {
  let apiUrl = process.env.REACT_APP_CBT + type + '?_format=json';
  return new Promise((resolve, reject) => {
    fetch(apiUrl, {
      method: 'POST',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': initial_data.x_csrf_token,
        'Authorization': initial_data.auth
      },
      body: JSON.stringify({
        "type": "result",
        "field_result_exam": {
          "target_id": initial_data.examid
        },
      })
    })
    .then((response) => response.json())
    .then((res) => {
        resolve(res);
    })
    .catch((error) => {
      reject(error);
    });
  });
}
// Update Result on each step.
export function UpdateResult(type, data) {
  let apiUrl = process.env.REACT_APP_CBT + type + '?_format=json';
  return new Promise((resolve, reject) => {
    fetch(apiUrl,{
      method: 'POST',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': data.x_csrf_token,
        'Authorization': data.auth
      },
      body: JSON.stringify({
        "type": "result",
        "field_solved_question_and_answer": {
          "value": data.question_id + "|" + data.chapter_id + "|" + data.selected_answer + "|" + data.correct_answer + "|" + data.iscorrect + "|" + data.marks + "|" + data.flag + "|" + data.question_feedback + "\n",
        },
        "field_remaining_time": {
          "value": data.remaining_exam_time,
        },
        "nid": {
          "value": data.nid
        },
      })
    })
    .then((response) => response.json())
    .then((res) => {
      resolve(res);
    })
    .catch((error) => {
      reject(error);
    });
  });
}
// Update Result on each step.
export function VerifyToken(type, data) {
  let apiUrl = process.env.REACT_APP_CBT + type + '?_format=json';
  return new Promise((resolve, reject) => {
    fetch(apiUrl,{
      method: 'POST',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        "id": data.uid,
        "temp_token": data.token
      })
    })
    .then((response) => response.json())
    .then((res) => {
        resolve(res);
    })
    .catch((error) => {
      reject(error);
    });
  });
}
// Update Flag on each step.
export function UpdateFlagResult(type, newdata) {
  let apiUrl = process.env.REACT_APP_CBT + type + '?_format=json';
  return new Promise((resolve, reject) => {
    fetch(apiUrl,{
      method: 'POST',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': newdata.x_csrf_token,
        'Authorization': newdata.auth
      },
      body: JSON.stringify({
        "type": "result",
        "field_solved_question_and_answer": {
          "value": newdata.question_id + "|" + newdata.chapter_id + "|" + newdata.selected_answer + "|" + newdata.correct_answer + "|" + newdata.iscorrect + "|" + newdata.marks + "|" + newdata.flag + "|" + newdata.question_feedback + "\n",
        },
        "nid": {
          "value": newdata.nid
        },
      })
    })
    .then((response) => response.json())
    .then((res) => {
        resolve(res);
    })
    .catch((error) => {
      reject(error);
    });
  });
}
// Update Result on each step.
export function UpdateUserExam(type, user_data) {
  let apiUrl = process.env.REACT_APP_CBT + type + '?_format=json';
  return new Promise((resolve, reject) => {
    fetch(apiUrl,{
      method: 'POST',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': user_data.x_csrf_token,
        'Authorization': user_data.auth
      },
      body: JSON.stringify({
        "uid": {
          "value": user_data.userid
        },
        'field_exam_details': {
          "value": user_data.examid
        },
      })
    })
    .then((response) => response.json())
    .then((res) => {
        resolve(res);
    })
    .catch((error) => {
      reject(error);
    });
  });
}
// Update exam review in Result.
export function UpdateReviewResult(type, data) {
  let apiUrl = process.env.REACT_APP_CBT + type + '?_format=json';
  return new Promise((resolve, reject) => {
    fetch(apiUrl,{
      method: 'PATCH',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': data.x_csrf_token,
        'Authorization': data.auth
      },
      body: JSON.stringify({
        "type": "result",
        "field_exam_review": {
          "value": data.examReview,
        },
        "field_result_pause_count": {
          "value": data.pauseCount,
        },
        "field_remaining_time": {
          "value": data.pauseTime,
        },
      })
    })
    .then((response) => response.json())
    .then((res) => {
        resolve(res);
    })
    .catch((error) => {
      reject(error);
    });
  });
}
// Update Time and count of exam in Result.
export function UpdatePauseTimeResult(type, data) {
  let apiUrl = process.env.REACT_APP_CBT + type + '?_format=json';
  return new Promise((resolve, reject) => {
    fetch(apiUrl,{
      method: 'PATCH',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': data.x_csrf_token,
        'Authorization': data.auth
      },
      body: JSON.stringify({
        "type": "result",
        "field_result_pause_count": {
          "value": data.pauseCount,
        },
        "field_remaining_time": {
          "value": data.pauseTime,
        },
        "field_result_paused_question": {
          "value": data.paused_q,
        },
        "field_paused_question_data": {
          "value": data.paused_ques_data,
        }
      })
    })
    .then((response) => response.json())
    .then((res) => {
        resolve(res);
    })
    .catch((error) => {
      reject(error);
    });
  });
}
// Update User Profile
export function ProfileData(type, data) {
    let apiUrl = process.env.REACT_APP_CBT + type + '?_format=json';
    return new Promise((resolve, reject) => {
    fetch(apiUrl, {
      method: 'PATCH',
      dataType: 'json',
      headers: {
        'Authorization': data.auth,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        [data.edited_key]: {
          "value": data.edited_value
        }
      })
    })
    .then((response) => response.json())
    .then((res) => {
      resolve(res);
    })
    .catch((error) => {
      reject(error);
    });
  });
}
// Add payment data.
export function AddPaymentData(type, user_data) {
  var result = user_data.payment;
  result = JSON.stringify({result});
  let apiUrl = process.env.REACT_APP_CBT + type + '?_format=json';
  return new Promise((resolve, reject) => {
    fetch(apiUrl,{
      method: 'PATCH',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        "field_user_payment_metadata": {
          "value": result
        },
      })
    })
    .then((res) => {
        resolve(res);
    })
    .catch((error) => {
      reject(error);
    });
  });
}

// User Logout
export function getUserLogout(type, data) {
  let BaseURL = process.env.REACT_APP_CBT + type + '?_format=json&token=' + data.logout_token;
  return new Promise((resolve, reject) => {
    fetch(BaseURL, {
        method: 'POST',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-CSRF-Token': data.csrf_token,
          'Authorization': data.auth
        }
      })
      .then((response) => response.json())
      .then((res) => {
          resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
// Update Time and count of exam in Result.
export function UpdateTotalMarksResult(type, data) {
  let apiUrl = process.env.REACT_APP_CBT + type + '?_format=json';
  return new Promise((resolve, reject) => {
    fetch(apiUrl,{
      method: 'PATCH',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': data.x_csrf_token,
        'Authorization': data.auth
      },
      body: JSON.stringify({
        "type": "result",
        "field_total_marks": {
          "value": data.totalMarks,
        },
      })
    })
    .then((response) => response.json())
    .then((res) => {
      resolve(res);
    })
    .catch((error) => {
      reject(error);
    });
  });
}


// Update Time and count of exam in Result.
export function QuestionTranslation(type, qdata) {
  let apiUrl = process.env.REACT_APP_CBT + type + '?_format=json';
  return new Promise((resolve, reject) => {
    fetch(apiUrl,{
      method: 'POST',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        "type": "question",
        "field_failed_answer_description": {
          "value": qdata.lang,
        },
        "body": {
          "value": JSON.stringify(qdata.question_data),
        },
      })
    })
    .then((response) => response.json())
    .then((res) => {
      resolve(res);
    })
    .catch((error) => {
      reject(error);
    });
  });
}

// Remove payment data.
export function RemovePaymentData(type, userdata) {
  let apiUrl = process.env.REACT_APP_CBT + type + '?_format=json';
  return new Promise((resolve, reject) => {
    fetch(apiUrl,{
      method: 'PATCH',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        "field_user_payment_metadata": {
          "value": userdata.emptydata
        },
      })
    })
    .then((res) => {
      resolve(res);
    })
    .catch((error) => {
      reject(error);
    });
  });
}

// Remove payment data.
export function BlockBackendUser(type, userdata) {
  let apiUrl = process.env.REACT_APP_CBT + type + '?_format=json';
  return new Promise((resolve, reject) => {
    fetch(apiUrl,{
      method: 'POST',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        "uid": {
          "value": userdata.uid
        },
        "examid": {
          "value": userdata.examid
        },
      })
    })
    .then((res) => {
      resolve(res);
    })
    .catch((error) => {
      reject(error);
    });
  });
}

// Send mail on exam end.
export function SendResultMail(type, data) {
  let apiUrl = process.env.REACT_APP_CBT + type + '?_format=json';
  return new Promise((resolve, reject) => {
    fetch(apiUrl,{
      method: 'POST',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': data.x_csrf_token,
        'Authorization': data.auth
      },
      body: JSON.stringify({
        "result_data": {
          "value": data.result_data
        },
        "result_id": {
          "value": data.result_id
        },
        "uid": {
          "value": data.uid
        },
        "email": {
          "value": data.email
        },
        "exam_id": {
          "value":  data.exam_id
        },
        "questionCount": {
          "value": data.questionCount
        }
      })
    })
    .then((response) => response.json())
    .then((res) => {
        resolve(res);
    })
    .catch((error) => {
      reject(error);
    });
  });
}
// Send exam-question set mail on exam end.
export function SendQuestionSetMail(type, quesData) {
  let apiUrl = process.env.REACT_APP_CBT + type + '?_format=json';
  return new Promise((resolve, reject) => {
    fetch(apiUrl,{
      method: 'POST',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': quesData.x_csrf_token,
        'Authorization': quesData.auth
      },
      body: JSON.stringify({
        "questions_data": {
          "value": quesData.questions_data
        },
        "result_id": {
          "value": quesData.result_id
        },
        "uid": {
          "value": quesData.uid
        },
        "email": {
          "value": quesData.email
        },
        "exam_name": {
          "value": quesData.exam_name
        },
        "solved_questions_data": {
          "value": quesData.solved_questions_data
        }
      })
    })
    .then((response) => response.json())
    .then((res) => {
        resolve(res);
    })
    .catch((error) => {
      reject(error);
    });
  });
}
// Send exam-question set mail on exam end.
export function EmailReviewResult(type, review_data) {
  let apiUrl = process.env.REACT_APP_CBT + type + '?_format=json';
  return new Promise((resolve, reject) => {
    fetch(apiUrl,{
      method: 'POST',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': review_data.x_csrf_token,
        'Authorization': review_data.auth
      },
      body: JSON.stringify({
        "result_id": {
          "value": review_data.nid
        },
        "uid": {
          "value": review_data.uid
        },
        "examReview": {
          "value": review_data.examReview
        },
        "exam_name": {
          "value": review_data.exam_name
        },
      })
    })
    .then((response) => response.json())
    .then((res) => {
        resolve(res);
    })
    .catch((error) => {
      reject(error);
    });
  });
}
// Update question set.
export function AddQuestionSet(type, data) {
  let apiUrl = process.env.REACT_APP_CBT + type + '?_format=json';
  return new Promise((resolve, reject) => {
    fetch(apiUrl,{
      method: 'PATCH',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': data.x_csrf_token,
        'Authorization': data.auth
      },
      body: JSON.stringify({
        "type": "result",
        "field_attempted_question_set": {
          "value": data.att_ques_set
        },
      })
    })
    .then((response) => response.json())
    .then((res) => {
        resolve(res);
    })
    .catch((error) => {
      reject(error);
    });
  });
}
