import React, {Component} from 'react';
import {Icon} from "@blueprintjs/core";
import InlineEdit from 'react-ions/lib/components/InlineEdit';
import FileUpload from 'react-ions/lib/components/FileUpload'
import Button from 'react-ions/lib/components/Button';
import Spinner from 'react-ions/lib/components/Spinner'
import Textarea from 'react-ions/lib/components/Textarea'
import PhoneInput from 'react-phone-input-2';
import ReactTooltip from "react-tooltip";
import {ProfileData} from '../../services/PostData';
import { LoopCircleLoading } from 'react-loadingg';
import { AlertSystem } from 'react-ions/lib/components/Alerts';
import {UpdateUserImage} from '../../services/PostData';
import $ from "jquery";

import '../../css/Dashboard.css';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    // Max Date global variable.
    global.MaxDateVar = new Date().toISOString().split('T')[0];

    this.state = {
      field_first_name_editing: false,
      field_middle_name_editing: false,
      field_last_name_editing: false,
      field_user_picture_edit: false,
      field_user_personal_address_editing: true,
      field_user_contact_number_edit: true,
      dataloading: false,
      isLoading: true,
      field_first_name_loading: false,
      field_middle_name_loading: false,
      field_last_name_loading: false,
      field_user_personal_address_loading : false,
      field_user_contact_number_loading: false,
      name: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      user_picture: '',
      email: '',
      address: '',
      phoneNumber: '',
      alerts: [],
    };
    this.pictureHandleCallback = this.pictureHandleCallback.bind(this);
  }

  componentDidMount() {
    // Fetch logged in user info.
    const userID = localStorage.getItem('userData.uid');
    if(userID) {
      fetch(process.env.REACT_APP_CBT + '/user/' + userID + '?_format=json')
      .then(data => data.json()) // It resolves the promise with a JSON object
      .then(data => {
        this.setState({
          name: data.name[0] ? data.name[0].value : '',
          first_name: data.field_first_name[0] ? data.field_first_name[0].value : '',
          middle_name: data.field_middle_name[0] ? data.field_middle_name[0].value : '',
          last_name: data.field_last_name[0] ? data.field_last_name[0].value : '',
          user_picture: data.user_picture[0] ? data.user_picture[0].url : '',
          // email: data.mail[0] ? data.mail[0].value : '',
          address: data.field_user_personal_address[0] ? data.field_user_personal_address[0].value : '',
          phoneNumber: data.field_user_contact_number[0] ? data.field_user_contact_number[0].value : '',
          isLoading: false
       });
      })
    }
  }

  submitContactButtonCallback = () => {
    const classname = $(".flag-dropdown .selected-flag .flag").attr('class');
    var country_code = classname.split(" ");
    if (this.state.field_user_contact_number && country_code[1]) {
      let data = {
        'value': this.state.field_user_contact_number,
        'country': country_code[1].toUpperCase(),
        'local_number': this.state.field_user_contact_number,
      };
      const csrf_token = localStorage.getItem('userData.csrf_token');
      const auth = localStorage.getItem('userData.auth');
      const uid = localStorage.getItem('userData.uid');
      let user_data = {
        "edited_key": 'field_user_contact_number',
        "edited_value": data,
        "x_csrf_token": csrf_token,
        "auth": auth
      }
      this.setState({
        field_user_contact_number_loading: true,
        phoneNumber: this.state.field_user_contact_number,
      })
      ProfileData('/user/' + uid, user_data)
      .then(response => {
        setTimeout(() => {this.setState({
          field_user_contact_number_loading: false,
          field_user_contact_number_edit: true,
         })}, 500);
         if (response.message) {
           let alerts = this.state.alerts

           alerts.push({ type:'danger', content: 'Added contact number is not valid, please check.'})
           this.setState({
             field_user_contact_number: true,
             phoneNumber: this.state.phoneNumber,
             alerts: alerts
            })
         }
      })
      .catch(err => {
        return err
      })

    }
  }

  submitAddressButtonCallback = () => {
    if (this.state.field_user_personal_address) {
      let value = this.state.field_user_personal_address.target.value;
      let name = this.state.field_user_personal_address.target.name;

      const csrf_token = localStorage.getItem('userData.csrf_token');
      const auth = localStorage.getItem('userData.auth');
      const uid = localStorage.getItem('userData.uid');
      let user_data = {
        "edited_key": name,
        "edited_value": value,
        "x_csrf_token": csrf_token,
        "auth": auth
      }

      ProfileData('/user/' + uid, user_data)
      .then(response => {
        this.setState({
          field_user_personal_address_editing: true,
          field_user_personal_address_loading: true,
          address: value
        })
        setTimeout(() => {this.setState({ field_user_personal_address_loading: false })}, 500)
      })
      .catch(err => {
        return err
      })
    }
  }

  // Save button callback.
  handleCallback = event => {
    let editingKey = [event.target.name] + '_editing';
    let loadingKey = [event.target.name] + '_loading';
    if (event.target.value) {
      const csrf_token = localStorage.getItem('userData.csrf_token');
      const auth = localStorage.getItem('userData.auth');
      const uid = localStorage.getItem('userData.uid');
      let user_data = {
        "edited_key": event.target.name,
        "edited_value": event.target.value,
        "x_csrf_token": csrf_token,
        "auth": auth
      }
      ProfileData('/user/' + uid, user_data)
      .then(response => {
        console.log("User updated");
      })
      .catch(err => {
        return err
      })

      this.setState({
        [editingKey]: false,
        [loadingKey]: true,
        [event.target.name]: event.target.value,
      })

      setTimeout(() => {this.setState({ [loadingKey]: false })}, 500)
    }
    else {
      let alerts = this.state.alerts

      alerts.push({ type:'danger', content:'Field is required.'})
      this.setState({
        [editingKey]: true,
        alerts: alerts
       })
    }
  }

  // Picture callback handle.
  async pictureHandleCallback(event) {
    // eslint-disable-next-line
    if (event.target.name == "user_picture" && event.target.value && event.target.value[0]) {
      var file_name = event.target.value[0].name;
      var file_value = URL.createObjectURL(event.target.value[0]);
      let blob = await fetch(file_value).then(response => response.blob());
      const reader = new FileReader();
      reader.onload = () => {
        const csrf_token = localStorage.getItem('userData.csrf_token');
        const auth = localStorage.getItem('userData.auth');
        const uid = localStorage.getItem('userData.uid');
        let user_data = {
          "uid": uid,
          "file_name": file_name,
          "file_base64": reader.result,
          "x_csrf_token": csrf_token,
          "auth": auth
        }
        UpdateUserImage('api/user/image/update', user_data)
        .then(response => {
          window.location.reload(true);
        })
        .catch(err => {
          return err
        })
      }
      reader.readAsDataURL(blob);

    }
  }

  // Edit button callback.
  pictureButtonCallback = () => {
    this.setState({
      field_user_picture_edit: true,
      field_first_name_editing : false,
      field_middle_name_editing : false,
      field_last_name_editing : false,
      field_user_personal_address_editing: true,
      field_user_contact_number_edit: true,
     })
  }
  canclePictureButtonCallback = () => {
    this.setState({
      field_user_picture_edit: false,
      field_first_name_editing : false,
      field_middle_name_editing : false,
      field_last_name_editing : false,
      field_user_personal_address_editing: true,
      field_user_contact_number_edit: true,
     })
  }
  firstNameButtonCallback = () => {
    this.setState({
      field_first_name_editing: true,
      field_user_picture_edit: false,
      field_middle_name_editing : false,
      field_last_name_editing : false,
      field_user_personal_address_editing: true,
      field_user_contact_number_edit: true,
     })
  }
  middleNameButtonCallback = () => {
    this.setState({
      field_middle_name_editing: true,
      field_user_picture_edit : false,
      field_first_name_editing : false,
      field_last_name_editing : false,
      field_user_personal_address_editing: true,
      field_user_contact_number_edit: true,
     })
  }
  lastNameButtonCallback = () => {
    this.setState({
      field_last_name_editing: true,
      field_user_picture_edit: false,
      field_middle_name_editing: false,
      field_first_name_editing : false,
      field_user_personal_address_editing: true,
      field_user_contact_number_edit: true,
     })
  }
  addressButtonCallback = () => {
    this.setState({
      field_user_personal_address_editing: false,
      field_user_contact_number_edit: true,
      field_last_name_editing: false,
      field_user_picture_edit: false,
      field_middle_name_editing: false,
      field_first_name_editing : false,
     })
  }
  cancelAddressButtonCallback = () => {
    this.setState({
      field_user_personal_address_editing: true,
      field_user_contact_number_edit: true,
      field_last_name_editing: false,
      field_user_picture_edit: false,
      field_middle_name_editing: false,
      field_first_name_editing : false,
     })
  }
  phoneButtonCallback = () => {
    this.setState({
      field_user_contact_number_edit: false,
      field_user_personal_address_editing: true,
      field_last_name_editing: false,
      field_user_picture_edit: false,
      field_middle_name_editing: false,
      field_first_name_editing : false,
    })
  }
  cancelContactButtonCallback = () => {
    this.setState({
      field_user_contact_number_edit: true,
      field_user_personal_address_editing: true,
      field_last_name_editing: false,
      field_user_picture_edit: false,
      field_middle_name_editing: false,
      field_first_name_editing : false,
    })
  }
  canclePictureButtonCallback = () => {
    this.setState({
      field_user_contact_number_edit: true,
      field_user_personal_address_editing: true,
      field_last_name_editing: false,
      field_user_picture_edit: false,
      field_middle_name_editing: false,
      field_first_name_editing : false,
     })
  }


  // User picture upload.
  userProfilePictureUpload() {
    if (this.state.field_user_picture_edit || this.state.user_picture === '') {
      return (
        <div className="user_profile_pic">
          <FileUpload name="user_picture" className="edit-user-picture" value={this.state.user_picture} changeCallback={this.pictureHandleCallback} />
          <Button className="editButton" onClick={this.canclePictureButtonCallback} >Cancel</Button>
        </div>
      )
    }
    else {
      return (
        <div className="user_profile_pic">
          <img src={this.state.user_picture} alt="profile-photos" className="img-responsive" />
          <Button className="editButton" onClick={this.pictureButtonCallback} >Upload New Picture</Button>
        </div>
      )
    }
  }

  userAddress() {
    // eslint-disable-next-line
    if (this.state.field_user_personal_address_editing == false && this.state.address !== null) {
      return(
        <div className="user-address-edit">
          <Textarea className="address-field" name='field_user_personal_address' value={this.state.address}  changeCallback={field_user_personal_address => this.setState({ field_user_personal_address })}/>
          <Icon icon="tick" onClick={this.submitAddressButtonCallback}/>
          <Icon icon="cross" onClick={this.cancelAddressButtonCallback}/>
          <Spinner loading={this.state.field_user_personal_address_loading} type='spinner-circular' position='inline' />
        </div>
      )
    }
    else {
      return (
        <div className="user-address-view">
          {this.state.address}
          <Button className="editButton" onClick={this.addressButtonCallback}><Icon icon="edit" /></Button>
        </div>
      )
    }
  }

  userPhoneNumber() {
    // eslint-disable-next-line
    if (this.state.field_user_contact_number_edit == false && this.state.phoneNumber !== null) {
      return(
        <div className="user-contact-number-edit">
          <PhoneInput country={'in'} name="field_user_contact_number" value={this.state.phoneNumber} inputProps={{autoFocus: true, required: true}} onChange={field_user_contact_number => this.setState({ field_user_contact_number })}/>
          <Icon icon="tick" onClick={this.submitContactButtonCallback}/>
          <Icon icon="cross" onClick={this.cancelContactButtonCallback}/>
          <Spinner loading={this.state.field_user_contact_number_loading} type='spinner-circular' position='inline' />
        </div>
      )
    }
    else {
      return (
        <div className="user-contact-number-view">
          {this.state.phoneNumber}
          <Button className="editButton" onClick={this.phoneButtonCallback}><Icon icon="edit" /></Button>
        </div>
      )
    }
  }

  render() {
    $(document).ready(function() {
      $('.submit-btn-loader').hide();
    });
    if (this.state.isLoading) {
      // Return loader from here.
      return (
        <LoopCircleLoading color="#F92424" />
      )
    }
    let candidateName = ''
    if (this.state.first_name && this.state.last_name) {
      candidateName = this.state.first_name + ' ' + this.state.last_name;
    }
    else if (this.state.email) {
      candidateName = this.state.email;
    }
    else if (this.state.name) {
      candidateName = this.state.name;
    }

    return (
      <div className="user-dashboard">
        <AlertSystem className="field-error" alerts={this.state.alerts} slideIn={true} />
        <div className="user_profile">
          <h2>Welcome {candidateName}.</h2>
        </div>

        <div className="user_info">
          {this.userProfilePictureUpload()}
          <div className="user_details">
            <div className="email_wrapper inline">
              <label>Personal Email :</label>
              <span data-tip="You can't edit email id. Please contact administartor for changing." data-event='click' data-event-off='dblclick'>
                {this.state.email}
              </span>
              <ReactTooltip place="top" type="warning" textColor="#000"/>
            </div>
            <div className="candidate_id_wrapper inline">
              <label>Candidate ID :</label>
              <span data-tip="You can't edit candidate id as its autogenerated." data-event='click' data-event-off='dblclick'>
                {this.state.name}
              </span>
              <ReactTooltip place="top" type="warning" textColor="#000"/>
            </div>
            <div className="first_name_wrapper inline">
              <label>First Name :</label>
              <InlineEdit name='field_first_name' className='first-name-field' value={this.state.first_name} isEditing={this.state.field_first_name_editing} changeCallback={this.handleCallback} />
              <Button className="editButton" onClick={this.firstNameButtonCallback}><Icon icon="edit" /></Button>
              <Spinner loading={this.state.field_first_name_loading} type='spinner-circular' position='inline' />
            </div>
            <div className="middle_name_wrapper inline">
              <label>Middle Name :</label>
              <InlineEdit name='field_middle_name' className="middle-name-field" value={this.state.middle_name} isEditing={this.state.field_middle_name_editing} changeCallback={this.handleCallback} />
              <Button className="editButton" onClick={this.middleNameButtonCallback}><Icon icon="edit" /></Button>
              <Spinner loading={this.state.field_middle_name_loading} type='spinner-circular' position='inline' />
            </div>
            <div className="last_name_wrapper inline">
              <label>Last Name :</label>
              <InlineEdit name='field_last_name' className="last-name-field" value={this.state.last_name} isEditing={this.state.field_last_name_editing} changeCallback={this.handleCallback}/>
              <Button className="editButton" onClick={this.lastNameButtonCallback}><Icon icon="edit" /></Button>
              <Spinner loading={this.state.lastNameLoading} type='spinner-circular' position='inline' />
            </div>
            <div className="personal_address_wrapper">
              <label>Personal Address :</label>
              {this.userAddress()}
            </div>
            <div className="phone_number_wrapper">
              <label>Mobile Number :</label>
              {this.userPhoneNumber()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
