import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import {
  Icon,
  Navbar,
  NavbarGroup,
  NavbarHeading
} from "@blueprintjs/core";
import logo from '../../../logo.svg';
import ResponsiveMenu from 'react-responsive-navbar';
import { Dropdown } from 'semantic-ui-react';
import {getUserLogout} from '../../../services/PostData';
import { Offline } from "react-detect-offline";

import styled from 'styled-components/macro';
const Menu = styled.div`
  ul {
    padding: 0;
  }
  li {
    display: inline;
    font-size: 10px;
    list-style-type: none;
    margin-left: 20px;
  }
  a {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 12px;
    color: MediumPurple;
    &:hover {
      color: white;
    }
  }
  @media (max-width: 900px) {
    padding: 10px 0;
    li {
      padding: 2px 0;
      display: block;
      margin-left: 0;
    }
  }
`;

class Header extends Component {
  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
      redirectToReferrer: false,
      user_picture : '',
      name: '',
      first_name: '',
      last_name: '',
      email: '',
      menuen: '',
      menulink: '',
      pdflink: '',
    };
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    const userID = localStorage.getItem('userData.uid');
    if (userID) {
      fetch(process.env.REACT_APP_CBT + '/user/' + userID + '?_format=json')
      .then(data => data.json()) // It resolves the promise with a JSON object
      .then(data => {
        this.setState({
          user_picture: data.user_picture[0] ? data.user_picture[0].url : '',
          name: data.name[0] ? data.name[0].value : '',
          first_name: data.field_first_name[0] ? data.field_first_name[0].value : '',
          last_name: data.field_last_name[0] ? data.field_last_name[0].value : '',
          // email: data.mail[0] ? data.mail[0].value : '',
          prefix: data.field_user_prefix[0]? data.field_user_prefix[0].value : '',
          self_name: data.field_user_self_name[0]? data.field_user_self_name[0].value : '',
        });
      });

      fetch(process.env.REACT_APP_CBT + '/api/pdf-menu?_format=json')
      .then(data => data.json()) // It resolves the promise with a JSON object
      .then(data => {
        this.setState({
          menuen: data.menu_enable ? data.menu_enable : '',
          menulink: data.menu_title ? data.menu_title : '',
          pdflink: data.pdf_menulink ? data.pdf_menulink : '',
        });
      });
    }
  }

  userProfile() {
    let candidateName = '';
    if (this.state.self_name) {
      candidateName  = this.state.prefix + ' ' + this.state.self_name;
    }
    else if (this.state.first_name && this.state.last_name) {
      candidateName = this.state.prefix + ' ' +this.state.first_name + ' ' + this.state.last_name;
    }
    else if (this.state.email) {
      candidateName = this.state.email;
    }
    else if (this.state.name) {
      candidateName = this.state.name;
    }

    if (this.state.user_picture) {
      return (
        <div className="user_data_dropdown">
          <img width='50px' src={this.state.user_picture} alt="profile-photos" className="img-responsive" />
          <span className="user_name">{ candidateName }</span>
          <span><Icon icon="caret-down" /></span>
        </div>
      )
    }
    else {
      return (
        <div className="user_data_dropdown">
          <Icon icon="user" />
          <span className="user_name">{ candidateName }</span>
          <span><Icon icon="caret-down" /></span>
        </div>
      )
    }
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  logout(){
    let csrf_token = localStorage.getItem('userData.csrf_token');
    let logout_token = localStorage.getItem('userData.logout_token');
    const auth = localStorage.getItem('userData.auth');

    let data = {
      logout_token,
      csrf_token,
      auth
    };

    getUserLogout('user/logout',data)
    .then((result) => {
      sessionStorage.setItem('userData','');
      sessionStorage.clear();

      localStorage.setItem('userData','');
      localStorage.setItem('userData.name','');
      localStorage.clear();
      window.location.reload();

      this.setState({redirectToReferrer: true});
    })
    .catch(err => {
      return err;
    })
  }

  render() {
    const trigger = (
      <span>
        {this.userProfile()}
      </span>
    )

    let button = ''
    let userName = ''
    let assignedExams = ''
    let pdflink = ''
    userName = localStorage.getItem('userData.name');

    if (userName) {
      // eslint-disable-next-line
      if (this.state.menuen == 0 || this.state.menulink == "" || this.state.pdflink == "") {
        pdflink = "";
      }
      else {
        pdflink = <li>
                    <i className="fa fa-file-pdf-o"></i>
                    <a className="pt-button-text pdf-link-menu" href={this.state.pdflink} target="_blank" rel="noopener noreferrer" download>{this.state.menulink}</a>
                  </li>;
      }
      if (!userName.includes('IQSOL')) {
        assignedExams = <li><Icon icon="book" /><Dropdown.Item text='Assigned Exams' as={Link} to='/assigned-exams'/></li>;
      }
      button = <li className="user_profile_dropdown">
                <Dropdown trigger={trigger} pointing='top left' className="userLinks" icon={null}>
                  <Dropdown.Menu>
                      <ul><li><Icon icon="user" /><Dropdown.Item text='Dashboard' as={Link} to='/dashboard'/></li>
                      <li><Icon icon="form" /><Dropdown.Item text='Result' as={Link} to='/allresult'/></li>
                      {assignedExams}
                      <li><Icon icon="log-out" /><Dropdown.Item text='Logout' as={Link} to='' onClick={this.logout}/></li></ul>
                  </Dropdown.Menu>
                </Dropdown>
            </li>
    }
    else {
      button = <li><NavLink href="/login" className="pt-button pt-minimal">
                <Icon icon="key" />
                <span className="pt-button-text">Sign In</span>
              </NavLink></li>
    }

    return (
      <div className="header-container">
      <Navbar fixedToTop="true" className="row">
        <NavbarGroup className="col-md-2">
          <NavbarHeading><Link to="/"><img src={logo} className="logo" alt="logo" /></Link></NavbarHeading>
        </NavbarGroup>
        <div className="site-menu">
          <ResponsiveMenu
            menuOpenButton={<div><Icon className="mainmenu-icon" icon="menu" /></div>}
            menuCloseButton={<div><Icon className="mainmenu-icon" icon="menu" /></div>}
            changeMenuOn="767px"
            largeMenuClassName="large-menu"
            smallMenuClassName="small-menu"
            menu={
              <Menu className="small-menu-wrapper">
                <ul>
                  <li>
                    <NavLink href="https://www.iqs-ndt.org/" className="pt-button pt-minimal">
                      <Icon icon="home" />
                      <span className="pt-button-text">Home</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink href="/about-us" className="pt-button pt-minimal">
                      <Icon icon="search" />
                      <span className="pt-button-text">About Exam</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink href="/exam_types" className="pt-button pt-minimal">
                      <Icon icon="edit" />
                      <span className="pt-button-text">Exams</span>
                    </NavLink>
                   </li>
                   {pdflink}
                  {button}
                </ul>
              </Menu>
            }
          />
        </div>
        <Offline><div className="offline-message">No Internet Connection</div></Offline>
      </Navbar>
      </div>
    );
  }
}

export default Header;
