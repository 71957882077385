import React, { Component } from "react";
import '../../css/Home.css';
// eslint-disable-next-line
import director_image from '../../images/director_image.jpg';
import $ from "jquery";
import ReactHtmlParser from 'react-html-parser';

class About_us extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aboutUsPage: '',
    }
  }

  componentDidMount() {
    fetch(process.env.REACT_APP_CBT + '/api/custom/configs?_format=json')
    .then(res => res.json()) // It resolves the promise with a JSON object
    .then(res => {
      this.setState({
        aboutUsPage : res.about_us_page,
      });
    })
  }

  render() {
    $(document).ready(function() {
      $('.submit-btn-loader').hide();
    });
    return (
      <div className="about">
        {ReactHtmlParser(this.state.aboutUsPage)}
      </div>
    );
  }
}

export default About_us;
