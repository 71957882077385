import React, {Component} from 'react';
import ReactHtmlParser from 'react-html-parser'
import Countdown from 'react-countdown';
import { CircularProgressbar } from 'react-circular-progressbar';
import {
  Icon
} from "@blueprintjs/core";
import $ from "jquery";
import '../../css/Tutorial.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import exam_logo from '../../logo_exam.png';
import Dialog from 'react-bootstrap-dialog'

class Tutorial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tutorial_exams:[],
      showItems: 1,
      maxItemsPerPage: 1,
      currentPage: 1,
      userInfo: [],
      renderer: '',
      percentage: 0
    }
  }

  componentDidMount() {
    var examid;
    var lang;
    if (this.props.location.state) {
      examid = this.props.location.state.response;
      lang = this.props.location.state.lang_sel;
    }
    else {
      examid = "";
      lang = "en"
    }
    if(examid) {
      fetch(process.env.REACT_APP_CBT + '/api/tutorial/exams/' + lang + '/' + examid + '?_format=json')
      .then(res => res.json()) // It resolves the promise with a JSON object
      .then(res => {
        this.setState({ tutorial_exams : res });
      })
    }

    // Fetch logged in user info.
    const userID = localStorage.getItem('userData.uid');
    if(userID) {
      fetch(process.env.REACT_APP_CBT + '/user/' + userID + '?_format=json')
      .then(data => data.json()) // It resolves the promise with a JSON object
      .then(data => {
        this.setState({ userInfo : data });
      })
    }
  }

  componentDidUpdate() {
    // Set tutorial info timer.
    const render = ({ hours, minutes, seconds, completed }) => {
      if (completed) {
        // Render a completed state
        return this.redirectToTarget();
      }
      else {
        // Render a countdown
        return <span>{minutes}:{seconds}</span>;
      }
    };
    if(this.state.renderer === null ||  this.state.renderer === '') {
      const ren = <Countdown date = { Date.now() + 900000 } renderer = { render } />
      this.setState({ renderer: ren })
    }
  }

  redirectToTarget = (e) => {
    if (typeof e === 'undefined') {
      this.props.history.push('/questions', {
        response: this.props.location.state.response,
        exam_name: this.props.location.state.exam_name,
        exam_time: this.props.location.state.exam_time,
        question: this.props.location.state.question,
        lang_sel: this.props.location.state.lang_sel
      });
      window.location.reload();
      e.preventDefault();
    }

    var response_val = e.currentTarget.attributes['id'].value;
    var exam_name_val = e.currentTarget.attributes['exam'].value;
    var exam_time_val = e.currentTarget.attributes['exam_time'].value;

    confirmAlert({
      title: 'Confirm to Start the Exam',
      message: 'Are you sure to start the Exam?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.dialog.show({
            title: 'Confirm to Start the Exam',
            body: 'Are you definitely sure to start the Exam?',
            actions: [
              Dialog.Action(
                'Yes',
                () => this.props.history.push('/questions', {
                  response: response_val,
                  exam_name: exam_name_val,
                  exam_time: exam_time_val,
                  question: this.props.location.state.question,
                  exam_id: this.props.location.state.response,
                  lang_sel: this.props.location.state.lang_sel
                }),
                'btn-yes'
              ),
              Dialog.Action(
                'No',
                () => console.log('No!'),
                'btn-no'
              )
            ],
            bsSize: 'small',
            onHide: (dialog) => {
              Dialog.hide()
            }
          })
        },
        {
          label: 'No',
          onClick: () => ""
        }
      ]
    });
  }

  navigation_exams() {
    if(this.state.tutorial_exams.length > 0) {
      let index = 1;
      return this.state.tutorial_exams.map((tutorial, item) => {
        let newIndex = item + index;
        let selected_nav = this.state.currentPage;
        let nav_class;
        let btn_class;
        if (selected_nav === newIndex) {
          btn_class = 'selected';
          nav_class = '<span class="selected-nav"></span>';
        }
        else {
          nav_class = '';
          btn_class = '';
        }
        return (
          <li key={item} className="nav-list">
            {/* eslint-disable-next-line */}
            <a id ={"nav-button" + item} className = {btn_class} onClick={() => this.changePageOnNav(item)}>{newIndex}</a>
            {ReactHtmlParser(nav_class)}
          </li>
        );
      });
    }
  }

  changePageOnNav(index) {
   var percent = (index)/(this.state.tutorial_exams.length)*100;
   this.setState({
    currentPage: index + 1,
    percentage: percent,
   });
  }

  tutorial_of_exams() {
    if(this.state.tutorial_exams.length > 0) {
      return this.state.tutorial_exams.slice((this.state.currentPage * this.state.maxItemsPerPage) - this.state.maxItemsPerPage, this.state.currentPage * this.state.maxItemsPerPage).map((tutorial, item) => {
        return (
          <ul key={item}>
          <div className="tutorial-list">
            <div>{ReactHtmlParser(tutorial.tutorial_info)}</div>
          </div>
          </ul>
        );
      });
    }
    // If no tutorial info, redirect to exam.
    if(this.state.tutorial_exams.message) {
      return this.redirectToTarget();
    }
  }

  simplePaging() {
    return (
      <div className="pagination-button-wrapper">
        {this.state.currentPage > 1 ?
        <button id ={"back-button" + this.state.currentPage} className = "back-button" onClick={() => this.changePage('back')}><div className="back-arrow"></div>Back</button>
        : null}
        {this.state.currentPage === 1 ?
        <button id ={"back-button-disable" + this.state.currentPage} className = "back-button-disable"><div className="back-arrow"></div>Back</button>
        : null}
        {this.state.tutorial_exams.length === this.state.currentPage ?
         <button id ={"next-button-disable" + this.state.currentPage} className = "next-button-disable">Next<div className="next-arrow"></div></button>
        : null}
        {this.state.tutorial_exams.length > this.state.currentPage * this.state.maxItemsPerPage ?
        <button id ={"next-button" + this.state.currentPage} className = "next-button" onClick={() => {this.changePage('next');this.changePageOnNav(this.state.currentPage)}}>Next <div className="next-arrow"></div></button>
        : null}
        {this.state.tutorial_exams.length > this.state.currentPage * this.state.maxItemsPerPage ?
        <button exam_time = {this.props.location.state.exam_time} exam = {this.props.location.state.exam_name} className = "start_exam" id = { this.props.location.state.response } onClick={this.redirectToTarget}>Start Exam</button>
        : null}
        {this.state.tutorial_exams.length === this.state.currentPage ?
        <button exam_time = {this.props.location.state.exam_time} exam = {this.props.location.state.exam_name} className = "start_exam" id = { this.props.location.state.response } onClick={this.redirectToTarget}>Start Exam</button>
        : null}
      </div>
    );
  }

  changePage(direction: string) {
    if (direction === 'back') {
       this.setState({
        currentPage: this.state.currentPage - 1
       });
    }
    else if (direction === 'next') {
      this.setState({
        currentPage: this.state.currentPage + 1
      });
    }
  }

  render() {
    window.onbeforeunload = function () {return false;}
    // Get exam and candidate name.
    var examname;
    if (this.props.location.state) {
      examname = this.props.location.state.exam_name;
    }
    else {
      examname = "";
    }

    if (!this.state.userInfo.field_first_name) return null;
    const fname  = this.state.userInfo.field_first_name[0].value;
    if (!this.state.userInfo.field_last_name) return null;
    const lname  = this.state.userInfo.field_last_name[0].value;
    const username = fname + ' ' + lname;
    const progress = Math.trunc(this.state.percentage);

    $(document).ready(function () {
      $('.submit-btn-loader').hide();
      $(".popup-timer").hide();
      // Show the div after 5s
      $(".popup-timer").delay(2000).fadeIn('slow');

      setTimeout(function() {
        $('.popup-timer').fadeOut('slow');
      }, 5000); // <-- time in milliseconds
    });

    return (
      <div className= "tutorial-page">
        <div>
          <div className="exam-intro-section-header-wrapper">
            <div className="exam-header-section-wrapper">
              <div className="section-intro">
                <div className="site-logo">
                  <a href="/"><img src={exam_logo} className="logo" alt="logo" /></a>
                </div>
              <span><b>Section</b> : Introduction</span>
              </div>
              <div className="popup-timer">
                <Icon icon="time" />
                <div>
                  <p className="intro-title">Intro Timing Remaining </p>
                  <p className="intro-time">{ this.state.renderer }</p>
                  <p>15 minutes remaining.</p>
                </div>
              </div>
              <div className="exam-intro-timer" title="show on load" data-toggle="tooltip">
                <Icon icon="time" />
                <div>
                  <p>Intro Timing Remaining </p>
                  <p>{ this.state.renderer }</p>
                </div>
              </div>
              <div className="progress">
                <CircularProgressbar value={this.state.percentage} text={`${progress}%`} />
                <p className="progress-title">Progress</p>
              </div>
            </div>
            <div className="exam-header-inner-wrapper">
              <div className="exam-title"><span>Exam : </span>{examname}</div>
              <div className="candidate-name"><span>Candidate : </span>{username}</div>
            </div>
          </div>
        </div>
        <div>
          <div className="answer-container">
            <div className="row">
              <div className="navigation col-sm-3">
                <div className="navigation-wrapper">
                  <ul>
                    {this.navigation_exams()}
                  </ul>
                </div>
              </div>
              <div className="answer col-sm-7">
                {this.tutorial_of_exams()}
                <Dialog ref={(el) => { this.dialog = el }} />
                {this.simplePaging()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Tutorial;
