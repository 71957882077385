import React, {Component} from 'react';
import '../../css/Register.css';
import $ from "jquery";

class success extends Component {
  redirectToTarget = (e) => {
    window.location = '/agreement';
    this.props.history.push('/agreement', {
      response: e.target.id,
      exam_name: e.currentTarget.attributes['exam'].value,
      exam_time: e.currentTarget.attributes['exam_time'].value,
      exam_loc: window.location.pathname,
      question: e.currentTarget.attributes['data-question'].value,
    });
    // window.location.reload();
    e.preventDefault();
  }

  render() {
    $(document).ready(function() {
      $('.submit-btn-loader').hide();
    });
    var exam_name;
    var examid;
    var exam_time;
    var amount;
    var productinfo;
    var txnid;
    var question;
    if (this.props.location.state != null) {
      exam_name = this.props.location.state.exam_name
      examid = this.props.location.state.examid
      exam_time = this.props.location.state.exam_time
      amount = this.props.location.state.amount
      productinfo = this.props.location.state.productinfo
      txnid = this.props.location.state.txnid
      question = this.props.location.state.question
    }
    else {
      exam_name = "IQS CBT Product";
      examid = "";
      exam_time = "";
      amount = "";
      productinfo = "";
      txnid = "";
      question = "";
    }
    return (
      <div className="payment-success-form">
        <div class='course-registration-payment-success-page'>
          <h2>Thank You. You have successfully paid for {productinfo}.</h2>
          <h4>Your Transaction ID for this transaction is {txnid}.</h4>
          <h4>We have received a payment of Rs. {amount} against exam {productinfo}.</h4>
          <div class='details'>Please click below button to start the exam.</div>
          <button data-question = {question} exam_time = {exam_time} exam = {exam_name} className = "start_exam" id = { examid } onClick={this.redirectToTarget}>Start Exam</button>
        </div>
      </div>
    );
  }
}

export default success;
