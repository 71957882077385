import React, {Component} from 'react';
import { TransverseLoading } from 'react-loadingg';
import default_logo from '../../course_default.png';
import Truncate from 'react-truncate';
import ReactTooltip from "react-tooltip";
import Pagination from "react-js-pagination";
import '../../css/Exam.css';
import $ from "jquery";

class Exam_types extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      exam_types:[],
      exam_types_list: [],
      isLoading:true,
      activePage: 1,
      totalItemsCount: 0,
      itemsCountPerPage: 16,
      pageRangeDisplayed: 5,
      skipCountPerPage: 0,
    }
  }

  componentDidMount() {
    fetch(process.env.REACT_APP_CBT + 'api/exam-types?_format=json')
    .then(res => res.json()) // It resolves the promise with a JSON object
    .then(
      (res) => {
        if (res.length > 0) {
          var exam_types_list = res.slice(this.state.skipCountPerPage, this.state.itemsCountPerPage);
        }
        this.setState({
          exam_types : res,
          isLoading : false,
          totalItemsCount : res.length,
          exam_types_list : exam_types_list
        });
      },
      // Note: it's important to handle errors here
      (error) => {
        this.setState({
          isLoading: false,
          error
        });
      }
    )
  }

  redirectToTarget = (e) => {
    window.location.reload();
  }

  // Handle page changes.
  handlePageChange(pageNumber) {
    var skipPagesExamTypes = (pageNumber - 1) * this.state.itemsCountPerPage;
    var skipCountPerPage = this.state.skipCountPerPage + skipPagesExamTypes;
    var showNextCount = this.state.itemsCountPerPage * pageNumber;
    var exam_types = this.state.exam_types;
    this.setState({
      activePage: pageNumber,
      exam_types_list : exam_types.slice(skipCountPerPage, showNextCount)
    });
  }

  listExamTypes(){
    // eslint-disable-next-line
    if (this.state.isLoading == true) {
      return (
        <div className="result_loder"><TransverseLoading color="#F92424" /></div>
      );
    }
    else if(this.state.exam_types.length > 0) {
      return this.state.exam_types_list.map((item, key) => {
        var img_url;
        var img_class;
        // eslint-disable-next-line
        if (item.field_exam_type_logo == 0) {
          img_url = default_logo;
          img_class = "default_logo";
        }
        else {
          img_url = item.field_exam_type_logo[0].url;
          img_class = "original_logo";
        }

        var exam_class = '';
        if(key%2 === 0){
          exam_class = 'even';
        }
        else {
          exam_class = 'odd';
        }
        return (
          <div key={ item.tid[0].value } className={'exam-type-list ' + exam_class}>
            <a className="exam-link" data-tip={item.name[0].value} href={'/exam/' + item.tid[0].value} onClick={this.redirectToTarget}>
              <div className="exam-logo">
                <img src={ img_url } alt="exam-logo" className={"img-responsive " + img_class}/>
              </div>
              <div className="exam-name">
                <Truncate lines={2}>{ item.name[0].value }</Truncate>
              </div>
            </a>
            <ReactTooltip type="light"/>
          </div>
        );
      });
    }
    else {
      return (
        <div className="no_result_message">Sorry, No exam types found. Please contact to site administrator.</div>
      );
    }
  }

  examTypesPagination() {
    // Add pagination.
    if (this.state.exam_types.length > this.state.itemsCountPerPage) {
      return (
        <div className="pagination-wrapper">
          <Pagination
            itemClass="page-item"
            linkClass="page-link"
            activePage={this.state.activePage}
            itemsCountPerPage={this.state.itemsCountPerPage}
            totalItemsCount={this.state.totalItemsCount}
            pageRangeDisplayed={this.state.pageRangeDisplayed}
            onChange={this.handlePageChange.bind(this)}
          />
        </div>
      );
    }
  }

  render() {
    $(document).ready(function() {
      $('.submit-btn-loader').hide();
    });
    return (
      <div className="exam-list">
        <h2>Exam Types</h2>
        {this.listExamTypes()}
        {this.examTypesPagination()}
      </div>
    );
  }
}

export default Exam_types;
