import React, {Component} from 'react';
import ReactHtmlParser from 'react-html-parser'
import '../../css/Exam_review.css';
import {UpdateReviewResult} from '../../services/PostData';
import {EmailReviewResult} from '../../services/PostData';
import { confirmAlert } from 'react-confirm-alert';
import ClipLoader from "react-spinners/ClipLoader";
import $ from "jquery";

class Available_exams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      custom_data: '',
      review_answer: '',
      resultID: ''
    }

    this.onBlur= this.onBlur.bind(this);
    this.submitReview= this.submitReview.bind(this);
  }

  onBackButtonEvent = () => {
   window.history.forward()
 }

 componentWillUnmount() {
   window.removeEventListener("popstate", this.onBackButtonEvent)
 }

  componentDidMount() {
    window.addEventListener("popstate", this.onBackButtonEvent);
    this.setState({ "resultID": this.props.location.state.result_id })

    fetch(process.env.REACT_APP_CBT + '/api/custom/configs/?_format=json')
    .then(res => res.json()) // It resolves the promise with a JSON object
    .then(res => {
      this.setState({
        custom_data : res.review_summary,
      });
    })
  }

  onBlur(e) {
    this.setState({ "review_answer": e.target.value });
  }

  submitReview() {
    $('.review-btn-loader').show();
    window.onbeforeunload = function () {return false;}
    const csrf_token = localStorage.getItem('userData.csrf_token');
    const auth = localStorage.getItem('userData.auth');

    let data = {
      "nid": this.state.resultID,
      "examReview": this.state.review_answer,
      "pauseCount": 0,
      "pauseTime": 0,
      csrf_token,
      auth
    };
    if(this.state.resultID) {
      UpdateReviewResult('node/' + this.state.resultID , data)
      .then((response) => {
        $('.review-btn-loader').hide();
        console.log("Result Updated with exam review on finish exam!");
        confirmAlert({
          title: 'Confirm to Submit the Exam Review',
          message: 'Are you sure?',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                // eslint-disable-next-line
                if (this.state.review_answer != '') {
                  const userID = localStorage.getItem('userData.uid');
                  let review_data = {
                    "nid": this.state.resultID,
                    "examReview": this.state.review_answer,
                    "uid": userID,
                    "exam_name": this.props.location.state.examname,
                    csrf_token,
                    auth
                  };
                  if(this.state.resultID) {
                    EmailReviewResult('api/result-review/email', review_data)
                    .then((response) => {
                      console.log("Result Summary Email has been sent!");
                    })
                  }
                }
                this.props.history.push('/result_summary', {
                  result_id: this.state.resultID,
                  examname: this.props.location.state.examname,
                  exam_id: this.props.location.state.exam_id,
                  question: this.props.location.state.question,
                  ques_count: this.props.location.state.ques_count,
                  gcount: this.props.location.state.gcount,
                  scount: this.props.location.state.scount
                });
                // window.location.reload();
              }
            },
            {
              label: 'No',
              onClick: () => ""
            }
          ]
        });

      })
    }
  }

  reviewSummary() {
    const uid = localStorage.getItem('userData.uid');
    let summary = '';
    // eslint-disable-next-line
    if (this.state.custom_data != null || this.state.custom_data != ''){
      summary = this.state.custom_data;
    }
    // eslint-disable-next-line
    if (this.state.custom_data == null || this.state.custom_data == '' ) {
      summary = "<div><h2>Final Review and Submit</h2><div>";
    }

    let loader_btn = <ClipLoader
          size={20}
          color={"red"}
          loading={true}
        />;

    return (
      <div>
        <div className="exam-review-summary" id={uid}>
          {ReactHtmlParser(summary)}
          <div className="pt-input-group feedback-section">
            <textarea rows="10" cols="40" name="review_feedback" onChange={this.onBlur}></textarea>
          </div>
        </div>
        <div className="exam_review_action">
          <div><button className="feedback_button" onClick={() => {this.submitReview()}}>Submit Review</button><span className="review-btn-loader">{loader_btn}</span></div>
        </div>
      </div>
    );
  }

  render() {
    // eslint-disable-next-line
    if (document.referrer == document.location.href) {
      setTimeout(function() {
        // eslint-disable-next-line
        if(window.location.hash == '' ) {
          window.location.reload();
        }
      }, 1000);
      document.location.href = '#'
    }
    // $(document).ready(function() {
    //   $('.submit-btn-loader').hide();
    // });
    return (
      <div className="review-wrapper">
        <div className="exam-review-wrapper">
          {this.reviewSummary()}
        </div>
      </div>
    );
  }
}

export default Available_exams;
